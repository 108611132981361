import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword, login } from "../../services/loginApi.jsx";
import { toast } from "react-toastify";
import { ContextApp } from "../../utils/Context";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Button } from "../ui/button.tsx";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card.tsx";
import { Input } from "../ui/input.tsx";
import { Info, AlertTriangle, CheckCircle2, Loader2, CheckCheck, X } from "lucide-react";
import { useTheme } from "../ThemeContext";
import { CustomAlert } from "../ui/CustomAlert.tsx";
import Cookies from "js-cookie";
import { ApiContext } from "../../utils/ApiContext";

function Login() {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { setIsLoggedIn, setIsGuest, setUsername } = useContext(ContextApp);
  const apiContext = useContext(ApiContext);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [needPasswordChange, setNeedPasswordChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [session, setSession] = useState("");
  const [textConfig, setTextConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [alertConfig, setAlertConfig] = useState({
    open: false,
    type: "success",
    message: "",
    title: "",
    onConfirm: undefined,
  });

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const validatePassword = (password) => {
    setPasswordValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>\-_\\\/\[\]+=]/.test(password),
    });
  };

  const isPasswordValid = () => {
    return Object.values(passwordValidations).every((validation) => validation);
  };
  
  const handleSuccessfulLogin = async (username) => {
    try {
      const API_ASSOCIATION = apiContext.endpoints.API_ASSOCIATION;

      
      if (!username) {
        console.error("No se encontró nombre de usuario después del login");
        setLoginStatus('error');
        return;
      }
      
      const associationUrl = `${API_ASSOCIATION}?user_id=${username}`;
      
      const token = Cookies.get("accessToken");
      const associationResponse = await fetch(associationUrl, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      
      if (!associationResponse.ok) {
        setAlertConfig({
          open: true,
          type: "warning",
          message: "Tu cuenta no tiene acceso a ninguna colección. Contacta con el administrador para obtener acceso.",
          title: "Acceso limitado",
          onConfirm: undefined,
        });
        return false;
      } 
      
      const associationData = await associationResponse.json();
      if (!associationData || !associationData.profile_id) {
        setAlertConfig({
          open: true,
          type: "warning",
          message: "Tu cuenta no tiene un perfil asociado. Contacta con el administrador para obtener acceso.",
          title: "Acceso limitado",
          onConfirm: undefined,
        });
        return false;
      }
      
      return true;
    } catch (error) {
      console.error("Error al verificar acceso después del login:", error);
      return true; 
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      const data = await response.json();
      setTextConfig(data);
    } catch (error) {
      console.error("Error loading text config:", error);
    }
  };
  useEffect(() => {
    fetchTextConfig();
  }, []);

  useEffect(() => {
    validatePassword(newPassword);
  }, [newPassword]);

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case 'password':
        setShowPassword(!showPassword);
        break;
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const handleGuestLogin = () => {
    setIsLoggedIn(false);
    setIsGuest(true);
    navigate("/guest-chat");
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoginStatus(null);
    
    if (!isPasswordValid()) {
      setErrorMessage("La contraseña no cumple con los requisitos de seguridad");
      toast.error("La contraseña no cumple con los requisitos de seguridad");
      setIsLoading(false);
      setLoginStatus('error');
      
      setTimeout(() => {
        setLoginStatus(null);
      }, 3000);
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Las contraseñas no coinciden");
      toast.error("Las contraseñas no coinciden");
      setIsLoading(false);
      setLoginStatus('error');
      
      setTimeout(() => {
        setLoginStatus(null);
      }, 3000);
      return;
    }
    if (newPassword === password) {
      setErrorMessage("La nueva contraseña no puede ser igual a la anterior");
      toast.error("La nueva contraseña no puede ser igual a la anterior");
      setIsLoading(false);
      setLoginStatus('error');
      
      setTimeout(() => {
        setLoginStatus(null);
      }, 3000);
      return;
    }
    try {
      setAlertConfig({
        open: true,
        type: "loading",
        message: "Cambiando contraseña...",
        title: "",
        onConfirm: undefined,
      });
      
      const response = await changePassword(user, newPassword, session);
      
      setLoginStatus('success');
      
      setAlertConfig({
        open: true,
        type: "success",
        message: "Contraseña cambiada exitosamente. Ingresando a la plataforma...",
        title: "¡Éxito!",
        onConfirm: undefined,
      });
      
      setTimeout(() => {
        setUsername(user);
        localStorage.setItem('username', user);
        Cookies.set('username', user);
        
        setIsLoggedIn(true);
        setIsGuest(false);
        
        setNeedPasswordChange(false);
        setPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setSession("");
        setErrorMessage("");
        setPasswordValidations({
          length: false,
          uppercase: false,
          lowercase: false,
          number: false,
          special: false,
        });
        
        setAlertConfig(prev => ({ ...prev, open: false }));
        setIsLoading(false);
        setLoginStatus(null);
        
        navigate("/");
      }, 2000);
      
    } catch (error) {
      console.error(error);
      setErrorMessage("Error al cambiar la contraseña");
      
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al cambiar la contraseña",
        title: "Error",
        onConfirm: undefined,
      });
      setIsLoading(false);
      setLoginStatus('error');
      
      setTimeout(() => {
        setLoginStatus(null);
      }, 3000);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoginStatus(null);
    toast.dismiss();
    toast.info(textConfig.LOGIN_TRYING_LOGIN);
    try {
      const response = await login(user, password);
      toast.dismiss();
      if (response.ChallengeName === "NEW_PASSWORD_REQUIRED") {
        setNeedPasswordChange(true);
        setSession(response.Session);
        toast.success(textConfig.LOGIN_NEED_CHANGE_PASSWORD);
        setErrorMessage(textConfig.LOGIN_NEED_CHANGE_PASSWORD);
        setIsLoading(false);
        setLoginStatus('success');
      } else {
        toast.success(textConfig.LOGIN_TOAST_LOGIN_SUCCESS);
        setLoginStatus('success');
        
        setTimeout(async () => {
          const hasAccess = await handleSuccessfulLogin(user);
          setUsername(user);
          localStorage.setItem('username', user);
          Cookies.set('username', user);
          setIsLoggedIn(true);
          setIsGuest(false);
          navigate("/");
        }, 800);
        
        return;
      }
    } catch (error) {
      console.error(error);
      toast.dismiss();
      setErrorMessage(textConfig.LOGIN_TOAST_LOGIN_FAIL);
      toast.error(textConfig.LOGIN_TOAST_LOGIN_FAIL, { autoClose: 5000 });
      setIsLoading(false);
      setLoginStatus('error');  
      setTimeout(() => {
        setLoginStatus(null);
      }, 3000);
    }
  };
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center p-4 lg:p-20">
      <div className={`w-full lg:w-1/2 space-y-4 lg:pr-8 mb-8 lg:mb-0 ${
        isDarkMode ? "text-white" : "text-black"
      }`}>
        <h1 className={`text-3xl lg:text-5xl font-bold ${
          isDarkMode ? "text-white" : "text-black"
        }`}>
          Bienvenido a Go! Support & Assist
        </h1>
        <h2 className={`text-xl lg:text-3xl pt-8 text-green-500`}>
          Tu Asistente Virtual Empresarial
        </h2>
        <p className={`text-lg font-semibold ${
          isDarkMode ? "text-gray-400" : "text-gray-700"
        }`}>
          Accede a la plataforma y recibe ayuda en tiempo real de nuestro equipo
          de expertos. ¿Listo para comenzar?
        </p>
      </div>

      <div className="w-full lg:w-1/2 max-w-md">
        <Card>
          <CardHeader className="relative">
            <CardTitle>{needPasswordChange ? "Cambiar Contraseña" : "Ingresar"}</CardTitle>
            <p className={`text-sm text-center ${
              isDarkMode ? "text-gray-300" : "text-gray-500"
            }`}>
              {needPasswordChange 
                ? "Por seguridad, debes cambiar tu contraseña en el primer inicio de sesión"
                : "Ingresa a tu cuenta para acceder a todas las funcionalidades de soporte en tiempo real."}
            </p>
            <div className="absolute top-4 right-4">
              {isLoading && (
                <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? "text-white" : "text-gray-700"}`} />
              )}
              {!isLoading && loginStatus === 'success' && (
                <CheckCheck className="h-6 w-6 text-green-500" />
              )}
              {!isLoading && loginStatus === 'error' && (
                <X className="h-6 w-6 text-red-500" />
              )}
            </div>
          </CardHeader>
          <CardContent className="space-y-4">
            {!needPasswordChange ? (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-4">
                  <Input
                    type="text"
                    placeholder="Usuario"
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    disabled={isLoading}
                  />
                  <div className={`text-xs text-center ${
                    isDarkMode ? "text-gray-500" : "text-gray-700"
                  }`}>
                    Ingrese su usuario
                  </div>
                </div>
                <div className="space-y-1 relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('password')}
                    className="absolute inset-y-0 right-3 flex items-center"
                    aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                    disabled={isLoading}
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-700" />
                    )}
                  </button>
                </div>
                {errorMessage && (
                  <div className="text-xs text-center text-red-500">
                    {errorMessage}
                  </div>
                )}
                <div className="flex gap-4 pt-2">
                  <Button 
                    variant="primary" 
                    className="w-full" 
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        CARGANDO...
                      </div>
                    ) : (
                      "INGRESAR"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-full"
                    type="button"
                    onClick={handleGuestLogin}
                    disabled={isLoading}
                  >
                    INVITADO
                  </Button>
                </div>
              </form>
            ) : (
              <form onSubmit={handlePasswordChange} className="space-y-4">
                <div className="space-y-1 relative">
                  <Input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Nueva Contraseña"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('newPassword')}
                    className="absolute inset-y-0 right-3 flex items-center"
                    aria-label={showNewPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                    disabled={isLoading}
                  >
                    {showNewPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-700" />
                    )}
                  </button>
                </div>
                
                {newPassword.length > 0 && (
                  <div className={`text-xs px-2 py-1 rounded-md ${
                    isDarkMode ? "bg-gray-800" : "bg-gray-100"
                  }`}>
                    <p className={`font-semibold mb-1 ${
                      isDarkMode ? "text-gray-300" : "text-gray-700"
                    }`}>
                      La contraseña debe tener:
                    </p>
                    <div className={`flex items-center gap-1 ${
                      passwordValidations.length ? "text-green-500" : "text-red-500"
                    }`}>
                      {passwordValidations.length ? <CheckCircle2 size={12}/> : <AlertTriangle size={12}/>}
                      <p>• Mínimo 8 caracteres</p>
                    </div>
                    <div className={`flex items-center gap-1 ${
                      passwordValidations.uppercase ? "text-green-500" : "text-red-500"
                    }`}>
                      {passwordValidations.uppercase ? <CheckCircle2 size={12}/> : <AlertTriangle size={12}/>}
                      <p>• Al menos una mayúscula</p>
                    </div>
                    <div className={`flex items-center gap-1 ${
                      passwordValidations.lowercase ? "text-green-500" : "text-red-500"
                    }`}>
                      {passwordValidations.lowercase ? <CheckCircle2 size={12}/> : <AlertTriangle size={12}/>}
                      <p>• Al menos una minúscula</p>
                    </div>
                    <div className={`flex items-center gap-1 ${
                      passwordValidations.number ? "text-green-500" : "text-red-500"
                    }`}>
                      {passwordValidations.number ? <CheckCircle2 size={12}/> : <AlertTriangle size={12}/>}
                      <p>• Al menos un número</p>
                    </div>
                    <div className={`flex items-center gap-1 ${
                      passwordValidations.special ? "text-green-500" : "text-red-500"
                    }`}>
                      {passwordValidations.special ? <CheckCircle2 size={12}/> : <AlertTriangle size={12}/>}
                      <p>• Al menos un carácter especial</p>
                    </div>
                  </div>
                )}
                <div className="space-y-1 relative">
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirmar Nueva Contraseña"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('confirmPassword')}
                    className="absolute inset-y-0 right-3 flex items-center"
                    aria-label={showConfirmPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                    disabled={isLoading}
                  >
                    {showConfirmPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-700" />
                    )}
                  </button>
                </div>
                
                {confirmNewPassword.length > 0 && (
                  <div className={`flex items-center gap-1 text-xs ${
                    newPassword === confirmNewPassword ? "text-green-500" : "text-red-500"
                  }`}>
                    {newPassword === confirmNewPassword ? 
                      <CheckCircle2 size={12}/> : 
                      <AlertTriangle size={12}/>
                    }
                    <p>
                      {newPassword === confirmNewPassword ? 
                        "Las contraseñas coinciden" : 
                        "Las contraseñas no coinciden"
                      }
                    </p>
                  </div>
                )}
                
                {errorMessage && (
                  <div className="text-xs text-center text-red-500">
                    {errorMessage}
                  </div>
                )}
                <Button 
                  variant="primary" 
                  className="w-full" 
                  type="submit"
                  disabled={!isPasswordValid() || newPassword !== confirmNewPassword || isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      CARGANDO...
                    </div>
                  ) : (
                    "CAMBIAR CONTRASEÑA"
                  )}
                </Button>
              </form>
            )}
            <div className="flex items-stretch rounded-lg shadow-md overflow-hidden">
              <div className="flex items-center justify-center bg-blue-500 w-14 p-4">
                <Info className="text-white w-6 h-6" />
              </div>
              <div className="flex items-center bg-blue-100 p-2">
                <p className="text-blue-500 text-xs">
                  {needPasswordChange
                    ? "Por tu seguridad, debes elegir una nueva contraseña fuerte para continuar."
                    : "Ingresa tus datos para iniciar sesión. Si necesitas asistencia como invitado, elige la opción de ingreso sin cuenta."}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <CustomAlert
        open={alertConfig.open}
        onClose={() => setAlertConfig((prev) => ({ ...prev, open: false }))}
        onConfirm={alertConfig.onConfirm}
        type={alertConfig.type}
        message={alertConfig.message}
        title={alertConfig.title}
        $isDark={isDarkMode}
        textConfig={{
          CUSTOM_ALERT_BUTTON_CONFIRM: "Confirmar",
          CUSTOM_ALERT_BUTTON_CANCEL: "Cancelar",
          CUSTOM_ALERT_BUTTON_CLOSE: "Cerrar"
        }}
      />
    </div>
  );
}

export default Login;