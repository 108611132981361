import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "./components/ThemeContext";
import ConfigProvider from "./utils/Config.jsx";
import ApiProvider from "./utils/ApiContext.jsx";
import AppContext from "./utils/Context";
import LoginPage from "./Page/Login.jsx";
import HomeContent from "./Page/Home.jsx";

function App() {
  return (
    <ThemeProvider>
      <ConfigProvider>
        <ApiProvider>
          <AppContext>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/guest-chat" replace />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/guest-chat" element={<HomeContent />} />
              </Routes>
            </BrowserRouter>
          </AppContext>
        </ApiProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;