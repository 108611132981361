import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Users, Edit, Save, X } from "lucide-react";
import { CustomTextField } from "./CustomTextField.tsx";
import CustomButton from "./CustomButton.tsx";
import { getGroups, updateUserApi } from "../../services/associationApi.jsx";

interface StyledProps {
  $isDark?: boolean;
  $variant?: "primary" | "danger";
  disabled?: boolean;
}

interface UserGroup {
  username: string;
}

interface GroupType {
  groupname: string;
  description: string;
  users: UserGroup[];
}

const EditButton = styled(Box)<StyledProps>`
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.$isDark ? "#E5E7EB" : "#4B5563")};
  &:hover {
    background-color: ${(props) =>
      props.$isDark ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)"};
  }
`;

const ActionButtonsContainer = styled(Box)<StyledProps>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 8px;
`;

const ActionButton = styled(Box)<StyledProps & { $color?: string }>`
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.$color ? props.$color : "transparent")};
  color: ${(props) =>
    props.$color ? "#FFFFFF" : props.$isDark ? "#E5E7EB" : "#4B5563"};
  &:hover {
    background-color: ${(props) =>
      props.$color === "#22C55E"
        ? "#16A34A"
        : props.$color === "#EF4444"
        ? "#DC2626"
        : props.$isDark
        ? "rgba(255, 255, 255, 0.05)"
        : "rgba(0, 0, 0, 0.05)"};
  }
`;

const GroupsContainer = styled(Box)<StyledProps>`
  border-radius: 8px;
  background-color: ${(props) => (props.$isDark ? "#1F1F23" : "#F9FAFB")};
  border: 1px solid ${(props) => (props.$isDark ? "#27272A" : "#E5E7EB")};
  padding: 16px;
  margin-bottom: 16px;
`;

const GroupsList = styled(Box)<StyledProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;

const GroupBadge = styled(Box)<StyledProps>`
  background-color: ${(props) => (props.$isDark ? "#22C55E" : "#16A34A")};
  color: #ffffff;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
`;

const GroupCheckbox = styled(Box)<StyledProps & { $selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  background-color: ${(props) =>
    props.$selected
      ? props.$isDark
        ? "rgba(34, 197, 94, 0.1)"
        : "rgba(22, 163, 74, 0.1)"
      : "transparent"};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;

  &:hover {
    background-color: ${(props) =>
      props.$selected
        ? props.$isDark
          ? "rgba(34, 197, 94, 0.15)"
          : "rgba(22, 163, 74, 0.15)"
        : props.$isDark
        ? "rgba(255, 255, 255, 0.05)"
        : "rgba(0, 0, 0, 0.05)"};
  }
`;

const CheckboxIndicator = styled(Box)<StyledProps & { $selected: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 2px solid
    ${(props) =>
      props.$selected
        ? props.$isDark
          ? "#22C55E"
          : "#16A34A"
        : props.$isDark
        ? "#6B7280"
        : "#9CA3AF"};
  background-color: ${(props) =>
    props.$selected ? (props.$isDark ? "#22C55E" : "#16A34A") : "transparent"};
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: background-color 0.2s, border-color 0.2s;
`;

const NoGroupsMessage = styled(Box)<StyledProps>`
  padding: 12px;
  background-color: ${(props) => (props.$isDark ? "#27272A" : "#F3F4F6")};
  border-radius: 6px;
  color: ${(props) => (props.$isDark ? "#9CA3AF" : "#6B7280")};
  text-align: center;
  font-size: 14px;
  margin-top: 12px;
`;

const Label = styled(Box)<StyledProps>`
  color: ${(props) => (props.$isDark ? "#E5E7EB" : "#4B5563")};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const ReadOnlyField = styled(Box)<StyledProps>`
  padding: 10px 14px;
  background-color: ${(props) => (props.$isDark ? "#18181B" : "#F3F4F6")};
  border-radius: 8px;
  color: ${(props) => (props.$isDark ? "#D1D5DB" : "#6B7280")};
  font-size: 14px;
  border: 1px solid ${(props) => (props.$isDark ? "#27272A" : "#E5E7EB")};
`;

const LoadingContainer = styled(Box)<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  flex-direction: column;
  gap: 12px;
`;

const LoadingText = styled(Box)<StyledProps>`
  color: ${(props) => (props.$isDark ? "#A1A1AA" : "#6B7280")};
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const LoadingDots = ({ isDarkMode }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev === "...") return "";
        return prev + ".";
      });
    }, 400);

    return () => clearInterval(interval);
  }, []);

  return (
    <span
      style={{
        display: "inline-block",
        minWidth: "20px",
        textAlign: "left",
        color: isDarkMode ? "#A1A1AA" : "#6B7280",
      }}
    >
      {dots}
    </span>
  );
};

const UserEditor = ({
  userData,
  isLoading,
  handleUpdate,
  email,
  setEmail,
  isDarkMode,
  textConfig,
}) => {
  const [allGroups, setAllGroups] = useState<GroupType[]>([]);
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Record<string, boolean>>(
    {}
  );
  const [originalEmail, setOriginalEmail] = useState("");
  const [originalGroups, setOriginalGroups] = useState<string[]>([]);

  useEffect(() => {
    if (userData && userData.attributes) {
      setOriginalEmail(userData.attributes.email || "");
      setEmail(userData.attributes.email || "");
    }
  }, [userData]);

  useEffect(() => {
    const fetchGroups = async () => {
      if (!userData || !userData.username) return;

      setLoadingGroups(true);
      try {
        const groupsData = await getGroups();
        setAllGroups(groupsData);

        const userGroupNames: string[] = [];
        const userGroupSelect: Record<string, boolean> = {};

        groupsData.forEach((group) => {
          const isInGroup = group.users.some(
            (user) => user.username === userData.username
          );

          userGroupSelect[group.groupname] = isInGroup;

          if (isInGroup) {
            userGroupNames.push(group.groupname);
          }
        });

        setUserGroups(userGroupNames);
        setOriginalGroups([...userGroupNames]);
        setSelectedGroups(userGroupSelect);
      } catch (error) {
        console.error("Error al cargar grupos:", error);
      } finally {
        setTimeout(() => {
          setLoadingGroups(false);
        }, 500);
      }
    };

    fetchGroups();
  }, [userData]);

  const getGroupDisplayName = (groupName) => {
    const group = allGroups.find((g) => g.groupname === groupName);
    if (group && group.description) {
      return `${groupName} (${group.description})`;
    }

    switch (groupName) {
      case "admin":
        return "Administrador";
      case "nuevos":
        return "Nuevos";
      case "user":
        return "Usuario";
      default:
        return groupName;
    }
  };

  const toggleEditing = () => {
    if (isEditing) {
      setEmail(originalEmail);

      const resetGroups: Record<string, boolean> = {};
      allGroups.forEach((group) => {
        resetGroups[group.groupname] = originalGroups.includes(group.groupname);
      });
      setSelectedGroups(resetGroups);
    }
    setIsEditing(!isEditing);
  };

  const toggleGroup = (groupName: string) => {
    setSelectedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handleSaveChanges = async () => {
    if (!userData || !userData.username) return;

    try {
      const groupsForUpdate: Record<string, boolean> = {};
      Object.entries(selectedGroups).forEach(([group, isSelected]) => {
        if (isSelected) {
          groupsForUpdate[group] = true;
        }
      });

      await updateUserApi({
        username: userData.username,
        email: email,
        groups: groupsForUpdate,
      });

      setOriginalEmail(email);
      const updatedGroups = Object.keys(selectedGroups).filter(
        (group) => selectedGroups[group]
      );
      setOriginalGroups(updatedGroups);
      setUserGroups(updatedGroups);

      setIsEditing(false);

      if (handleUpdate) {
        await handleUpdate();
      }
    } catch (error) {
      console.error("Error al guardar cambios:", error);
    }
  };

  const hasMadeChanges = () => {
    const emailChanged = email !== originalEmail;

    const groupsChanged = Object.keys(selectedGroups).some((group) => {
      const isSelected = selectedGroups[group];
      const wasSelected = originalGroups.includes(group);
      return isSelected !== wasSelected;
    });

    return emailChanged || groupsChanged;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        position: "relative",
      }}
    >
      {/* Mensaje informativo con botón de editar */}
      {!isEditing ? (
        <Box sx={{ position: "relative", mb: 2 }}>
          <Box
            sx={{
              p: 2,
              borderRadius: "4px",
              border: "1px solid",
              display: "flex",
              alignItems: "center",
              backgroundColor: isDarkMode
                ? "rgba(34, 197, 94, 0.1)"
                : "rgba(22, 163, 74, 0.05)",
              borderColor: isDarkMode ? "#22C55E" : "#16A34A",
              color: isDarkMode ? "#A7F3D0" : "#166534",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Box sx={{ fontSize: "14px" }}>
                <strong>Información:</strong> Puede modificar el correo
                electrónico y los grupos asignados. El nombre de usuario no
                puede ser editado. Para comenzar la edición, haga clic en el
                icono de editar ubicado abajo a la derecha.
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
            }}
          >
            <EditButton $isDark={isDarkMode} onClick={toggleEditing}>
              <Edit size={20} />
            </EditButton>
          </Box>
        </Box>
      ) : (
        <ActionButtonsContainer>
          <ActionButton $isDark={isDarkMode} onClick={toggleEditing}>
            <X size={18} />
          </ActionButton>
        </ActionButtonsContainer>
      )}

      <Box>
        <Label $isDark={isDarkMode}>
          {textConfig.USER_EDITOR_USERNAME || "Nombre de usuario"}
        </Label>
        <ReadOnlyField $isDark={isDarkMode}>{userData.username}</ReadOnlyField>
        <Box
          sx={{
            fontSize: "12px",
            color: isDarkMode ? "#A1A1AA" : "#6B7280",
            mt: 1,
          }}
        >
          El nombre de usuario no se puede modificar.
        </Box>
      </Box>

      <Box>
        <Label $isDark={isDarkMode}>
          {textConfig.USER_EDITOR_EMAIL || "Correo electrónico"}
        </Label>
        {isEditing ? (
          <CustomTextField
            isFullWidth
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        ) : (
          <ReadOnlyField $isDark={isDarkMode}>
            {email || "No especificado"}
          </ReadOnlyField>
        )}
      </Box>

      <Box>
        <Label $isDark={isDarkMode}>
          {textConfig.USER_EDITOR_GROUP || "Grupos"}
        </Label>

        <GroupsContainer $isDark={isDarkMode}>
          <Label $isDark={isDarkMode} sx={{ fontSize: "13px" }}>
            Grupos del usuario
          </Label>

          {loadingGroups ? (
            <LoadingContainer $isDark={isDarkMode}>
              <CircularProgress
                size={28}
                thickness={4}
                sx={{
                  color: isDarkMode ? "#22C55E" : "#16A34A",
                }}
              />
              <LoadingText $isDark={isDarkMode}>
                Cargando grupos
                <LoadingDots isDarkMode={isDarkMode} />
              </LoadingText>
            </LoadingContainer>
          ) : isEditing ? (
            allGroups.length > 0 ? (
              <Box>
                {allGroups.map((group) => (
                  <GroupCheckbox
                    key={group.groupname}
                    $isDark={isDarkMode}
                    $selected={selectedGroups[group.groupname] || false}
                    onClick={() => toggleGroup(group.groupname)}
                  >
                    <CheckboxIndicator
                      $isDark={isDarkMode}
                      $selected={selectedGroups[group.groupname] || false}
                    >
                      {selectedGroups[group.groupname] && (
                        <svg
                          width="10"
                          height="8"
                          viewBox="0 0 10 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4L3.5 6.5L9 1"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </CheckboxIndicator>
                    <Box
                      sx={{
                        fontSize: "13px",
                        color: isDarkMode ? "#E5E7EB" : "#374151",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>{group.groupname}</span>
                      {group.description && (
                        <span
                          style={{
                            fontSize: "11px",
                            color: isDarkMode ? "#9CA3AF" : "#6B7280",
                            marginTop: "2px",
                          }}
                        >
                          {group.description}
                        </span>
                      )}
                    </Box>
                  </GroupCheckbox>
                ))}
              </Box>
            ) : (
              <NoGroupsMessage $isDark={isDarkMode}>
                No hay grupos disponibles
              </NoGroupsMessage>
            )
          ) : userGroups.length > 0 ? (
            <GroupsList>
              {userGroups.map((groupName) => (
                <GroupBadge key={groupName} $isDark={isDarkMode}>
                  <Users size={16} />
                  {getGroupDisplayName(groupName)}
                </GroupBadge>
              ))}
            </GroupsList>
          ) : (
            <NoGroupsMessage $isDark={isDarkMode}>
              El usuario no pertenece a ningún grupo
            </NoGroupsMessage>
          )}
        </GroupsContainer>
      </Box>
      {isEditing && (
        <CustomButton
          label={textConfig.USER_EDITOR_SAVE || "Guardar"}
          onClick={handleSaveChanges}
          $isDark={isDarkMode}
          $variant="primary"
          disabled={isLoading || !hasMadeChanges()}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default UserEditor;
