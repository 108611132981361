import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";
import { ConfigContext } from "../utils/Config.jsx";

function Footer() {
  const { isDarkMode } = useContext(ThemeContext);
  const { config } = useContext(ConfigContext);
  
  const footerText = config?.FOOTER || "© 2024 Chat Go. All rights reserved.";

  return (
    <footer
      className={`w-full text-center py-2 fixed bottom-4 right-4 sm:right-4 text-xs sm:text-base ${
        isDarkMode ? "text-white" : "text-black"
      }`}
    >
      {footerText}
    </footer>
  );
}

export default Footer;