import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Users, Trash2 } from "lucide-react";
import { CustomTextField } from "../ui/CustomTextField.tsx";
import { updateUserApi } from "../../services/associationApi.jsx";
import { AdminUsersTabProps, User, StyledProps } from "./types";
import UserEditor from "./UserEditor.tsx";

const ContentBox = styled(Box)<StyledProps>`
  background-color: ${(props) => (props.$isDark ? "#2D2D2D" : "#FFFFFF")};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: ${(props) =>
    props.$isDark
      ? "none"
      : "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"};
`;

const SelectContainer = styled(Box)<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: ${(props) =>
    props.$isDark ? "#18181B" : "#F3F4F6"};
  border-radius: 12px;
  margin-bottom: 16px;
  border: ${(props) => (props.$isDark ? "none" : "1px solid #E5E7EB")};
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => (props.$isDark ? "#27272A" : "#E5E7EB")};
    border-radius: 0 12px 12px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    border-radius: 4px;
  }
`;

const SelectLabel = styled(Box)<StyledProps>`
  color: ${(props) => (props.$isDark ? "#FFFFFF" : "#4B5563")};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const GroupBadge = styled(Box)<StyledProps>`
  background-color: ${(props) => props.$isDark ? "#22C55E" : "#16A34A"};
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 11px;
  color: white;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-left: 8px;
`;

const NoResultsMessage = styled(Box)<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: ${(props) => (props.$isDark ? "#71717A" : "#6B7280")};
  font-style: italic;
  background-color: ${(props) => (props.$isDark ? "#27272A" : "#F9FAFB")};
  border-radius: 8px;
  margin-top: 8px;
`;

const AdminUsersTab: React.FC<AdminUsersTabProps> = ({
  users,
  userData,
  updateUserData,
  handleDeleteUser,
  isLoading,
  setIsLoading,
  isDarkMode,
  textConfig,
  setAlertConfig,
  fetchUsers
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
  const [email, setEmail] = useState("");
  
  useEffect(() => {
    if (userData) {
      setEmail(userData.attributes?.email || "");
    } else {
      setEmail("");
    }
  }, [userData]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(user =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [users, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectUser = (user: User) => {
    updateUserData(user);
  };

  const handleCloseAlert = () => {
    setAlertConfig(prev => ({ ...prev, open: false }));
  };

  const handleUpdateUser = async () => {
    if (!userData) {
      return;
    }

    try {
      setIsLoading(true);
      
      await fetchUsers();
      
      setAlertConfig({
        open: true,
        type: "success",
        message: "Usuario actualizado exitosamente",
        title: "¡Éxito!",
        onConfirm: () => handleCloseAlert(),
      });
    } catch (error) {
      console.error("Error al actualizar:", error);
      let errorMessage = 'Error desconocido';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      }
      
      setAlertConfig({
        open: true,
        type: "error",
        message: `Error al actualizar usuario: ${errorMessage}`,
        title: "Error",
        onConfirm: () => handleCloseAlert(),
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const hasGroups = (user) => {
    return user && 
           user.groups && 
           Array.isArray(user.groups) && 
           user.groups.length > 0;
  };

  const getFirstGroup = (user) => {
    if (!hasGroups(user)) return null;
    return user.groups[0];
  };

  const getAdditionalGroupsCount = (user) => {
    if (!hasGroups(user)) return 0;
    return user.groups.length - 1;
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <CustomTextField
          isFullWidth
          placeholder="Buscar usuario..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>

      <SelectContainer $isDark={isDarkMode}>
        <Box>
          <SelectLabel $isDark={isDarkMode}>Usuarios</SelectLabel>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <Box
                  key={user.username}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "12px",
                    backgroundColor:
                      userData?.username === user.username
                        ? isDarkMode
                          ? "#3F3F46"
                          : "#D1D5DB"
                        : isDarkMode
                        ? "#27272A"
                        : "#E5E7EB",
                    borderRadius: "8px",
                    transition: "all 0.2s ease",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: isDarkMode ? "#3F3F46" : "#D1D5DB",
                    },
                  }}
                  onClick={() => handleSelectUser(user)}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", flex: 1 }}
                  >
                    <Users
                      size={20}
                      color={isDarkMode ? "#71717A" : "#6B7280"}
                    />
                    <Box
                      sx={{
                        ml: 2,
                        color: isDarkMode ? "#FFFFFF" : "#1F2937",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {user.username}
                      
                      {hasGroups(user) && (
                        <GroupBadge $isDark={isDarkMode}>
                          <Users size={12} />
                          {getFirstGroup(user)}
                          {getAdditionalGroupsCount(user) > 0 && 
                            ` +${getAdditionalGroupsCount(user)}`}
                        </GroupBadge>
                      )}
                    </Box>
                  </Box>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteUser(user.username);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "8px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        backgroundColor: isDarkMode ? "#2A2A2D" : "#F3F4F6",
                      },
                      "&:active": {
                        backgroundColor: isDarkMode ? "#333336" : "#E5E7EB",
                      },
                    }}
                  >
                    <Trash2 size={18} color="#FF3D3D" />
                  </Box>
                </Box>
              ))
            ) : (
              <NoResultsMessage $isDark={isDarkMode}>
                No se encontraron usuarios
              </NoResultsMessage>
            )}
          </Box>
        </Box>
      </SelectContainer>

      {userData && (
        <ContentBox $isDark={isDarkMode}>
          <UserEditor
            userData={userData}
            isLoading={isLoading}
            handleUpdate={handleUpdateUser}
            email={email}
            setEmail={setEmail}
            isDarkMode={isDarkMode}
            textConfig={textConfig}
          />
        </ContentBox>
      )}
    </>
  );
};

export default AdminUsersTab;