import React from "react";
import { Dialog, DialogActions, DialogContent, Button, Box, Fade, IconButton, Typography } from "@mui/material";
import { Check, AlertCircle, X, Loader } from "lucide-react";
import { styled } from "@mui/material/styles";

interface AlertProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: (() => void) | (() => Promise<void>);
  type: "loading" | "success" | "error" | "confirm";
  message: string;
  title?: string;
  $isDark?: boolean;
  textConfig: Record<string, string>;
}

interface StyledProps {
  $isDark?: boolean;
}

const IconWrapper = styled(Box)<StyledProps & { type: string }>(
  ({ $isDark = true, type }) => {
    const getBorder = () => {
      switch (type) {
        case "success":
          return `2px solid ${$isDark ? "#22C55E" : "#16A34A"}`;
        case "error":
          return `2px solid ${$isDark ? "#FF3D3D" : "#DC2626"}`;
        case "confirm":
          return `2px solid ${$isDark ? "#FF9800" : "#D97706"}`;
        default:
          return "none";
      }
    };

    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      padding: "0.5rem",
      border: getBorder(),
      backgroundColor: $isDark ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.9)",
      boxShadow: $isDark 
        ? "0 4px 6px -1px rgba(0, 0, 0, 0.2)" 
        : "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
      margin: "0 auto",
      width: "50px",
      height: "50px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      animation: type === "loading" ? "none" : "pulseAnimation 2s infinite",
      "@keyframes pulseAnimation": {
        "0%": {
          transform: "scale(1)",
          boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.2)",
        },
        "70%": {
          transform: "scale(1.05)",
          boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
        },
        "100%": {
          transform: "scale(1)",
          boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
        },
      },
    };
  }
);

const StyledButton = styled(Button)<
  StyledProps & { $variant?: "primary" | "danger" | "neutral" }
>(({ $isDark = true, $variant = "primary" }) => {
  const colors = {
    primary: {
      dark: {
        bg: "#22C55E",
        hover: "#16A34A",
      },
      light: {
        bg: "#16A34A",
        hover: "#059669",
      },
    },
    danger: {
      dark: {
        bg: "#FF3D3D",
        hover: "#FF5252",
      },
      light: {
        bg: "#DC2626",
        hover: "#B91C1C",
      },
    },
    neutral: {
      dark: {
        bg: "#515151",
        hover: "#666666",
      },
      light: {
        bg: "#6B7280",
        hover: "#4B5563",
      },
    },
  };

  const themeColors = colors[$variant][$isDark ? "dark" : "light"];

  return {
    backgroundColor: themeColors.bg,
    color: "#FFFFFF",
    padding: "0.5rem 1.5rem",
    fontWeight: 500,
    textTransform: "none",
    borderRadius: "8px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: themeColors.hover,
      transform: "translateY(-2px)",
      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    },
  };
});

export const CustomAlert: React.FC<AlertProps> = ({
  open,
  onClose,
  onConfirm,
  type,
  message,
  title,
  $isDark = true,
  textConfig,
}) => {
  const getIconColor = (type: string) => {
    const colors = {
      loading: $isDark ? "#22C55E" : "#16A34A",
      success: $isDark ? "#22C55E" : "#16A34A",
      error: $isDark ? "#FF3D3D" : "#DC2626",
      confirm: $isDark ? "#FF9800" : "#D97706",
    };
    return colors[type] || colors.loading;
  };

  const getIcon = () => {
    switch (type) {
      case "loading":
        return <Loader className="animate-spin" size={35} color={getIconColor("loading")} />;
      case "success":
        return <Check size={35} color={getIconColor("success")} />;
      case "error":
        return <X size={35} color={getIconColor("error")} />;
      case "confirm":
        return <AlertCircle size={35} color={getIconColor("confirm")} />;
      default:
        return null;
    }
  };

  const getDefaultTitle = () => {
    switch (type) {
      case "loading":
        return "Cargando";
      case "success":
        return "¡Éxito!";
      case "error":
        return "Error";
      case "confirm":
        return "Confirmación";
      default:
        return "";
    }
  };

  const handleClose = () => {
    if (type !== "loading") {
      onClose();
    }
  };

  const handleConfirm = async () => {
    if (onConfirm) {
      try {
        const result = onConfirm();
        if (result instanceof Promise) {
          await result;
        }
      } catch (error) {
        console.error("Error en onConfirm:", error);
      }
    }
    
    if (type !== "success") {
      handleClose();
    }
  };

  const getButtonText = () => {
    switch (type) {
      case "success":
        return textConfig?.CUSTOM_ALERT_BUTTON_ACCEPT || "Aceptar";
      case "error":
        return textConfig?.CUSTOM_ALERT_BUTTON_CLOSE || "Cerrar";
      default:
        return textConfig?.CUSTOM_ALERT_BUTTON_CLOSE || "Cerrar";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: $isDark ? "#27272A" : "#FFFFFF",
          minWidth: "350px",
          padding: "0.5rem",
          borderRadius: "12px",
          boxShadow: $isDark
            ? "0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -2px rgba(0, 0, 0, 0.3)"
            : "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
          border: $isDark ? "1px solid #3F3F46" : "none",
          overflow: "hidden",
        },
      }}
      TransitionComponent={Fade}
      transitionDuration={300}
    >
      <Fade in={open} timeout={400}>
        <Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.75rem 0.75rem 0 0.75rem',
            position: 'relative'
          }}>
            {type !== "loading" && (
              <IconButton 
                onClick={handleClose} 
                aria-label="close"
                sx={{
                  color: $isDark ? "#9CA3AF" : "#6B7280",
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: $isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)",
                    color: $isDark ? "#FFFFFF" : "#1F2937",
                  },
                }}
              >
                <X size={20} />
              </IconButton>
            )}
          </Box>
          
          <DialogContent sx={{ padding: "0.5rem 1.5rem", marginTop: "0.5rem" }}>
            <IconWrapper $isDark={$isDark} type={type} style={{ marginBottom: '1.25rem' }}>
              {getIcon()}
            </IconWrapper>
            
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: $isDark ? "#FFFFFF" : "#1F2937",
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "1.25rem",
                  marginBottom: "0.75rem"
                }}
              >
                {title || getDefaultTitle()}
              </Typography>
            </Box>
            
            <Typography
              variant="body1"
              sx={{
                color: $isDark ? "#FFFFFF" : "#1F2937",
                textAlign: "center",
                fontSize: "1rem",
                marginBottom: "1.5rem"
              }}
            >
              {message}
            </Typography>
            
            {(type === "success" || type === "error") && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 1, 
                mb: 1 
              }}>
                <StyledButton
                  onClick={handleClose}
                  $isDark={$isDark}
                  $variant={type === "success" ? "primary" : "danger"}
                >
                  {getButtonText()}
                </StyledButton>
              </Box>
            )}
          </DialogContent>
          {type === "confirm" && (
            <DialogActions sx={{ 
              justifyContent: "center", 
              gap: 2, 
              padding: "0.5rem 1.5rem 1.5rem 1.5rem" 
            }}>
              <StyledButton
                onClick={handleClose}
                $isDark={$isDark}
                $variant="danger"
              >
                {textConfig?.CUSTOM_ALERT_BUTTON_CANCEL || "Cancelar"}
              </StyledButton>
              <StyledButton
                onClick={handleConfirm}
                $isDark={$isDark}
                $variant="primary"
              >
                {textConfig?.CUSTOM_ALERT_BUTTON_CONFIRM || "Confirmar"}
              </StyledButton>
            </DialogActions>
          )}
        </Box>
      </Fade>
    </Dialog>
  );
};

export default CustomAlert;