import React, { useContext } from "react";
import { ThemeContext } from "../ThemeContext";

interface NewChatButtonProps {
  onClick: () => void;
  textConfig: Record<string, string>;
}

const NewChatButton: React.FC<NewChatButtonProps> = ({ onClick, textConfig }) => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <button
      className={`
        flex items-center w-full py-2 justify-center shadow-xl rounded-full
        transition-all duration-300
        ${
          isDarkMode
            ? "bg-neutral-900 border-2 border-green-500 text-green-500 hover:bg-green-500 hover:text-neutral-900"
            : "bg-white border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white"
        }
      `}
      onClick={onClick}
    >
      <span className="mr-2 text-lg font-bold">+</span> {textConfig?.LEFTNAV_TEXT_NEW_CHAT || "Chat Nuevo"}
    </button>
  );
};

export default NewChatButton;