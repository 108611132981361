import Cookies from 'js-cookie';
import { refreshToken } from '../services/loginApi';

const performFetch = async (url, options, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    ...(options.headers || {})
  };

  return fetch(url, {
    ...options,
    headers
  });
};

const fetchWithAuth = async (url, options = {}) => {
  let accessToken = Cookies.get('accessToken');
  
  if (!accessToken) {
    redirectToLogin(); 
    throw new Error("No hay token de acceso");
  }
  
  try {
    let response = await performFetch(url, options, accessToken);

    if (response.status === 401) {
      try {
        await refreshToken();
        const newAccessToken = Cookies.get('accessToken');
        
        if (!newAccessToken) {
          throw new Error('No se pudo renovar el token');
        }
        
        response = await performFetch(url, options, newAccessToken);
      } catch (refreshError) {
        console.error('Error al refrescar el token:', refreshError);
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        Cookies.remove('expirationDate');
        redirectToLogin();
        throw new Error('Sesión expirada');
      }
    }
    
    return response;
  } catch (error) {
    console.error('Error en la petición:', error);
    throw error;
  }
};

const redirectToLogin = () => {
  if (typeof window !== 'undefined') { 
    window.location.href = '/login'; 
  }
};

export default fetchWithAuth;