import React, { useContext, useEffect, useState } from "react";
import { BaseModal } from "../ui/BaseModal.tsx";
import { Box, Button, styled, TextField } from "@mui/material";
import {
  LayoutGrid,
  Menu,
  Plus,
  User,
  ChevronUp,
  ChevronDown,
  X,
  Edit,
  Trash,
} from "lucide-react";
import {
  getProfiles,
  createProfile,
  updateProfile,
  deleteProfile,
} from "../../services/profileApi.jsx";
import { ContextApp } from "../../utils/Context.jsx";
import { ActionButton } from "../ui/ActionButton.tsx";
import { CustomTextField } from "../ui/CustomTextField.tsx";
import { ThemeContext } from "../ThemeContext.jsx";

interface ProfileManagementModalProps {
  onClose: () => void;
  isOpen: boolean;
}
interface EditFormState {
  profile_id: string;
  collections: string[];
  prompts: Array<[string, string]>;
}
interface Profile {
  profile_id: string;
  backend_config: {
    collections: string[];
    prompt: Array<[string, string]>;
  };
}

interface PromptItem {
  id: string;
  type: string;
  content: string;
}

interface StyledProps {
  $isDark?: boolean;
}
const PromptEditCard = styled(Box)<StyledProps>`
  background-color: ${(props) => (props.$isDark ? "#27272A" : "#F3F4F6")};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  &.moving-up {
    animation: moveUp 0.3s ease-in-out;
    background-color: ${(props) => (props.$isDark ? "#383838" : "#E5E7EB")};
  }
  &.moving-down {
    animation: moveDown 0.3s ease-in-out;
    background-color: ${(props) => (props.$isDark ? "#383838" : "#E5E7EB")};
  }
  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
      background-color: ${(props) => (props.$isDark ? "#404040" : "#D1D5DB")};
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes moveDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
      background-color: ${(props) => (props.$isDark ? "#404040" : "#D1D5DB")};
    }
    100% {
      transform: translateY(0);
    }
  }
  & .prompt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }
  & .prompt-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  & .move-button {
    min-width: 44px;
    height: 44px;
    padding: 10px;
    background-color: ${(props) => (props.$isDark ? "#1F1F1F" : "#E5E7EB")};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    border: 1px solid ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    &:hover:not(:disabled) {
      background-color: ${(props) => (props.$isDark ? "#2D2D2D" : "#D1D5DB")};
      transform: scale(1.05);
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
const ProfileListContainer = styled(Box)<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: ${(props) => (props.$isDark ? "#18181B" : "#F3F4F6")};
  border-radius: 12px;
  border: ${(props) => (props.$isDark ? "none" : "1px solid #E5E7EB")};
  min-height: 200px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  ,
  &::-webkit-scrollbar-track {
    background: ${(props) => (props.$isDark ? "#27272A" : "#F3F4F6")};
    border-radius: 0 12px 12px 0;
  }
  ,
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    border-radius: 4px;
  }
`;

const CreateProfileButton = styled(Button)<StyledProps>`
  background-color: #22c55e;
  color: #ffffff;
  text-transform: none;
  padding: 8px 16px;
  border-radius: 8px;
  &:hover {
    background-color: #16a34a;
  }
  margin-left: auto;
`;

const ProfileGrid = styled(Box)<StyledProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
  padding: 16px;
  background-color: ${(props) => (props.$isDark ? "#18181B" : "#F9FAFB")};
  border-radius: 12px;
  border: ${(props) => (props.$isDark ? "none" : "1px solid #E5E7EB")};
  max-height: 250px;
  margin-bottom: 16px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  ,
  &::-webkit-scrollbar-track {
    background: ${(props) => (props.$isDark ? "#27272A" : "#F3F4F6")};
    border-radius: 0 12px 12px 0;
  }
  ,
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    border-radius: 4px;
  }
`;

const ProfileList = styled(Box)<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: ${(props) => (props.$isDark ? "#18181B" : "#F9FAFB")};
  border-radius: 12px;
  border: ${(props) => (props.$isDark ? "none" : "1px solid #E5E7EB")};
  height: 300px;
  margin-bottom: 16px;
  overflow-y: auto;
`;
const ProfileItem = styled(Box)<{ isSelected?: boolean; $isDark?: boolean }>(
  ({ isSelected, $isDark }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "16px",
    backgroundColor: isSelected ? "#22C55E" : $isDark ? "#27272A" : "#F3F4F6",
    borderRadius: "8px",
    cursor: "pointer",
    height: "100px",
    width: "100%",
    "&:hover": {
      backgroundColor: isSelected ? "#16A34A" : $isDark ? "#3F3F46" : "#E5E7EB",
    },
  })
);

const StyledSelect = styled("select")<StyledProps>`
  background-color: ${(props) => (props.$isDark ? "#27272A" : "#F9FAFB")};
  color: ${(props) => (props.$isDark ? "#FFFFFF" : "#1F2937")};
  padding: 8px 12px;
  padding-right: 32px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
  width: 100%;
  appearance: none;
  background-image: ${(props) =>
    props.$isDark
      ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`
      : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`};
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  &:focus {
    outline: none;
    border-color: #22c55e;
  }
`;

const StyledTextField = styled(TextField)<StyledProps>(({ $isDark }) => ({
  "& .MuiOutlinedInput-root": {
    color: $isDark ? "#FFFFFF" : "#1F2937",
    backgroundColor: $isDark ? "#27272A" : "#F9FAFB",
    "& fieldset": {
      borderColor: $isDark ? "#3F3F46" : "#D1D5DB",
    },
    "&:hover fieldset": {
      borderColor: "#22C55E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#22C55E",
    },
  },
  "& .MuiInputLabel-root": {
    color: $isDark ? "#FFFFFF" : "#6B7280",
  },
}));
const CollectionContainer = styled(Box)<StyledProps>`
  padding: 16px;
  background-color: ${(props) => (props.$isDark ? "#18181B" : "#F9FAFB")};
  border-radius: 12px;
  border: ${(props) => (props.$isDark ? "none" : "1px solid #E5E7EB")};
  margin-bottom: 16px;
`;

const CollectionItem = styled(Box)<StyledProps>`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${(props) => (props.$isDark ? "#27272A" : "#F3F4F6")};
  color: ${(props) => (props.$isDark ? "#FFFFFF" : "#1F2937")};
  border-radius: 8px;
  margin-bottom: 8px;
  '& input[type="checkbox"]': {
    marginRight: "8px",
    appearance: "none",
    width: "20px",
    height: "20px",
    border: "2px solid #22C55E",
    borderRadius: "4px",
    cursor: "pointer",
    position: "relative",
    "&:checked": {
      backgroundColor: "#22C55E",
      "&:after": {
        content: '"✓"',
        position: "absolute",
        left: "4px",
        top: "-2px",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  },
`;

const StyledTextarea = styled("textarea")<StyledProps>`
  background-color: ${(props) => (props.$isDark ? "#27272A" : "#F9FAFB")};
  color: ${(props) => (props.$isDark ? "#FFFFFF" : "#1F2937")};
  border: 1px solid ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
  border-radius: 8px;
  padding: 8px;
  font-family: inherit;
  font-size: inherit;
  resize: none;
  line-height: 1.5;
  height: 120px;
  width: 100%;
  overflow-y: auto;
  &:focus {
    outline: none;
    border-color: #22c55e;
  }
  &::placeholder {
    color: ${(props) => (props.$isDark ? "#71717A" : "#9CA3AF")};
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => (props.$isDark ? "#27272A" : "#F3F4F6")};
    border-radius: 0 8px 8px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    border-radius: 4px;
  }
`;
const PromptCard = styled(Box)<StyledProps>`
  background-color: ${(props) => (props.$isDark ? "#27272A" : "#F3F4F6")};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  &.moving-up {
    animation: moveUp 0.3s ease-in-out;
    background-color: ${(props) => (props.$isDark ? "#383838" : "#E5E7EB")};
  }
  &.moving-down {
    animation: moveDown 0.3s ease-in-out;
    background-color: ${(props) => (props.$isDark ? "#383838" : "#E5E7EB")};
  }
  @keyframes moveUp {
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(-10px)",
      background-color: ${(props) => (props.$isDark ? "#404040" : "#D1D5DB")};
    },
    "100%": {
      transform: "translateY(0)",
    },
  }
  @keyframes moveDown {
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(10px)",
      background-color: ${(props) => (props.$isDark ? "#404040" : "#D1D5DB")};
    },
    "100%": {
      transform: "translateY(0)",
    },
  }
  & .prompt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }
  & .prompt-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  & .move-button {
    min-width: 44px;
    height: 44px;
    padding: 10px;
    background-color: ${(props) => (props.$isDark ? "#1F1F1F" : "#E5E7EB")};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    border: 1px solid ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    &:hover:not(:disabled) {
      background-color: ${(props) => (props.$isDark ? "#2D2D2D" : "#D1D5DB")};
      transform: scale(1.05);
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  & .delete-button {
    min-width: 44px;
    height: 44px;
    padding: 10px;
    background-color: ${(props) => (props.$isDark ? "#1F1F1F" : "#E5E7EB")};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    border: 1px solid ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    &:hover {
      background-color: #991B1B;
      transform: scale(1.05);
    }
  }
`;
const ProfileManagementModal = ({
  onClose,
  isOpen,
}: ProfileManagementModalProps) => {
  const { collections } = useContext(ContextApp);
  const { isDarkMode } = useContext(ThemeContext);
  const [isCreatingProfile, setIsCreatingProfile] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
    null
  );
  const [profileName, setProfileName] = useState("");
  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
  const [selectedPromptType, setSelectedPromptType] = useState("system");
  const [promptContent, setPromptContent] = useState("");
  const [prompts, setPrompts] = useState<PromptItem[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [editingProfile, setEditingProfile] = useState<Profile | null>(null);
  const [editForm, setEditForm] = useState<EditFormState>({
    profile_id: "",
    collections: [],
    prompts: [],
  });
  const [alertConfig, setAlertConfig] = useState({
    open: false,
    type: "success" as "success" | "error" | "loading",
    message: "",
  });

  const promptTypes = [
    "contexto de los documentos",
    "agregar historial",
    "system",
  ];
  useEffect(() => {
    if (isCreatingProfile) {
      setPrompts([
        {
          id: "history",
          type: "agregar historial",
          content: "{history}",
        },
        {
          id: "user-query",
          type: "consulta del usuario",
          content: "{question}",
        },
      ]);
    }
  }, [isCreatingProfile]);

  useEffect(() => {
    const fetchProfiles = async () => {
      setIsLoading(true);
      setError("");
      try {
        const data = await getProfiles();
        if (Array.isArray(data)) {
          setProfiles(data);
        } else {
          throw new Error("La respuesta de la API no es un array");
        }
      } catch (error) {
        setError(
          "Error al cargar los perfiles. Por favor, intente nuevamente."
        );
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfiles();
  }, []);
  const handleProfileClick = (profileId: string) => {
    setSelectedProfileId(profileId === selectedProfileId ? null : profileId);
  };

  const handleCollectionToggle = (collection: string) => {
    setSelectedCollections((prev) =>
      prev.includes(collection)
        ? prev.filter((c) => c !== collection)
        : [...prev, collection]
    );
  };

  const handleEditCollectionToggle = (collection: string) => {
    setEditForm((prev: EditFormState) => ({
      ...prev,
      collections: prev.collections.includes(collection)
        ? prev.collections.filter((c: string) => c !== collection)
        : [...prev.collections, collection],
    }));
  };

  const handleAddPrompt = () => {
    if (!selectedPromptType) {
      setErrorMessage("Debe seleccionar un tipo de prompt");
      return;
    }

    if (
      selectedPromptType === "contexto de los documentos" &&
      prompts.some((p) => p.type === "contexto de los documentos")
    ) {
      setErrorMessage(
        "Solo puede haber un prompt de contexto de los documentos"
      );
      return;
    }

    if (
      selectedPromptType === "agregar historial" &&
      prompts.some((p) => p.type === "agregar historial")
    ) {
      setErrorMessage("Solo puede haber un prompt de historial");
      return;
    }

    let finalContent = "";
    switch (selectedPromptType) {
      case "agregar historial":
        finalContent = "{history}";
        break;
      case "contexto de los documentos":
        finalContent = "{context}";
        break;
      default:
        if (!promptContent.trim()) {
          setErrorMessage("El contenido del prompt no puede estar vacío");
          return;
        }
        finalContent = promptContent.trim();
    }

    const newPrompt = {
      id: Date.now().toString(),
      type: selectedPromptType,
      content: finalContent,
    };

    setPrompts((prev) => {
      const userQueryIndex = prev.findIndex(
        (p) => p.type === "consulta del usuario"
      );

      if (selectedPromptType === "agregar historial") {
        const withoutUserQuery = prev.filter(
          (p) => p.type !== "consulta del usuario"
        );
        return [...withoutUserQuery, newPrompt, prev[userQueryIndex]];
      }

      const historyIndex = prev.findIndex(
        (p) => p.type === "agregar historial"
      );

      if (historyIndex !== -1) {
        const result = [...prev];
        result.splice(historyIndex, 0, newPrompt);
        return result;
      } else {
        const result = [...prev];
        result.splice(userQueryIndex, 0, newPrompt);
        return result;
      }
    });

    setPromptContent("");
    setErrorMessage("");
  };
  const handleStartEdit = (profile: Profile) => {
    setEditingProfile(profile);
    setEditForm({
      profile_id: profile.profile_id,
      collections: profile.backend_config?.collections || [],
      prompts: profile.backend_config?.prompt || [],
    });
  };

  const handlePromptUpdate = (
    index: number,
    field: "type" | "content",
    value: string
  ) => {
    setEditForm((prev) => {
      const newPrompts = [...prev.prompts];
      if (field === "type") {
        newPrompts[index][0] = value;
      } else {
        newPrompts[index][1] = value;
      }
      return {
        ...prev,
        prompts: newPrompts,
      };
    });
  };

  const fetchProfiles = async () => {
    try {
      const data = await getProfiles();
      if (Array.isArray(data)) {
        setProfiles(data);
      }
    } catch (error) {
      console.error("Error al obtener perfiles:", error);
    }
  };

  const handleSaveEdit = async () => {
    if (!editingProfile) {
      console.error("No hay perfil en edición");
      return;
    }

    const { isValid, errorMessage } = validateEditPromptOrder(editForm.prompts);

    if (!isValid) {
      setErrorMessage(errorMessage);
      return;
    }

    try {
      setAlertConfig({
        open: true,
        type: "loading",
        message: "Actualizando perfil...",
      });

      await updateProfile(editingProfile.profile_id, {
        profile_id: editForm.profile_id,
        backend_config: {
          collections: editForm.collections,
          prompt: editForm.prompts,
        },
      });

      await fetchProfiles();
      setEditingProfile(null);
      setErrorMessage("");
      setAlertConfig({
        open: true,
        type: "success",
        message: "Perfil actualizado exitosamente",
      });
    } catch (error) {
      console.error("Error al actualizar perfil:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al actualizar el perfil",
      });
    }
  };
  const handleDeleteProfile = async (profileId: string) => {
    try {
      setAlertConfig({
        open: true,
        type: "loading",
        message: "Eliminando perfil...",
      });

      await deleteProfile(profileId);
      await fetchProfiles();

      setAlertConfig({
        open: true,
        type: "success",
        message: "Perfil eliminado exitosamente",
      });
    } catch (error) {
      console.error("Error al eliminar perfil:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al eliminar el perfil",
      });
    }
  };
  const handleRemovePrompt = (id: string) => {
    const prompt = prompts.find((p) => p.id === id);

    if (prompt?.type === "consulta del usuario") {
      return;
    }

    setPrompts((prev) => prev.filter((prompt) => prompt.id !== id));
  };

  const validatePromptOrder = (
    prompts: PromptItem[]
  ): { isValid: boolean; errorMessage: string } => {
    const historyIndex = prompts.findIndex(
      (p) => p.type === "agregar historial"
    );
    const userQueryIndex = prompts.findIndex(
      (p) => p.type === "consulta del usuario"
    );

    if (historyIndex === -1) {
      return { isValid: true, errorMessage: "" };
    }

    if (userQueryIndex === -1) {
      return { isValid: true, errorMessage: "" };
    }

    if (historyIndex > userQueryIndex) {
      return {
        isValid: false,
        errorMessage: "Error: 'history' debe estar arriba de 'user-query'.",
      };
    }

    if (userQueryIndex - historyIndex !== 1) {
      return {
        isValid: false,
        errorMessage:
          "Error: No puede haber tarjetas entre 'history' y 'user-query'.",
      };
    }

    return { isValid: true, errorMessage: "" };
  };

  const handleEditMovePrompt = (index: number, direction: "up" | "down") => {
    const cards = document.querySelectorAll(".prompt-edit-card");

    if (index >= 0 && index < cards.length) {
      const card = cards[index] as HTMLElement;

      if (card) {
        card.classList.add(direction === "up" ? "moving-up" : "moving-down");

        setTimeout(() => {
          setEditForm((prev) => {
            const newPrompts = [...prev.prompts];

            if (direction === "up" && index > 0) {
              [newPrompts[index], newPrompts[index - 1]] = [
                newPrompts[index - 1],
                newPrompts[index],
              ];
            } else if (
              direction === "down" &&
              index < prev.prompts.length - 1
            ) {
              [newPrompts[index], newPrompts[index + 1]] = [
                newPrompts[index + 1],
                newPrompts[index],
              ];
            }

            return {
              ...prev,
              prompts: newPrompts,
            };
          });

          setTimeout(() => {
            if (card) {
              card.classList.remove("moving-up", "moving-down");
            }
          }, 400);
        }, 200);
      }
    }
  };

  const validateCreatePromptOrder = (
    prompts: PromptItem[]
  ): { isValid: boolean; errorMessage: string } => {
    const historyIndex = prompts.findIndex(
      (p) => p.type === "agregar historial"
    );
    const userQueryIndex = prompts.findIndex(
      (p) => p.type === "consulta del usuario"
    );

    if (historyIndex === -1) {
      return { isValid: true, errorMessage: "" };
    }

    if (userQueryIndex === -1) {
      return { isValid: true, errorMessage: "" };
    }

    if (historyIndex > userQueryIndex) {
      return {
        isValid: false,
        errorMessage: "Error: 'history' debe estar arriba de 'user-query'.",
      };
    }

    if (userQueryIndex - historyIndex !== 1) {
      return {
        isValid: false,
        errorMessage:
          "Error: No puede haber tarjetas entre 'history' y 'user-query'.",
      };
    }

    return { isValid: true, errorMessage: "" };
  };

  const validateEditPromptOrder = (
    prompts: Array<[string, string]>
  ): { isValid: boolean; errorMessage: string } => {
    const historyIndex = prompts.findIndex(
      (p) => p[0] === "placeholder" && p[1] === "{history}"
    );
    const questionIndex = prompts.findIndex(
      (p) => p[0] === "human" && p[1] === "{question}"
    );

    if (historyIndex === -1 || questionIndex === -1) {
      return { isValid: true, errorMessage: "" };
    }

    if (historyIndex > questionIndex) {
      return {
        isValid: false,
        errorMessage: "Error: 'history' debe estar arriba de 'question'.",
      };
    }

    if (questionIndex - historyIndex !== 1) {
      return {
        isValid: false,
        errorMessage:
          "Error: No puede haber prompts entre 'history' y 'question'.",
      };
    }

    return { isValid: true, errorMessage: "" };
  };
  const handleCreateMovePrompt = (index: number, direction: "up" | "down") => {
    const cards = document.querySelectorAll(".prompt-card");

    if (index >= 0 && index < cards.length) {
      const card = cards[index] as HTMLElement;

      if (card) {
        card.classList.add(direction === "up" ? "moving-up" : "moving-down");

        setTimeout(() => {
          setPrompts((prev) => {
            const newPrompts = [...prev];
            if (direction === "up" && index > 0) {
              [newPrompts[index], newPrompts[index - 1]] = [
                newPrompts[index - 1],
                newPrompts[index],
              ];
            } else if (direction === "down" && index < prev.length - 1) {
              [newPrompts[index], newPrompts[index + 1]] = [
                newPrompts[index + 1],
                newPrompts[index],
              ];
            }

            const historyIndex = newPrompts.findIndex(
              (p) => p.type === "agregar historial"
            );
            const userQueryIndex = newPrompts.findIndex(
              (p) => p.type === "consulta del usuario"
            );

            if (
              historyIndex !== -1 &&
              userQueryIndex !== -1 &&
              historyIndex > userQueryIndex
            ) {
              setErrorMessage(
                "Error: 'history' debe estar arriba de 'user-query'."
              );
              return prev;
            }

            return newPrompts;
          });

          setTimeout(() => {
            if (card) {
              card.classList.remove("moving-up", "moving-down");
            }
          }, 400);
        }, 200);
      }
    }
  };
  const canEditPrompt = (type: string, content: string) => {
    return type === "system" && content !== "{context}";
  };
  const handleCreateProfile = async () => {
    if (!profileName.trim()) {
      setErrorMessage("El nombre del perfil es obligatorio");
      return;
    }
    const { isValid, errorMessage } = validateCreatePromptOrder(prompts);
    if (!isValid) {
      setErrorMessage(errorMessage);
      return;
    }
    const hasSystemContext = prompts.some(
      (p) => p.type === "contexto de los documentos"
    );
    const hasCollections = selectedCollections.length > 0;

    if (hasSystemContext !== hasCollections) {
      if (hasSystemContext) {
        setErrorMessage(
          "Has agregado 'contexto de los documentos' pero no has seleccionado ninguna colección. Debes seleccionar al menos una colección."
        );
        return;
      } else {
        setErrorMessage(
          "Has seleccionado colecciones pero no has agregado 'contexto de los documentos'. Debes agregar el contexto de los documentos."
        );
        return;
      }
    }

    try {
      setIsLoading(true);
      setErrorMessage("");

      const sanitizedProfileId = profileName
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9_-]/g, "_");

      const formattedPrompts = prompts.map((prompt) => {
        switch (prompt.type) {
          case "consulta del usuario":
            return ["human", "{question}"];
          case "agregar historial":
            return ["placeholder", "{history}"];
          case "contexto de los documentos":
            return ["system", "{context}"];
          default:
            return [prompt.type.toLowerCase(), prompt.content];
        }
      });

      const newProfile = {
        profile_id: sanitizedProfileId,
        backend_config: {
          collections: selectedCollections,
          prompt: formattedPrompts,
        },
      };

      await createProfile(newProfile);
      const updatedProfiles = await getProfiles();
      setProfiles(updatedProfiles);
      setIsCreatingProfile(false);
      resetForm();
      setAlertConfig({
        open: true,
        type: "success",
        message: "Perfil creado exitosamente",
      });
    } catch (error) {
      console.error("Error al crear perfil:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al crear el perfil",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setProfileName("");
    setSelectedCollections([]);
    setPrompts([]);
    setErrorMessage("");
    setSelectedPromptType("system");
    setPromptContent("");
  };
  const renderPromptInput = () => {
    const requiresContent = ["system", "human", "ai"].includes(
      selectedPromptType
    );

    return (
      <Box sx={{ mb: 2 }}>
        <Box sx={{ color: isDarkMode ? "#FFFFFF" : "#1F2937", mb: 1 }}>
          Agregar Prompt
        </Box>
        <StyledSelect
          value={selectedPromptType}
          onChange={(e) => setSelectedPromptType(e.target.value)}
          $isDark={isDarkMode}
          style={{ width: "100%", marginBottom: "16px" }}
        >
          {promptTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </StyledSelect>
        {requiresContent && (
          <Box sx={{ mb: 2 }}>
            <StyledTextarea
              placeholder="Ingrese el contenido (máximo 500 caracteres)"
              value={promptContent}
              onChange={(e) => {
                if (e.target.value.length <= 500) {
                  setPromptContent(e.target.value);
                }
              }}
              $isDark={isDarkMode}
            />
            <Box
              sx={{
                color: isDarkMode ? "#71717A" : "#9CA3AF",
                fontSize: "12px",
              }}
            >
              {promptContent.length}/500 caracteres
            </Box>
          </Box>
        )}
        <Button
          onClick={handleAddPrompt}
          disabled={isLoading}
          sx={{
            backgroundColor: "#22C55E",
            color: "#FFFFFF",
            "&:hover": { backgroundColor: "#16A34A" },
            minWidth: "100px",
            height: "40px",
          }}
        >
          {isLoading ? "Agregando..." : "Agregar"}
        </Button>
      </Box>
    );
  };

  const renderEditForm = () => (
    <div className="p-4 border border-gray-200 dark:border-zinc-700 rounded-lg space-y-4">
      <CustomTextField
        label="ID del Perfil"
        value={editForm.profile_id}
        onChange={(e) =>
          setEditForm({
            ...editForm,
            profile_id: e.target.value,
          })
        }
        isFullWidth
      />

      <div>
        <h4
          className={`text-sm font-medium mb-2 ${
            isDarkMode ? "text-white" : "text-gray-700"
          }`}
        >
          Colecciones
        </h4>
        <div className="max-h-[200px] overflow-y-auto border border-gray-200 dark:border-zinc-700 rounded p-2">
          {collections.map((collection: string) => (
            <div
              key={collection}
              className={`flex items-center space-x-2 p-2 rounded cursor-default ${
                isDarkMode
                  ? "text-white hover:bg-zinc-700 hover:text-white"
                  : "text-gray-900 hover:bg-gray-200"
              }`}
            >
              <input
                type="checkbox"
                checked={editForm.collections.includes(collection)}
                onChange={() => handleEditCollectionToggle(collection)}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer"
                onClick={(e) => e.stopPropagation()}
              />
              <span className="select-none">{collection}</span>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h4
          className={`text-sm font-medium mb-2 ${
            isDarkMode ? "text-white" : "text-gray-700"
          }`}
        >
          Prompts
        </h4>
        <div className="space-y-2">
          {editForm.prompts.map((prompt, index) => (
            <PromptEditCard
              key={index}
              $isDark={isDarkMode}
              className="prompt-edit-card"
            >
              <div className="prompt-header">
                <Box sx={{ color: "#22C55E", fontWeight: "bold" }}>
                  {prompt[0]}
                </Box>
                <div className="prompt-buttons">
                  <Button
                    className="move-button"
                    onClick={() => handleEditMovePrompt(index, "up")}
                    disabled={index === 0}
                    title="Mover arriba"
                  >
                    <ChevronUp
                      size={24}
                      color={isDarkMode ? "#FFFFFF" : "#1F2937"}
                    />
                  </Button>
                  <Button
                    className="move-button"
                    onClick={() => handleEditMovePrompt(index, "down")}
                    disabled={index === editForm.prompts.length - 1}
                    title="Mover abajo"
                  >
                    <ChevronDown
                      size={24}
                      color={isDarkMode ? "#FFFFFF" : "#1F2937"}
                    />
                  </Button>
                </div>
              </div>

              <Box
                sx={{
                  color: isDarkMode ? "#FFFFFF" : "#1F2937",
                  mt: 1,
                  backgroundColor: isDarkMode ? "#1F1F1F" : "#F3F4F6",
                  padding: "12px",
                  borderRadius: "6px",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                {canEditPrompt(prompt[0], prompt[1]) ? (
                  <StyledTextarea
                    value={prompt[1]}
                    onChange={(e) =>
                      handlePromptUpdate(index, "content", e.target.value)
                    }
                    $isDark={isDarkMode}
                    style={{ width: "100%", height: "80px" }}
                  />
                ) : (
                  prompt[1]
                )}
              </Box>
            </PromptEditCard>
          ))}
        </div>
      </div>

      {errorMessage && (
        <Box sx={{ color: "#FF3D3D", mt: 2, mb: 2 }}>{errorMessage}</Box>
      )}

      <div className="flex justify-end space-x-2 mt-4">
        <ActionButton
          label="Cancelar"
          onClick={() => {
            setEditingProfile(null);
            setErrorMessage("");
          }}
          variant="clean"
          Icon={<X size={16} />}
        />
        <ActionButton
          label="Guardar"
          onClick={handleSaveEdit}
          disabled={!editForm.profile_id}
          Icon={<Edit size={16} />}
        />
      </div>
    </div>
  );
  const renderProfileList = () => (
    <ProfileListContainer $isDark={isDarkMode}>
      {profiles.map((profile) => (
        <Box
          key={profile.profile_id}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            overflow: "hidden",
            marginBottom: "8px",
            border: isDarkMode ? "none" : "1px solid #E5E7EB",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "12px",
              backgroundColor:
                selectedProfileId === profile.profile_id
                  ? isDarkMode
                    ? "#3F3F46"
                    : "#D1D5DB"
                  : isDarkMode
                  ? "#27272A"
                  : "#E5E7EB",
              borderRadius: "8px 8px 0 0",
              transition: "all 0.2s ease",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: isDarkMode ? "#3F3F46" : "#D1D5DB",
              },
            }}
            onClick={() => handleProfileClick(profile.profile_id)}
          >
            <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
              <User size={20} color={isDarkMode ? "#71717A" : "#6B7280"} />
              <Box sx={{ ml: 2, color: isDarkMode ? "#FFFFFF" : "#1F2937" }}>
                {profile.profile_id}
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  handleStartEdit(profile);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: isDarkMode ? "#2A2A2D" : "#F3F4F6",
                  },
                  "&:active": {
                    backgroundColor: isDarkMode ? "#333336" : "#E5E7EB",
                  },
                }}
              >
                <Edit size={18} color={isDarkMode ? "#71717A" : "#6B7280"} />
              </Box>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteProfile(profile.profile_id);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: isDarkMode ? "#2A2A2D" : "#F3F4F6",
                  },
                  "&:active": {
                    backgroundColor: isDarkMode ? "#333336" : "#E5E7EB",
                  },
                }}
              >
                <Trash size={18} color="#FF3D3D" />
              </Box>
            </Box>
          </Box>

          {editingProfile?.profile_id === profile.profile_id
            ? renderEditForm()
            : selectedProfileId === profile.profile_id && (
                <Box
                  sx={{
                    padding: "16px",
                    backgroundColor: isDarkMode ? "#18181B" : "#FFFFFF",
                    borderRadius: "0 0 8px 8px",
                    borderTop: "none",
                    borderLeft: isDarkMode ? "none" : "1px solid #E5E7EB",
                    borderRight: isDarkMode ? "none" : "1px solid #E5E7EB",
                    borderBottom: isDarkMode ? "none" : "1px solid #E5E7EB",
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: isDarkMode ? "#9CA3AF" : "#6B7280",
                        mb: 1,
                      }}
                    >
                      Colecciones:
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {profile.backend_config?.collections?.map(
                        (collection) => (
                          <Box
                            key={collection}
                            sx={{
                              display: "inline-flex",
                              alignItems: "center",
                              px: 1,
                              py: 0.5,
                              borderRadius: "0.25rem",
                              fontSize: "0.75rem",
                              backgroundColor: isDarkMode
                                ? "#3F3F46"
                                : "#E5E7EB",
                              color: isDarkMode ? "#D1D5DB" : "#374151",
                            }}
                          >
                            {collection}
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>

                  <Box>
                    <Box
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: isDarkMode ? "#9CA3AF" : "#6B7280",
                        mb: 1,
                      }}
                    >
                      Prompts:
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      {profile.backend_config?.prompt?.map((prompt, index) => (
                        <Box
                          key={index}
                          sx={{
                            p: 1,
                            borderRadius: "0.25rem",
                            backgroundColor: isDarkMode ? "#3F3F46" : "#E5E7EB",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "0.75rem",
                              fontWeight: 500,
                              color: isDarkMode ? "#9CA3AF" : "#6B7280",
                            }}
                          >
                            {prompt[0]}
                          </Box>
                          <Box
                            sx={{
                              fontSize: "0.875rem",
                              color: isDarkMode ? "#D1D5DB" : "#374151",
                            }}
                          >
                            {prompt[1]}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
        </Box>
      ))}
    </ProfileListContainer>
  );
  const renderCreateProfile = () => (
    <Box sx={{ display: "flex", flexDirection: "column", height: "80vh" }}>
      <Box sx={{ flex: 1, overflowY: "auto", p: 2 }}>
        <StyledTextField
          fullWidth
          label="Nombre del Perfil"
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
          sx={{ mb: 2 }}
          placeholder="Ingrese el nombre del perfil"
          $isDark={isDarkMode}
        />

        <CollectionContainer $isDark={isDarkMode}>
          <Box sx={{ color: isDarkMode ? "#FFFFFF" : "#1F2937", mb: 2 }}>
            Colecciones
          </Box>
          {collections.map((collection) => (
            <CollectionItem key={collection} $isDark={isDarkMode}>
              <input
                type="checkbox"
                checked={selectedCollections.includes(collection)}
                onChange={() => handleCollectionToggle(collection)}
                style={{ marginRight: "8px" }}
              />
              <span>{collection}</span>
            </CollectionItem>
          ))}
        </CollectionContainer>

        {renderPromptInput()}

        <Box sx={{ mb: 3 }}>
          {prompts.map((prompt, index) => (
            <PromptCard
              key={prompt.id}
              className="prompt-card"
              $isDark={isDarkMode}
            >
              <Box className="prompt-header">
                <Box sx={{ color: "#22C55E", fontWeight: "bold" }}>
                  {prompt.type}
                </Box>
                <Box className="prompt-buttons">
                  <Button
                    className="move-button"
                    onClick={() => handleCreateMovePrompt(index, "up")}
                    disabled={
                      index === 0 ||
                      (prompt.type === "consulta del usuario" &&
                        prompts[index - 1].type === "agregar historial")
                    }
                    title="Mover arriba"
                  >
                    <ChevronUp
                      size={24}
                      color={isDarkMode ? "#FFFFFF" : "#1F2937"}
                    />
                  </Button>
                  <Button
                    className="move-button"
                    onClick={() => handleCreateMovePrompt(index, "down")}
                    disabled={
                      index === prompts.length - 1 ||
                      (prompt.type === "agregar historial" &&
                        prompts[index + 1].type === "consulta del usuario")
                    }
                    title="Mover abajo"
                  >
                    <ChevronDown
                      size={24}
                      color={isDarkMode ? "#FFFFFF" : "#1F2937"}
                    />
                  </Button>
                  {prompt.type !== "consulta del usuario" && (
                    <Button
                      className="delete-button"
                      onClick={() => handleRemovePrompt(prompt.id)}
                      title="Eliminar"
                    >
                      <X size={24} color={isDarkMode ? "#FFFFFF" : "#1F2937"} />
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  color: isDarkMode ? "#FFFFFF" : "#1F2937",
                  mt: 1,
                  backgroundColor: isDarkMode ? "#1F1F1F" : "#F3F4F6",
                  padding: "12px",
                  borderRadius: "6px",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                {prompt.content}
              </Box>
            </PromptCard>
          ))}
        </Box>

        {errorMessage && (
          <Box sx={{ color: "#FF3D3D", mb: 2 }}>{errorMessage}</Box>
        )}
      </Box>

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: isDarkMode ? "#2D2D2D" : "#F9FAFB",
          p: 2,
          borderTop: `1px solid ${isDarkMode ? "#3F3F46" : "#E5E7EB"}`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            onClick={() => {
              setIsCreatingProfile(false);
              resetForm();
            }}
            sx={{
              backgroundColor: isDarkMode ? "#27272A" : "#E5E7EB",
              color: isDarkMode ? "#FFFFFF" : "#1F2937",
              "&:hover": {
                backgroundColor: isDarkMode ? "#3F3F46" : "#D1D5DB",
              },
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleCreateProfile}
            disabled={isLoading}
            sx={{
              backgroundColor: "#22C55E",
              color: "#FFFFFF",
              "&:hover": { backgroundColor: "#16A34A" },
            }}
          >
            {isLoading ? "Creando..." : "Crear Perfil"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
  return (
    <BaseModal
      title="Administración de Perfil"
      onClose={onClose}
      isOpen={isOpen}
    >
      {!isCreatingProfile ? (
        <>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <CreateProfileButton
              startIcon={<Plus size={20} />}
              onClick={() => setIsCreatingProfile(true)}
              $isDark={isDarkMode}
            >
              Crear Perfil
            </CreateProfileButton>
          </Box>

          {renderProfileList()}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 4,
              pt: 3,
              borderTop: `1px solid ${isDarkMode ? "#3F3F46" : "#E5E7EB"}`,
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                backgroundColor: "#FF3D3D",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#FF5252" },
              }}
            >
              Cerrar
            </Button>
          </Box>
        </>
      ) : (
        renderCreateProfile()
      )}
    </BaseModal>
  );
};

export default ProfileManagementModal;
