import React, { useState, useEffect, useCallback } from "react";
import { BaseModal } from "../ui/BaseModal.tsx";
import { fetchServiceStatus } from "../../services/serviceStatusApi";
import { toast } from "react-toastify";
import { postRestartService } from "../../services/whatsAppStatusApi";
import { Box, Typography, MenuItem, styled } from "@mui/material";
import { CheckCircle, ChevronDown } from "lucide-react";
import { CustomTextField } from "../ui/CustomTextField.tsx";

interface ServiceMenuModalProps {
  onClose: () => void;
  isOpen: boolean;
}

interface ServiceStatus {
  service_status: string;
  completed_deployments: number;
  failed_deployments: number;
  inprogress_deployments: number;
  running_count: number;
  desired_count: number;
  unhealthy_instances: number;
  healthy_instances: number;
  other_state_instances: number;
}

const ServiceContainer = styled(Box)({
  backgroundColor: "#3A3A3A",
  borderRadius: "12px",
  padding: "20px",
  width: "100%",
  minHeight: "280px",
});

const ServiceTitle = styled(Typography)({
  color: "#22C55E",
  fontSize: "24px",
  fontWeight: 500,
  marginBottom: "20px",
});

const StatusRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "12px",
  "&:last-child": {
    marginBottom: 0,
  },
});

const StatusLabel = styled(Typography)({
  color: "#FFFFFF",
  fontSize: "16px",
});

const StatusValue = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  color: "#22C55E",
  fontSize: "16px",
});

const ActionButton = styled("button")<{ color?: string; disabled?: boolean }>(
  ({ color = "#22C55E", disabled = false }) => ({
    width: "100%",
    height: "40px",
    padding: "8px 16px",
    backgroundColor: disabled ? "#4B5563" : color,
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    cursor: disabled ? "not-allowed" : "pointer",
    fontWeight: 500,
    transition: "background-color 0.2s",
    "&:hover": {
      backgroundColor: disabled
        ? "#4B5563"
        : color === "#22C55E"
        ? "#16A34A"
        : "#EF4444",
    },
  })
);

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "7px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#30303000",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgb(70, 70, 70)",
    borderRadius: "8px",
  },
  scrollbarWidth: "thin",
  scrollbarColor: "rgb(70, 70, 70) transparent",
}));

function ServiceMenuModal({
  onClose,
  isOpen,
}: ServiceMenuModalProps): JSX.Element {
  const [backendStatus, setBackendStatus] = useState<ServiceStatus | null>(
    null
  );
  const [whatsappStatus, setWhatsappStatus] = useState<ServiceStatus | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [serviceName, setServiceName] = useState("backend");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [textConfig, setTextConfig] = useState<Record<string, string>>({});

  const fetchStatuses = useCallback(async () => {
    setIsLoading(true);
    try {
      const [backend, whatsapp] = await Promise.all([
        fetchServiceStatus("backend"),
        fetchServiceStatus("whatsapp"),
      ]);
      setBackendStatus(backend);
      setWhatsappStatus(whatsapp);
    } catch (error) {
      console.error(textConfig.SERVICE_MENU_TEXT_CONSOLE_ERROR, error);
      toast.error(textConfig.SERVICE_MENU_TEXT_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [
    textConfig.SERVICE_MENU_TEXT_ERROR,
    textConfig.SERVICE_MENU_TEXT_CONSOLE_ERROR,
  ]);

  useEffect(() => {
    const fetchTextConfig = async () => {
      try {
        const response = await fetch("/text.json");
        const data = await response.json();
        setTextConfig(data);
      } catch (error) {
        console.error(textConfig.SERVICE_MENU_TEXT_CONFIG_ERROR, error);
      }
    };

    fetchTextConfig();
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchStatuses();
      const interval = setInterval(
        fetchStatuses,
        parseInt(textConfig.SERVICE_MENU_TEXT_INTERVAL || "10000")
      );
      return () => clearInterval(interval);
    }
  }, [fetchStatuses, isOpen, textConfig.SERVICE_MENU_TEXT_INTERVAL]);

  const handleRestartService = async () => {
    setIsButtonDisabled(true);
    toast.warning(textConfig.SERVICE_MENU_TEXT_SENDING_REQUEST);
    try {
      await postRestartService(serviceName);
      toast.dismiss();
      toast.success(textConfig.SERVICE_MENU_TEXT_RESTART_SUCCESS);
      await fetchStatuses();
    } catch (error) {
      toast.dismiss();
      console.error(textConfig.SERVICE_MENU_TEXT_ACTION_ERROR, error);
      toast.error(textConfig.SERVICE_MENU_TEXT_ERROR_ACTION);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const renderServiceStatus = (
    serviceStatus: ServiceStatus | null,
    title: string
  ) => {
    if (!serviceStatus) return null;

    const totalDeployments =
      serviceStatus.completed_deployments +
      serviceStatus.failed_deployments +
      serviceStatus.inprogress_deployments;

    const totalInstances =
      serviceStatus.healthy_instances +
      serviceStatus.unhealthy_instances +
      serviceStatus.other_state_instances;

    return (
      <ServiceContainer>
        <ServiceTitle>{title}</ServiceTitle>
        <StatusRow>
          <StatusLabel>
            {textConfig.SERVICE_MENU_TEXT_SERVICE_STATUS_LABEL}
          </StatusLabel>
          <StatusValue>
            {serviceStatus.service_status ===
              textConfig.SERVICE_MENU_TEXT_ACTIVE && <CheckCircle size={20} />}
            {serviceStatus.service_status}
          </StatusValue>
        </StatusRow>
        <StatusRow>
          <StatusLabel>
            {textConfig.SERVICE_MENU_TEXT_DEPLOYMENTS_LABEL}
          </StatusLabel>
          <StatusValue>
            {`${serviceStatus.completed_deployments}/${totalDeployments}`}
          </StatusValue>
        </StatusRow>
        <StatusRow>
          <StatusLabel>
            {textConfig.SERVICE_MENU_TEXT_HEALTHY_INSTANCES_LABEL}
          </StatusLabel>
          <StatusValue>
            {`${serviceStatus.healthy_instances}/${totalInstances}`}
            {serviceStatus.healthy_instances === totalInstances && (
              <span>{textConfig.SERVICE_MENU_TEXT_HEALTHY}</span>
            )}
          </StatusValue>
        </StatusRow>
        <StatusRow>
          <StatusLabel>
            {textConfig.SERVICE_MENU_TEXT_RUNNING_TASKS_LABEL}
          </StatusLabel>
          <StatusValue>
            {`${serviceStatus.running_count}/${serviceStatus.desired_count}`}
          </StatusValue>
        </StatusRow>
      </ServiceContainer>
    );
  };

  return (
    <BaseModal
      title={textConfig.SERVICE_MENU_TEXT_TITLE}
      onClose={onClose}
      isOpen={isOpen}
      maxWidth="md"
      fullWidth
      customStyles={{
        maxWidth: "900px",
        width: "100%",
        margin: { xs: "16px", sm: "32px" },
        maxHeight: "calc(100vh - 64px)",
      }}
    >
      {isLoading ? (
        <Typography color="white">
          {textConfig.SERVICE_MENU_TEXT_LOADING}
        </Typography>
      ) : (
        <ContentBox
          sx={{
            padding: { xs: "8px", sm: "12px" },
            height: { xs: "calc(100vh - 180px)", sm: "auto" },
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: { xs: 2, sm: 3 },
            }}
          >
            {renderServiceStatus(
              backendStatus,
              textConfig.SERVICE_MENU_TEXT_BACKEND
            )}
            {renderServiceStatus(
              whatsappStatus,
              textConfig.SERVICE_MENU_TEXT_WHATSAPP
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              mt: { xs: 1, sm: 2 },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <CustomTextField
                select
                isFullWidth
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                disabled={isButtonDisabled}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "40px",
                    backgroundColor: "#2D2D2D",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    height: "40px !important",
                    padding: "0 14px",
                    color: "#fff",
                  },
                  "& .MuiSelect-icon": {
                    color: "#fff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#22c55e",
                    borderWidth: "1px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#22c55e",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#22c55e",
                    borderWidth: "2px",
                  },
                }}
                SelectProps={{
                  IconComponent: (props) => (
                    <ChevronDown
                      {...props}
                      size={20}
                      style={{
                        color: "#fff",
                        right: 8,
                      }}
                    />
                  ),
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "#3A3A3A",
                        borderRadius: "8px",
                        marginTop: "4px",
                        "& .MuiList-root": {
                          padding: "4px",
                        },
                        "& .MuiMenuItem-root": {
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#4A4A4A",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#22C55E",
                            "&:hover": {
                              backgroundColor: "#1EA34A",
                            },
                          },
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="backend">
                  {textConfig.SERVICE_MENU_TEXT_BACKEND}
                </MenuItem>
                <MenuItem value="whatsapp">
                  {textConfig.SERVICE_MENU_TEXT_WHATSAPP}
                </MenuItem>
              </CustomTextField>
            </Box>

            <Box sx={{ flex: 1 }}>
              <ActionButton
                onClick={handleRestartService}
                disabled={isButtonDisabled}
              >
                {textConfig.SERVICE_MENU_TEXT_RESTART}
              </ActionButton>
            </Box>

            <Box sx={{ flex: 1 }}>
              <ActionButton onClick={onClose} color="#DC2626">
                {textConfig.SERVICE_MENU_TEXT_CLOSE}
              </ActionButton>
            </Box>
          </Box>
        </ContentBox>
      )}
    </BaseModal>
  );
}

export default ServiceMenuModal;
