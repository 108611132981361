import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Folder, File, Trash } from "lucide-react";
import { useTheme } from "../ThemeContext";

interface FileItem {
  name: string;
  type: "file";
  hash?: string;
  collection?: string;
  onDelete?: () => void;
}

interface FolderItem {
  name: string;
  type: "folder";
  onClick?: () => void;
  onDelete?: () => void;
}

type ListItem = FileItem | FolderItem;

interface FileListProps {
  items: ListItem[];
  onDelete?: (name: string) => void; 
  viewMode: "list" | "grid";
}

export function FileList({ items, onDelete, viewMode }: FileListProps) {
  const { isDarkMode } = useTheme();

  const handleDelete = (e: React.MouseEvent, item: ListItem) => {
    e.stopPropagation();
    
    if (item.onDelete) {
      item.onDelete();
    } 
    else if (onDelete) {
      onDelete(item.name);
    }
  };

  if (viewMode === "list") {
    return (
      <Box>
        {items.map((item, index) => (
          <Box
            key={`${item.name}-${index}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            onClick={item.type === "folder" && item.onClick ? item.onClick : undefined}
            sx={{
              borderBottom: isDarkMode
                ? "1px solid #3f3f46"
                : "1px solid #e5e7eb",
              cursor: item.type === "folder" && item.onClick ? "pointer" : "default",
              "&:hover": { bgcolor: isDarkMode ? "#27272a" : "#f3f4f6" },
              transition: "background-color 0.2s",
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              {item.type === "folder" ? (
                <Folder
                  size={20}
                  className={isDarkMode ? "text-green-500" : "text-green-600"}
                />
              ) : (
                <File
                  size={20}
                  className={isDarkMode ? "text-blue-500" : "text-blue-600"}
                />
              )}
              <Typography color={isDarkMode ? "white" : "text-gray-900"}>
                {item.name}
              </Typography>
            </Box>
            {(item.onDelete || onDelete) && (
              <Trash
                size={16}
                className={`cursor-pointer ${
                  isDarkMode
                    ? "text-red-500 hover:text-red-600"
                    : "text-red-600 hover:text-red-500"
                }`}
                onClick={(e) => handleDelete(e, item)}
              />
            )}
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
        gap: 2,
        p: 1,
      }}
    >
      {items.map((item, index) => (
        <Box
          key={`${item.name}-${index}`}
          onClick={item.type === "folder" && item.onClick ? item.onClick : undefined}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
            cursor: item.type === "folder" && item.onClick ? "pointer" : "default",
            borderRadius: "8px",
            position: "relative",
            height: "100px",
            transition: "background-color 0.2s",
            "&:hover": {
              bgcolor: isDarkMode ? "#27272a" : "#f3f4f6",
              "& .delete-icon": {
                opacity: 1,
              },
            },
          }}
        >
          {item.type === "folder" ? (
            <Folder
              size={40}
              className={isDarkMode ? "text-green-500" : "text-green-600"}
            />
          ) : (
            <File
              size={40}
              className={isDarkMode ? "text-blue-500" : "text-blue-600"}
            />
          )}
          <Typography
            color={isDarkMode ? "white" : "text-gray-900"}
            sx={{
              fontSize: "0.75rem",
              mt: 1,
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              width: "100%",
            }}
          >
            {item.name}
          </Typography>
          {(item.onDelete || onDelete) && (
            <Box
              className="delete-icon"
              sx={{
                position: "absolute",
                top: 4,
                right: 4,
                opacity: 0,
                transition: "all 0.2s",
                backgroundColor: isDarkMode ? "#27272a" : "#f3f4f6",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: isDarkMode
                  ? "0 2px 4px rgba(0,0,0,0.2)"
                  : "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <Trash
                size={14}
                className={`cursor-pointer ${
                  isDarkMode
                    ? "text-red-500 hover:text-red-600"
                    : "text-red-600 hover:text-red-500"
                }`}
                onClick={(e) => handleDelete(e, item)}
              />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default FileList;