import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApp } from "../utils/Context";
import iconBot from "../images/botIcon.svg";
import iconUser from "../images/userIcon.svg";
import { Spin } from "antd";
import { ThemeContext } from "../components/ThemeContext";
import { CustomAlert } from "../components/ui/CustomAlert.tsx";

function Chat() {
  const { isDarkMode } = useContext(ThemeContext);
  const {
    message,
    showSlide,
    currentTopic,
    loadChatHistoryForTopic,
    isAwaitingNewResponse,
    setIsAwaitingNewResponse,
    alertConfig, 
    setAlertConfig,
    username,
    isGuest, 
  } = useContext(ContextApp);

  const endOfMessagesRef = useRef(null);
  const [shouldScrollToBottom] = useState(true);
  const messagesContainerRef = useRef(null);

  const chatClass = `
    w-full 
    h-[calc(90vh-160px)]
    overflow-y-scroll
    mt-24
    mb-24
    ${showSlide ? "pl-12" : "pl-2"} 
    pr-16
    sm:pl-4 
    lg:pr-24
    lg:pl-8 
    lg:pr-8
    [&::-webkit-scrollbar]:w-2
    [&::-webkit-scrollbar-track]:bg-transparent
    [&::-webkit-scrollbar-thumb]:bg-gray-400
    [&::-webkit-scrollbar-thumb]:rounded-full
    hover:[&::-webkit-scrollbar-thumb]:bg-gray-500
  `;

  const scrollToBottom = () => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  useEffect(() => {
    if (isAwaitingNewResponse) {
      const scrollInterval = setInterval(scrollToBottom, 100);
      return () => clearInterval(scrollInterval);
    }
  }, [isAwaitingNewResponse]);

  const getGreeting = () => {
    const hour = new Date().getHours();
    let greeting = "";
    
    if (hour >= 5 && hour < 12) {
      greeting = "¡Buenos días";
    } else if (hour >= 12 && hour < 19) {
      greeting = "¡Buenas tardes";
    } else {
      greeting = "¡Buenas noches";
    }
    
    if (isGuest || !username) {
      return `${greeting}!`;
    }
    
    return `${greeting}, ${username}!`;
  };

  return (
    <>
      <div ref={messagesContainerRef} className={chatClass}>
        {message && message.length > 0 ? (
          <div className="flex flex-col w-full justify-end items-center pb-16 px-2 sm:px-8 lg:px-8 pt-4">
            {message.map((msg, i) => (
              <div
                key={i}
                className={`flex mb-4 w-full max-w-[90%] sm:max-w-[700px] ${
                  msg.isBot ? "justify-start" : "justify-end"
                }`}
              >
                {msg.isBot && (
                  <div className="w-12 h-12 flex items-center justify-center mr-1 sm:mr-2">
                    <img
                      src={iconBot}
                      alt="Bot"
                      className="w-6 h-6 sm:w-10 sm:h-10 mt-8"
                    />
                  </div>
                )}
                <div
                  className={`p-2 sm:p-3 shadow-md semibold rounded-lg max-w-[70%] sm:max-w-[60%] ${
                    msg.isBot
                      ? msg.isError
                        ? "bg-red-700 text-white"
                        : "bg-neutral-900 text-white"
                      : "bg-blue-100 text-black"
                  }`}
                >
                  <p className="whitespace-pre-wrap text-xs sm:text-sm">
                    {typeof msg.text === "string" &&
                    msg.isBot &&
                    msg.text.startsWith(" ")
                      ? msg.text.slice(1)
                      : msg.text}
                  </p>
                </div>

                {!msg.isBot && (
                  <div className="w-8 h-8 flex items-center justify-center ml-1 sm:ml-2">
                    <img
                      src={iconUser}
                      alt="User"
                      className="w-6 h-6 sm:w-8 sm:h-8 mt-7"
                    />
                  </div>
                )}
              </div>
            ))}
            {isAwaitingNewResponse && (
              <div className="flex flex-col justify-center items-center h-full space-y-4">
                <Spin />
                <p className="relative font-semibold text-gray-300 animate-shine text-lg mt-6 sm:text-2xl">
                  {"Buscando información..."}
                </p>
              </div>
            )}

            <div ref={endOfMessagesRef} className="h-8" />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full w-full">
            <div className="text-center px-4 relative bottom-1/3">
              <p className={`font-bold text-lg sm:text-3xl md:text-4xl mb-4 ${
                isDarkMode ? "text-white" : "text-black"
              }`}>
                {getGreeting()}
              </p>
              <p className={`font-bold text-lg sm:text-2xl md:text-3xl ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}>
                {"¿Cómo puedo ayudarte hoy?"}
              </p>
            </div>
          </div>
        )}
      </div>

      <CustomAlert
        open={alertConfig?.open || false}
        type={alertConfig?.type || "loading"}
        message={alertConfig?.message || ""}
        title={alertConfig?.title || ""}
        onClose={() => setAlertConfig && setAlertConfig(prev => ({ ...prev, open: false }))}
        onConfirm={alertConfig?.onConfirm}
        $isDark={isDarkMode}
        textConfig={{
          CUSTOM_ALERT_BUTTON_CONFIRM: "Confirmar",
          CUSTOM_ALERT_BUTTON_CANCEL: "Cancelar",
          CUSTOM_ALERT_BUTTON_CLOSE: "Cerrar"
        }}
      />
    </>
  );
}

export default Chat;