import React, { useContext, useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleButton from "./ui/ToggleButton.tsx";
import Logo from "../images/logo.svg";
import { Button } from "./ui/button.tsx";
import { ContextApp } from "../utils/Context.jsx";
import { ThemeContext } from "./ThemeContext.jsx";
import FileManagementModal from "./modal/FileManagementModal.tsx";
import WhatsAppMenuModal from "./modal/WhatsAppMenuModal.tsx";
import UserManagementModal from "./modal/UserManagementModal.tsx";
import ServiceMenuModal from "./modal/ServiceMenuModal.tsx";
import ProfileManagementModal from "./modal/ProfileManagementModal.tsx";
import UserDropdown from "./ui/UserDropdown.tsx";
import AssociationModal from "./modal/AssociationModal.tsx";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setIsGuest,
    setIsLoggedIn,
    isLoggedIn,
    isGuest,
    isSidebarCollapsed,
    userRole,
    textConfig,
    username,
  } = useContext(ContextApp);
  const { isDarkMode } = useContext(ThemeContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [showFileManagementModal, setShowFileManagementModal] = useState(false);
  const [showWhatsAppMenuModal, setShowWhatsAppMenuModal] = useState(false);
  const [showUserManagementModal, setShowUserManagementModal] = useState(false);
  const [showServiceMenuModal, setShowServiceMenuModal] = useState(false);
  const [showProfileManagementModal, setShowProfileManagementModal] =
    useState(false);
  const [showAssociationModal, setShowAssociationModal] = useState(false);

  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("expirationDate");
    Cookies.remove("refreshToken");
    Cookies.remove("username");
    localStorage.removeItem("username");
    setIsLoggedIn(false);
    setIsGuest(false);
    navigate("/login");
    setIsMenuOpen(false);
  };

  const handleLogoClick = () => {
    window.location.reload();
  };

  return (
    <header
      className={`relative flex justify-between items-center md:px-8 pr-1 pl-1 pb-0 mb-4 py-6 mr-1 md:mr-12 text-primary ${
        isDarkMode ? "bg-transparent" : "bg-transparent"
      }`}
    >
      <div
        className="flex items-center cursor-pointer transform transition-transform duration-300 hover:scale-110"
        onClick={handleLogoClick}
      >
        <img
          src={Logo}
          alt="Logo"
          className="h-16 w-16 transition-all duration-300 hover:brightness-110"
        />
      </div>
      <div
        className={`flex items-center gap-4 transition-all duration-300 ${
          isSidebarCollapsed ? "mr-16" : "sm:mr-[320px] mr-0"
        }`}
      >
        <ToggleButton />
        {location.pathname !== "/login" && (
          <>
            {isLoggedIn ? (
              <>
                <UserDropdown
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                  userRole={userRole}
                  username={username}
                  setShowUserManagementModal={setShowUserManagementModal}
                  setShowFileManagementModal={setShowFileManagementModal}
                  setShowWhatsAppMenuModal={setShowWhatsAppMenuModal}
                  setShowProfileManagementModal={setShowProfileManagementModal}
                  setShowAssociationModal={setShowAssociationModal}
                  handleLogout={handleLogout}
                  textConfig={textConfig}
                />
                <div className="block">
                  <Button
                    variant="primary"
                    onClick={handleLogout}
                    className="bg-red-500 text-white hover:bg-red-600 px-4 py-2 rounded-md text-sm leading-none"
                  >
                    Cerrar Sesión
                  </Button>
                </div>
              </>
            ) : (
              <>
              
              </>
            )}
          </>
        )}
      </div>
      {showFileManagementModal && (
        <FileManagementModal
          onClose={() => setShowFileManagementModal(false)}
          isOpen={showFileManagementModal}
        />
      )}

      {showWhatsAppMenuModal && (
        <WhatsAppMenuModal
          onClose={() => setShowWhatsAppMenuModal(false)}
          isOpen={showWhatsAppMenuModal}
        />
      )}

      {showUserManagementModal && (
        <UserManagementModal
          onClose={() => setShowUserManagementModal(false)}
          isOpen={showUserManagementModal}
        />
      )}

      {showServiceMenuModal && (
        <ServiceMenuModal
          onClose={() => setShowServiceMenuModal(false)}
          isOpen={showServiceMenuModal}
        />
      )}
      {showProfileManagementModal && (
        <ProfileManagementModal
          onClose={() => setShowProfileManagementModal(false)}
          isOpen={showProfileManagementModal}
        />
      )}
      {showAssociationModal && (
        <AssociationModal
          onClose={() => setShowAssociationModal(false)}
          isOpen={showAssociationModal}
        />
      )}
    </header>
  );
};

export default Header;