import Cookies from "js-cookie";
import fetchWithAuth from "../utils/fetchWithAuth";
import { getEndpoints } from "../utils/ApiContext";


export const validateProfileData = (profileData) => {
  if (!profileData.profile_id || typeof profileData.profile_id !== 'string') {
    throw new Error("El ID del perfil es requerido y debe ser un string");
  }

  if (!profileData.backend_config || typeof profileData.backend_config !== 'object') {
    throw new Error("La configuración del backend es requerida y debe ser un objeto");
  }

  if (!Array.isArray(profileData.backend_config.collections)) {
    throw new Error("Las colecciones deben ser un array");
  }
  
  if (profileData.backend_config.collections.length === 0) {
    throw new Error("Se requiere al menos una colección");
  }
  
  for (const collection of profileData.backend_config.collections) {
    if (typeof collection !== 'string' || !collection.trim()) {
      throw new Error("Las colecciones deben ser strings no vacíos");
    }
  }

  if (!Array.isArray(profileData.backend_config.prompt) || 
      profileData.backend_config.prompt.length === 0) {
    throw new Error("Se requiere al menos un prompt");
  }

  profileData.backend_config.prompt.forEach((prompt, index) => {
    if (!Array.isArray(prompt) || prompt.length !== 2 || 
        typeof prompt[0] !== 'string' || typeof prompt[1] !== 'string') {
      throw new Error(`El prompt en la posición ${index} tiene un formato inválido`);
    }
    if (!prompt[0].trim() || !prompt[1].trim()) {
      throw new Error(`El prompt en la posición ${index} contiene valores vacíos`);
    }
  });

  return true;
};

export const getProfiles = async () => {
  try {
    const endpoints = await getEndpoints();
    const API_URL = endpoints.API_PROFILES;
    
    const response = await fetchWithAuth(API_URL);

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error en respuesta de API de perfiles:", errorText);
      let errorMessage;
      try {
        const errorData = JSON.parse(errorText);
        errorMessage = errorData.message || `Error ${response.status}: ${response.statusText}`;
      } catch {
        errorMessage = `Error ${response.status}: ${response.statusText}`;
      }
      throw new Error(errorMessage);
    }

    const data = await response.json();
    
    if (data && Array.isArray(data.profiles)) {
      return data.profiles;
    } else if (Array.isArray(data)) {
      return data;
    } else {
      throw new Error("La respuesta de la API no contiene un array de perfiles válido");
    }
  } catch (error) {
    console.error("Error al obtener perfiles:", error);
    throw error;
  }
};

export const createProfile = async (profileData) => {
  try {
    validateProfileData(profileData);

    const endpoints = await getEndpoints();
    const API_URL = endpoints.API_PROFILES;

    const sanitizedData = {
      profile_id: profileData.profile_id.trim().toLowerCase(),
      backend_config: {
        collections: profileData.backend_config.collections,
        prompt: profileData.backend_config.prompt.map(([type, content]) => [
          type.split(' ')[0].trim().toLowerCase(),
          content.trim()
        ])
      }
    };

    const response = await fetchWithAuth(API_URL, {
      method: "POST",
      headers: {
        "Accept": "application/json",
      },
      body: JSON.stringify(sanitizedData)
    });

    const responseText = await response.text();

    if (!response.ok) {
      let errorMessage;
      try {
        const errorData = JSON.parse(responseText);
        errorMessage = errorData.message || `Error ${response.status}: ${response.statusText}`;
      } catch {
        errorMessage = `Error ${response.status}: ${response.statusText}`;
      }
      throw new Error(errorMessage);
    }
    
    if (!responseText || responseText.trim() === '') {
      return { success: true, profile_id: sanitizedData.profile_id };
    }

    try {
      const parsedResponse = JSON.parse(responseText);
      return parsedResponse;
    } catch (e) {
      return { success: true, profile_id: sanitizedData.profile_id };
    }
  } catch (error) {
    console.error("Error al crear perfil:", error);
    throw error;
  }
};

export const deleteProfile = async (profileId) => {
  try {
    const endpoints = await getEndpoints();
    const baseUrl = endpoints.API_PROFILES;
    const API_URL = `${baseUrl}?profile_id=${encodeURIComponent(profileId)}`;

    const response = await fetchWithAuth(API_URL, {
      method: "DELETE"
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error al eliminar perfil:", errorText);
      let errorMessage;
      try {
        const errorData = JSON.parse(errorText);
        errorMessage = errorData.message || `Error al eliminar el perfil`;
      } catch {
        errorMessage = `Error ${response.status}: ${response.statusText}`;
      }
      throw new Error(errorMessage);
    }

    return true;
  } catch (error) {
    console.error("Error al eliminar perfil:", error);
    throw error;
  }
};

export const updateProfile = async (profileId, profileData) => {
  try {
    validateProfileData(profileData);
    
    const endpoints = await getEndpoints();
    const baseUrl = endpoints.API_PROFILES;
    const API_URL = `${baseUrl}?profile_id=${encodeURIComponent(profileId)}`;

    const sanitizedData = {
      ...profileData,
      profile_id: profileData.profile_id.trim().toLowerCase(),
      backend_config: {
        ...profileData.backend_config,
        prompt: profileData.backend_config.prompt.map(([type, content]) => [
          type.trim().toLowerCase(),
          content.trim()
        ])
      }
    };

    const response = await fetchWithAuth(API_URL, {
      method: "PUT",
      body: JSON.stringify(sanitizedData)
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error al actualizar perfil:", errorText);
      let errorMessage;
      try {
        const errorData = JSON.parse(errorText);
        errorMessage = errorData.message || `Error al actualizar el perfil`;
      } catch {
        errorMessage = `Error ${response.status}: ${response.statusText}`;
      }
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al actualizar perfil:", error);
    throw error;
  }
};

export const getProfileById = async (profileId) => {
  try {
    const endpoints = await getEndpoints();
    const baseUrl = endpoints.API_PROFILES;
    const API_URL = `${baseUrl}?profile_id=${encodeURIComponent(profileId)}`;    
    
    const response = await fetchWithAuth(API_URL);

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error al obtener perfil específico:", errorText);
      let errorMessage;
      try {
        const errorData = JSON.parse(errorText);
        errorMessage = errorData.message || `Error al obtener el perfil`;
      } catch {
        errorMessage = `Error ${response.status}: ${response.statusText}`;
      }
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al obtener perfil específico:", error);
    throw error;
  }
};