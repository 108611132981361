import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

interface StyledProps {
  $isDark?: boolean;
  $variant?: "primary" | "danger";
  disabled?: boolean;
}

interface CustomButtonProps {
  label: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  $isDark?: boolean;
  $variant?: "primary" | "danger";
  type?: "button" | "submit" | "reset";
  fullWidth?: boolean;
  className?: string;
  icon?: React.ReactNode;
}

const StyledButton = styled(Button)<StyledProps>`
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  background-color: ${(props) => {
    if (props.disabled) {
      return props.$isDark ? "#27272A" : "#E5E7EB";
    }
    
    if (props.$variant === "danger") {
      return "#EF4444";
    }
    
    return props.$isDark ? "#22C55E" : "#16A34A";
  }};

  color: ${(props) => {
    if (props.disabled) {
      return props.$isDark ? "#71717A" : "#9CA3AF";
    }
    
    return "#FFFFFF";
  }};

  &:hover {
    background-color: ${(props) => {
      if (props.disabled) {
        return props.$isDark ? "#27272A" : "#E5E7EB";
      }
      
      if (props.$variant === "danger") {
        return "#DC2626";
      }
      
      return props.$isDark ? "#16A34A" : "#15803D";
    }};
  }
`;

export const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  onClick,
  isLoading = false,
  disabled = false,
  $isDark = false,
  $variant = "primary",
  type = "button",
  fullWidth = false,
  className = "",
  icon,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled || isLoading}
      $isDark={$isDark}
      $variant={$variant}
      type={type}
      fullWidth={fullWidth}
      className={className}
    >
      {isLoading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        <>
          {icon && <Box component="span">{icon}</Box>}
          {label}
        </>
      )}
    </StyledButton>
  );
};

export default CustomButton;