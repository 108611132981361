import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Checkbox, FormControlLabel, Typography, Divider, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Eye, EyeOff, Users, Info, Shield } from "lucide-react";
import { createUser, getGroups, updateUserApi } from "../../services/associationApi.jsx";
import { CreateUserTabProps, PasswordValidations, StyledProps } from "./types";

const ContentBox = styled(Box)<StyledProps>`
  background-color: ${(props) => (props.$isDark ? "#2D2D2D" : "#FFFFFF")};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: ${(props) =>
    props.$isDark
      ? "none"
      : "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"};
`;

const SelectContainer = styled(Box)<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: ${(props) =>
    props.$isDark ? "#18181B" : "#F3F4F6"};
  border-radius: 12px;
  margin-bottom: 16px;
  border: ${(props) => (props.$isDark ? "none" : "1px solid #E5E7EB")};
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => (props.$isDark ? "#27272A" : "#E5E7EB")};
    border-radius: 0 12px 12px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => (props.$isDark ? "#3F3F46" : "#D1D5DB")};
    border-radius: 4px;
  }
`;

const GroupsContainer = styled(Box)<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  background-color: ${(props) =>
    props.$isDark ? "#27272A" : "#F9FAFB"};
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  border: ${(props) => (props.$isDark ? "1px solid #3F3F46" : "1px solid #E5E7EB")};
`;

const StyledTextField = styled(TextField)<StyledProps>(({ $isDark }) => ({
  "& .MuiOutlinedInput-root": {
    color: $isDark ? "#FFFFFF" : "#1F2937",
    backgroundColor: $isDark ? "#27272A" : "#F9FAFB",
    "& fieldset": {
      borderColor: $isDark ? "#3F3F46" : "#D1D5DB",
    },
    "&:hover fieldset": {
      borderColor: $isDark ? "#22C55E" : "#16A34A",
    },
    "&.Mui-focused fieldset": {
      borderColor: $isDark ? "#22C55E" : "#16A34A",
    },
    "&.Mui-error fieldset": {
      borderColor: "#DC2626",
    },
  },
  "& .MuiInputLabel-root": {
    color: $isDark ? "#FFFFFF" : "#4B5563",
  },
  "& .MuiFormHelperText-root": {
    color: "#DC2626",
  },
}));

const ValidationText = styled(Typography)<{ isValid: boolean }>(
  ({ isValid }) => ({
    color: isValid ? "#22C55E" : "#DC2626",
    fontSize: "12px",
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  })
);

const SectionTitle = styled(Typography)<StyledProps>`
  color: ${(props) => (props.$isDark ? "#FFFFFF" : "#1F2937")};
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  
  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 16px;
    background-color: ${(props) => (props.$isDark ? "#22C55E" : "#16A34A")};
    border-radius: 2px;
  }
`;

const StyledCheckbox = styled(Checkbox)<StyledProps>`
  &.MuiCheckbox-root {
    color: ${(props) => (props.$isDark ? "#71717A" : "#6B7280")};
    padding: 4px;
  }
  &.Mui-checked {
    color: ${(props) => (props.$isDark ? "#22C55E" : "#16A34A")};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)<StyledProps>`
  margin: 0;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  
  &:hover {
    background-color: ${(props) => (props.$isDark ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.03)")};
  }
  
  & .MuiFormControlLabel-label {
    color: ${(props) => (props.$isDark ? "#E5E7EB" : "#1F2937")};
    font-size: 13px;
    width: 100%;
  }
`;

const AlertBox = styled(Box)<StyledProps>`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${props => 
    props.$isDark ? 'rgba(34, 197, 94, 0.1)' : 'rgba(34, 197, 94, 0.05)'};
  border: 1px solid ${props => 
    props.$isDark ? '#22C55E' : '#86EFAC'};
`;

const CreateUserTab: React.FC<CreateUserTabProps> = ({
  isDarkMode,
  isLoading,
  setIsLoading,
  textConfig,
  setAlertConfig,
  fetchUsers,
  users
}) => {
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [groups, setGroups] = useState<any[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Record<string, boolean>>({});
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState<PasswordValidations>({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        setLoadingGroups(true);
        const groupsData = await getGroups();
        setGroups(groupsData || []);
        
        const initialGroups: Record<string, boolean> = {};
        if (Array.isArray(groupsData)) {
          groupsData.forEach(group => {
            if (group && typeof group.groupname === 'string') {
              initialGroups[group.groupname] = group.groupname === 'user';
            }
          });
        }
        setSelectedGroups(initialGroups);
      } catch (error) {
        console.error("Error al obtener grupos:", error);
        setAlertConfig({
          open: true,
          type: "error",
          message: "Error al cargar los grupos",
          title: "Error",
          onConfirm: undefined,
        });
      } finally {
        setLoadingGroups(false);
      }
    };

    fetchGroups();
  }, [setAlertConfig]);

  const validateEmail = (email: string): boolean => {
    if (!email) return true;
    return email.includes('@') && email.split('@')[1].length > 0;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewEmail(value);
    if (emailError) setEmailError("");
  };

  const handleEmailBlur = () => {
    if (newEmail && !validateEmail(newEmail)) {
      setEmailError("El formato del correo electrónico es incorrecto. Debe incluir un dominio después del @");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (password: string) => {
    setPasswordValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>\-_\\\/\[\]+=^$';~`]/.test(password),    });
  };

  const isPasswordValid = () => {
    return Object.values(passwordValidations).every((validation) => validation);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewPassword(value);
    validatePassword(value);
  };

  const handleGroupChange = (groupname: string) => {
    setSelectedGroups(prev => ({
      ...prev,
      [groupname]: !prev[groupname]
    }));
  };

  const hasSelectedGroup = () => {
    return Object.values(selectedGroups).some(selected => selected);
  };

  const handleCreateUser = async () => {
    if (!newUsername || !newPassword || !newEmail) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "Todos los campos son requeridos",
        title: "",
        onConfirm: undefined,
      });
      return;
    }

    if (!isPasswordValid()) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "La contraseña no cumple con los requisitos de seguridad",
        title: "",
        onConfirm: undefined,
      });
      return;
    }

    if (!validateEmail(newEmail)) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "El formato del correo electrónico es incorrecto. Debe incluir un dominio después del @",
        title: "Error de validación",
        onConfirm: undefined,
      });
      setEmailError("El formato del correo electrónico es incorrecto");
      return;
    }

    if (!hasSelectedGroup()) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "El usuario debe estar dentro de al menos un grupo",
        title: "Error de validación",
        onConfirm: undefined,
      });
      return;
    }

    const userExists = users.some(
      (user) => user.username.toLowerCase() === newUsername.toLowerCase()
    );

    if (userExists) {
      setAlertConfig({
        open: true,
        type: "error",
        message: `El usuario "${newUsername}" ya existe en el sistema`,
        title: "Usuario duplicado",
        onConfirm: undefined,
      });
      return;
    }

    try {
      setIsLoading(true);
      
      const userData = {
        username: newUsername,
        password: newPassword,
        email: newEmail
      };
      await createUser(userData);      
      const selectedGroupsObj = Object.entries(selectedGroups)
        .filter(([_, isSelected]) => isSelected)
        .reduce((acc, [groupname, _]) => {
          acc[groupname] = true;
          return acc;
        }, {} as Record<string, boolean>);
      
      if (Object.keys(selectedGroupsObj).length > 0) {
        try {
          
          await updateUserApi({
            username: newUsername,
            email: newEmail,
            groups: selectedGroupsObj
          });
          
        } catch (groupError) {
          console.error(`Error al asignar grupos al usuario ${newUsername}:`, groupError);
        }
      }
      
      setNewUsername("");
      setNewPassword("");
      setNewEmail("");
      setEmailError("");
      setPasswordValidations({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
      });
      
      const resetGroups: Record<string, boolean> = {};
      Object.keys(selectedGroups).forEach(group => {
        resetGroups[group] = group === 'user';
      });
      setSelectedGroups(resetGroups);
      
      setAlertConfig({
        open: true,
        type: "success",
        message: "Usuario creado exitosamente",
        title: "",
        onConfirm: undefined,
      });
      await fetchUsers();
    } catch (error) {
      console.error("Error detallado al crear usuario:", error);
      
      let errorMessage = 'Error desconocido';
      let errorTitle = 'Error';
      
      if (error.response) {
        console.error("Respuesta del servidor:", error.response);
        errorMessage = `Error ${error.response.status}: ${error.response.data?.message || error.response.statusText || 'Error del servidor'}`;
        
        if (error.response.status === 500) {
          errorTitle = "Error interno del servidor";
          errorMessage = "Ha ocurrido un error en el servidor. Por favor, contacte al administrador o intente más tarde.";
        }
      } else if (error.request) {
        console.error("Solicitud sin respuesta:", error.request);
        errorMessage = "No se pudo conectar con el servidor. Verifique su conexión a internet.";
        errorTitle = "Error de conexión";
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      const isDuplicateError =
        errorMessage.toLowerCase().includes("duplicate") ||
        errorMessage.toLowerCase().includes("already exists") ||
        errorMessage.toLowerCase().includes("ya existe") ||
        errorMessage.toLowerCase().includes("usuario existente");

      if (isDuplicateError) {
        setAlertConfig({
          open: true,
          type: "error",
          message: `El usuario "${newUsername}" ya existe en el sistema`,
          title: "Usuario duplicado",
          onConfirm: undefined,
        });
      } else {
        setAlertConfig({
          open: true,
          type: "error",
          message: errorMessage,
          title: errorTitle,
          onConfirm: undefined,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const userGroupExists = groups.some(group => group.groupname === 'user');

  return (
    <ContentBox $isDark={isDarkMode}>
      <SelectContainer $isDark={isDarkMode}>
        <StyledTextField
          fullWidth
          label="Username"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          disabled={isLoading}
          $isDark={isDarkMode}
        />
        <Box>
          <StyledTextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            value={newPassword}
            onChange={handlePasswordChange}
            disabled={isLoading}
            error={newPassword.length > 0 && !isPasswordValid()}
            $isDark={isDarkMode}
            InputProps={{
              endAdornment: (
                <Box
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: isDarkMode
                        ? "rgba(255, 255, 255, 0.1)"
                        : "rgba(0, 0, 0, 0.05)",
                    },
                  }}
                >
                  {showPassword ? (
                    <EyeOff
                      size={18}
                      color={isDarkMode ? "#71717A" : "#6B7280"}
                    />
                  ) : (
                    <Eye
                      size={18}
                      color={isDarkMode ? "#71717A" : "#6B7280"}
                    />
                  )}
                </Box>
              ),
            }}
          />
          {newPassword.length > 0 && (
            <Box sx={{ mt: 1, ml: 1 }}>
              <ValidationText isValid={passwordValidations.length}>
                • Mínimo 8 caracteres
              </ValidationText>
              <ValidationText isValid={passwordValidations.uppercase}>
                • Al menos una mayúscula
              </ValidationText>
              <ValidationText isValid={passwordValidations.lowercase}>
                • Al menos una minúscula
              </ValidationText>
              <ValidationText isValid={passwordValidations.number}>
                • Al menos un número
              </ValidationText>
              <ValidationText isValid={passwordValidations.special}>
                • Al menos un carácter especial
              </ValidationText>
            </Box>
          )}
        </Box>
        <StyledTextField
          fullWidth
          label="Email"
          value={newEmail}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          disabled={isLoading}
          error={!!emailError}
          helperText={emailError}
          $isDark={isDarkMode}
        />

        <Divider sx={{ my: 2, borderColor: isDarkMode ? "#3F3F46" : "#E5E7EB" }} />

        <SectionTitle $isDark={isDarkMode}>
          Asignar grupos
        </SectionTitle>
        
                <div
          className={`mb-4 p-2 rounded border flex items-center gap-2 ${
            isDarkMode
              ? "bg-green-900/10 border-green-500 text-green-300"
              : "bg-green-50 border-green-500 text-green-800"
          }`}
        >
          <Info size={44} className={isDarkMode ? "text-green-400" : "text-green-600"} />
          <p className="text-sm">
            Todo usuario debe pertenecer al menos a un grupo. Por defecto, el grupo "user" viene seleccionado. 
            Puede añadir o quitar grupos según los permisos que necesite el usuario.
          </p>
        </div>

        {loadingGroups ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <CircularProgress size={20} sx={{ color: isDarkMode ? "#22C55E" : "#16A34A" }} />
          </Box>
        ) : groups.length > 0 ? (
          <GroupsContainer $isDark={isDarkMode}>
            <Box 
              sx={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                gap: '4px'
              }}
            >
              {groups.map((group) => (
                <StyledFormControlLabel
                  key={group.groupname}
                  control={
                    <StyledCheckbox
                      checked={selectedGroups[group.groupname] || false}
                      onChange={() => handleGroupChange(group.groupname)}
                      $isDark={isDarkMode}
                      disabled={isLoading}
                      size="small"
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <Box sx={{ 
                        fontWeight: group.groupname === 'user' ? 700 : 500,
                        color: group.groupname === 'user' && isDarkMode 
                          ? '#22C55E' 
                          : group.groupname === 'user' 
                            ? '#16A34A' 
                            : isDarkMode 
                              ? '#E5E7EB' 
                              : '#374151',
                        mr: 0.5
                      }}>
                        {group.groupname}
                        {group.groupname === 'user' && ' (predeterminado)'}
                      </Box>
                      {group.description && (
                        <Typography 
                          component="span" 
                          sx={{ 
                            fontSize: '11px', 
                            color: isDarkMode ? '#9CA3AF' : '#6B7280',
                            fontStyle: 'italic'
                          }}
                        >
                          - {group.description}
                        </Typography>
                      )}
                    </Box>
                  }
                  $isDark={isDarkMode}
                />
              ))}
            </Box>
          </GroupsContainer>
        ) : (
          <Box 
            sx={{ 
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
              borderRadius: '8px',
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 1
            }}
          >
            <Typography sx={{ 
              color: isDarkMode ? '#A1A1AA' : '#6B7280', 
              fontSize: '13px', 
              fontStyle: 'italic',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <Users size={14} />
              No hay grupos disponibles para asignar
            </Typography>
          </Box>
        )}

        <Button
          variant="contained"
          onClick={handleCreateUser}
          disabled={
            isLoading ||
            !newUsername ||
            !newPassword ||
            !newEmail ||
            !isPasswordValid() ||
            !!emailError ||
            !hasSelectedGroup()
          }
          sx={{
            backgroundColor: "#22C55E",
            color: "#FFFFFF",
            "&:hover": { backgroundColor: "#16A34A" },
            textTransform: "none",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
          ) : (
            "Crear Usuario"
          )}
        </Button>
      </SelectContainer>
    </ContentBox>
  );
};

export default CreateUserTab;