import React, { useState, useEffect } from "react";
import { BaseModal } from "../ui/BaseModal.tsx";
import { ActionButton } from "../ui/ActionButton.tsx";
import { useTheme } from "../ThemeContext.jsx";
import { CustomTextField } from "../ui/CustomTextField.tsx";
import { Users, Info } from "lucide-react";
import { Box, Typography } from "@mui/material";

interface EditGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: {
    groupname: string;
    description: string;
    users?: { username: string }[];
  } | null;
  onSave: (data: { groupname: string; description: string }) => Promise<void>;
}

const EditGroupModal = ({ isOpen, onClose, group, onSave }: EditGroupModalProps) => {
  const { isDarkMode } = useTheme();
  const [description, setDescription] = useState("");
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    if (group) {
      setDescription(group.description || "");
    }
  }, [group]);

  const handleSave = async () => {
    if (!group) return;
    
    setSaving(true);
    try {
      await onSave({
        groupname: group.groupname,
        description: description,
      });
      onClose();
    } catch (error) {
      console.error("Error al guardar grupo:", error);
    } finally {
      setSaving(false);
    }
  };

  if (!group) return null;

  return (
    <BaseModal
      title={`Editar Grupo: ${group.groupname}`}
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className="w-full max-w-2xl">
        <div className="mt-4 space-y-5">
          <Box 
            p={2} 
            borderRadius={1} 
            bgcolor={isDarkMode ? "rgba(34, 197, 94, 0.1)" : "#f0fdf4"}
            border="1px solid #22C55E"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <Box sx={{ color: "#22C55E", display: "flex", alignItems: "center" }}>
              <Info size={20} color="#22C55E" />
            </Box>
            <Typography 
              sx={{ 
                color: "#22C55E", 
                fontSize: "0.875rem",
                fontWeight: 500
              }}
            >
              En esta sección solo se puede modificar la descripción del grupo
            </Typography>
          </Box>
          
          <div className="border border-gray-200 dark:border-zinc-700 rounded-lg p-4">
            <h3 className="text-lg font-medium mb-4">Información del Grupo</h3>
            
            <div className="mb-5">
              <div className="text-sm font-medium mb-1 text-gray-500 dark:text-gray-400">
                Nombre del Grupo
              </div>
              <div className="px-3 py-2 bg-gray-100 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 text-gray-900 dark:text-gray-100">
                {group.groupname}
              </div>
            </div>
            
            <div className="mb-2">
              <CustomTextField
                label="Descripción"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                isFullWidth
                multiline
                rows={4}
                placeholder="Ingrese una descripción para este grupo"
              />
            </div>
          </div>
          
          <div className="border border-gray-200 dark:border-zinc-700 rounded-lg p-4">
            <h3 className="text-lg font-medium mb-2">Usuarios del Grupo</h3>
            
            <div className="max-h-[120px] overflow-y-auto border border-gray-200 dark:border-zinc-700 rounded p-2">
              {group.users && group.users.length > 0 ? (
                <div className="grid grid-cols-2 gap-1">
                  {group.users.map((user) => (
                    <div
                      key={user.username}
                      className="flex items-center space-x-1 p-1 bg-gray-50 dark:bg-zinc-800 rounded text-xs"
                    >
                      <Users size={12} className="text-gray-500" />
                      <span className={isDarkMode ? "text-white" : "text-gray-900"}>
                        {user.username}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="p-2 text-center text-gray-500 text-sm">
                  No hay usuarios asignados a este grupo
                </div>
              )}
            </div>
          </div>
          
          <div className="flex justify-end space-x-3 mt-4">
            <ActionButton
              label="Cancelar"
              onClick={onClose}
              variant="clean"
              Icon={null}
            />
            <ActionButton
              label={isSaving ? "Guardando..." : "Guardar Cambios"}
              onClick={handleSave}
              disabled={isSaving || description.trim() === (group.description || "").trim()}
              Icon={null}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditGroupModal;