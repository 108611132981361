import React, { useState, useEffect, useContext } from "react";
import { BaseModal } from "../ui/BaseModal.tsx";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Users, UserPlus } from "lucide-react";
import { CustomAlert } from "../ui/CustomAlert.tsx";
import { getUsers, deleteUserApi } from "../../services/associationApi.jsx";
import { ThemeContext } from "../ThemeContext.jsx";
import AdminUsersTab from "../ui/AdminUsersTab.tsx";
import CreateUserTab from "../ui/CreateUserTab.tsx";
import { User, AlertConfig } from "../ui/types.ts";

interface UserManagementModalProps {
  onClose: () => void;
  isOpen: boolean;
}

interface StyledProps {
  $isDark?: boolean;
  $variant?: "primary" | "danger";
  disabled?: boolean;
}

const ModalContainer = styled(Box)<StyledProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${(props) => (props.$isDark ? "transparent" : "#FFFFFF")};
`;

const TabContainer = styled(Box)<StyledProps>`
  width: 100%;
  background-color: ${(props) => (props.$isDark ? "#18181B" : "#F3F4F6")};
  padding: 4px;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
  position: relative;
`;

const TabSlider = styled(Box)<StyledProps & { $position: string }>`
  position: absolute;
  width: calc(50% - 6px);
  height: calc(100% - 8px);
  background-color: ${(props) => (props.$isDark ? "#22C55E" : "#16A34A")};
  border-radius: 6px;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
    props.$position === "left" ? "translateX(0)" : "translateX(100%)"};
  left: 4px;
  top: 4px;
`;

const Tab = styled(Box)<StyledProps & { selected?: boolean }>`
  flex: 1;
  padding: 8px;
  text-align: center;
  color: ${(props) =>
    props.selected
      ? props.$isDark
        ? "#18181B"
        : "#FFFFFF"
      : props.$isDark
      ? "#FFFFFF"
      : "#1F2937"};
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  transition: color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: ${(props) => (props.selected ? 600 : 400)};

  &:hover {
    background-color: ${(props) =>
      !props.selected
        ? props.$isDark
          ? "rgba(255, 255, 255, 0.1)"
          : "rgba(0, 0, 0, 0.1)"
        : "transparent"};
  }
`;

export default function UserManagementModal({
  onClose,
  isOpen,
}: UserManagementModalProps) {
  const { isDarkMode } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState<"admin" | "create">("admin");
  const [textConfig, setTextConfig] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userData, setUserData] = useState<User | null>(null);

  const [alertConfig, setAlertConfig] = useState<AlertConfig>({
    open: false,
    type: "success",
    message: "",
    title: "",
    onConfirm: undefined,
  });

  useEffect(() => {
    const fetchTextConfig = async () => {
      try {
        const response = await fetch("/text.json");
        const data = await response.json();
        setTextConfig(data);
      } catch (error) {
        console.error("Error loading text config:", error);
      }
    };
    fetchTextConfig();
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const usersData = await getUsers();
      const formattedUsers = Array.isArray(usersData)
        ? usersData.map((user) => ({
            ...user,
            groups: user.groups || [],
          }))
        : [];

      setUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al obtener usuarios",
        title: "Error",
        onConfirm: () => handleCloseAlert(),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (username: string) => {

    setAlertConfig({
      open: true,
      type: "confirm",
      message: `¿Estás seguro que deseas eliminar el usuario ${username}?`,
      title: "Confirmar eliminación",
      onConfirm: async () => {
        try {
          setIsLoading(true);
          await deleteUserApi(username);

          await fetchUsers();
          setUserData(null);

          setAlertConfig({
            open: true,
            type: "success",
            message: "Usuario eliminado exitosamente",
            title: "¡Éxito!",
            onConfirm: () => handleCloseAlert(),
          });
        } catch (error) {
          console.error("Error deleting user:", error);
          setAlertConfig({
            open: true,
            type: "error",
            message:
              "Error al eliminar usuario: " +
              (error.message || "Error desconocido"),
            title: "Error",
            onConfirm: () => handleCloseAlert(),
          });
        } finally {
          setIsLoading(false);
        }
      },
    });
  };

  const updateUserData = (user: User | null) => {
    setUserData(user);
  };
  const handleCloseAlert = () => {
    setAlertConfig((prev) => ({ ...prev, open: false }));
  };

  return (
    <BaseModal
      title={textConfig.USER_MANAGEMENT_TEXT_TITLE || "Gestión de Usuarios"}
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalContainer $isDark={isDarkMode}>
        <TabContainer $isDark={isDarkMode}>
          <TabSlider
            $position={activeTab === "admin" ? "left" : "right"}
            $isDark={isDarkMode}
          />
          <Tab
            selected={activeTab === "admin"}
            onClick={() => setActiveTab("admin")}
            $isDark={isDarkMode}
          >
            <Users size={18} />
            {textConfig.USER_MENU_TEXT_ADMIN || "Administrar Usuarios"}
          </Tab>
          <Tab
            selected={activeTab === "create"}
            onClick={() => setActiveTab("create")}
            $isDark={isDarkMode}
          >
            <UserPlus size={18} />
            {textConfig.USER_MENU_TEXT_USER || "Crear Usuario"}
          </Tab>
        </TabContainer>

        {activeTab === "admin" && (
          <AdminUsersTab
            users={users}
            userData={userData}
            updateUserData={updateUserData}
            handleDeleteUser={handleDeleteUser}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isDarkMode={isDarkMode}
            textConfig={textConfig}
            setAlertConfig={setAlertConfig}
            fetchUsers={fetchUsers}
          />
        )}

        {activeTab === "create" && (
          <CreateUserTab
            isDarkMode={isDarkMode}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            textConfig={textConfig}
            setAlertConfig={setAlertConfig}
            fetchUsers={fetchUsers}
            users={users}
          />
        )}

        <CustomAlert
          open={alertConfig.open}
          type={alertConfig.type}
          message={alertConfig.message}
          title={alertConfig.title}
          onClose={handleCloseAlert}
          onConfirm={alertConfig.onConfirm}
          $isDark={isDarkMode}
          textConfig={{
            CUSTOM_ALERT_BUTTON_CONFIRM: "Confirmar",
            CUSTOM_ALERT_BUTTON_CANCEL: "Cancelar",
            CUSTOM_ALERT_BUTTON_CLOSE: "Cerrar",
          }}
        />
      </ModalContainer>
    </BaseModal>
  );
}
