import React, { useContext, useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { LuChevronLeftSquare, LuChevronRightSquare } from "react-icons/lu";
import { ContextApp } from "../utils/Context.jsx";
import { ThemeContext } from "../components/ThemeContext";
import NewChatButton from "./ui/NewChatButton.tsx";
import { Spin } from "antd";
import AccessErrorAlert from "./ui/AccessErrorAlert.tsx"; 

const RightSidebar = () => {
  const {
    resetChat,
    topics = [],
    loadChatHistoryForTopic,
    deleteTopicFromList,
    isGuest,
    setIsChatOpened,
    collections,
    selectedCollection,
    setSelectedCollection,
    loadCollections,
    loadDocumentsForCollection,
    lastError,
    setLastError,
    isLoadingChat,
    hasAccessError, 
  } = useContext(ContextApp);

  const { isDarkMode } = useContext(ThemeContext);
  const { setIsSidebarCollapsed } = useContext(ContextApp);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collectionError, setCollectionError] = useState("");
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      const isMobileOrTablet = window.innerWidth < 1024;
      setIsCollapsed(isMobileOrTablet);
      setIsSidebarCollapsed(isMobileOrTablet);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [setIsSidebarCollapsed]);
  
  useEffect(() => {
    if (collections && collections.length > 0 && !selectedCollection) {
      setSelectedCollection(collections[0]);
      localStorage.setItem("selectedCollection", collections[0]);
      loadDocumentsForCollection(collections[0]);
    }
  }, [collections]);
  
  useEffect(() => {
    const fetchCollections = async () => {
      setIsLoadingCollections(true);
      try {
        const availableCollections = await loadCollections();

        if (
          availableCollections &&
          availableCollections.length > 0 &&
          !selectedCollection
        ) {
          setSelectedCollection(availableCollections[0]);
          localStorage.setItem("selectedCollection", availableCollections[0]);
          loadDocumentsForCollection(availableCollections[0]);
        }
      } catch (error) {
      } finally {
        setIsLoadingCollections(false);
      }
    };

    if (!hasAccessError) {
      fetchCollections();
    }
  }, [hasAccessError]); 

  useEffect(() => {
    if (collections.length > 0 && !selectedCollection) {
      setSelectedCollection(collections[0]);
      localStorage.setItem("selectedCollection", collections[0]);
      loadDocumentsForCollection(collections[0]);
    }
  }, [collections]);


  useEffect(() => {
    if (lastError && !hasAccessError) {
      setCollectionError(lastError);
      setTimeout(() => {
        setCollectionError("");
        setLastError(null);
      }, 3000);
    }
  }, [lastError, setLastError, hasAccessError]);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    setIsSidebarCollapsed(!isCollapsed);
  };

  const handleOpenChat = (topicId) => {
    setIsChatOpened(true);
    loadChatHistoryForTopic(topicId);
  };

  const handleDeleteTopic = (e, topicId) => {
    e.stopPropagation();
    deleteTopicFromList(topicId);
  };

  const handleCollectionSelect = async (collection) => {
    setCollectionError("");
    const hasAccess = await loadDocumentsForCollection(collection);

    if (hasAccess) {
      setSelectedCollection(collection);
      localStorage.setItem("selectedCollection", collection);
    } else if (!hasAccessError) { 
      setCollectionError(`No tienes acceso a la colección: ${collection}`);

      setTimeout(() => {
        setCollectionError("");
      }, 3000);
    }
  };

  const handleNewChat = () => {
    resetChat();
    setIsChatOpened(true);

    if (selectedCollection) {
      setTimeout(() => {
        loadDocumentsForCollection(selectedCollection);
      }, 500);
    }
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full border-l z-50 shadow-lg transition-all duration-300 flex flex-col ${
        isCollapsed ? "w-[60px]" : "w-[300px]"
      } ${
        isDarkMode
          ? "bg-neutral-800 text-white border-neutral-700"
          : "bg-gray-200 text-black"
      }`}
    >
      <div className="p-4 mt-5 flex justify-start">
        <button
          className={`${
            isDarkMode
              ? "text-gray-400 hover:text-white"
              : "text-gray-600 hover:text-black"
          } transition-all duration-300`}
          onClick={handleCollapse}
          aria-label={isCollapsed ? "Abrir panel" : "Cerrar panel"}
        >
          {isCollapsed ? (
            <LuChevronLeftSquare
              size={38}
              className="transform transition-transform duration-300"
            />
          ) : (
            <LuChevronRightSquare
              size={38}
              className="transform transition-transform duration-300"
            />
          )}
        </button>
      </div>

      {!isCollapsed && hasAccessError && (
        <div className="mx-4 mb-2">
          <AccessErrorAlert message={lastError} isDarkMode={isDarkMode} />
        </div>
      )}

      {!isCollapsed && collectionError && !hasAccessError && (
        <div className="mx-4 mb-2 p-2 bg-red-500 text-white rounded-md">
          {collectionError}
        </div>
      )}

      {!isCollapsed && (
        <div className="flex flex-col flex-grow p-4">
          {!isGuest && (
            <>
              <div className="flex flex-col flex-grow-0 mb-4">
                <h2 className="text-xl font-bold text-green-500">Historial</h2>
                <div
                  className="flex flex-col mt-2 space-y-2 overflow-y-auto no-scrollbar"
                  style={{ maxHeight: "400px" }}
                >
                  {isLoadingChat ? (
                    <div className="flex justify-center p-4">
                      <Spin />
                    </div>
                  ) : topics.length > 0 ? (
                    topics.map((topic) => (
                      <div
                        key={topic.id}
                        className={`flex justify-between items-center p-2 rounded-md cursor-pointer font-medium ${
                          isDarkMode
                            ? "bg-neutral-900 hover:bg-gray-700"
                            : "bg-gray-100 hover:bg-gray-200"
                        }`}
                        onClick={() => handleOpenChat(topic.id)}
                      >
                        <span className="truncate">{topic.name}</span>
                        <AiFillDelete
                          className="text-red-500 hover:text-red-700"
                          onClick={(e) => handleDeleteTopic(e, topic.id)}
                        />
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500">No hay temas disponibles</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col flex-grow">
                <h2 className="text-lg font-bold mb-2">Colecciones</h2>
                <div
                  className="flex flex-col space-y-2 overflow-y-auto no-scrollbar"
                  style={{ maxHeight: "200px" }}
                >
                  {isLoadingCollections ? (
                    <div className="flex justify-center p-4">
                      <Spin />
                    </div>
                  ) : collections.length > 0 ? (
                    collections.map((collection) => (
                      <div
                        key={collection}
                        className={`p-2 rounded-md cursor-pointer font-medium ${
                          selectedCollection === collection
                            ? isDarkMode
                              ? "bg-gray-700"
                              : "bg-gray-300"
                            : isDarkMode
                            ? "bg-neutral-900 hover:bg-gray-700"
                            : "bg-gray-100 hover:bg-gray-200"
                        }`}
                        onClick={() => handleCollectionSelect(collection)}
                      >
                        {collection}
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500">
                      No hay colecciones disponibles
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!isCollapsed && (
        <div className="p-4 mb-4">
          <NewChatButton onClick={handleNewChat} />
        </div>
      )}

      {isCollapsed && selectedCollection && (
        <div className="p-2 mt-4">
          <div
            className={`p-2 rounded-md text-center ${
              isDarkMode ? "bg-gray-700 text-white" : "bg-gray-300 text-black"
            }`}
            style={{
              writingMode: "vertical-rl",
              textOrientation: "mixed",
              height: "120px",
            }}
          >
            {selectedCollection}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightSidebar;