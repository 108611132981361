import React, { useRef, useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";

interface UserDropdownProps {
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  userRole: string;
  username?: string;
  setShowUserManagementModal: (show: boolean) => void;
  setShowFileManagementModal: (show: boolean) => void;
  setShowWhatsAppMenuModal: (show: boolean) => void;
  setShowProfileManagementModal: (show: boolean) => void;
  setShowAssociationModal: (show: boolean) => void;
  handleLogout: () => void;
  textConfig?: any;
}

const UserDropdown = ({
  showDropdown,
  setShowDropdown,
  userRole,
  username,
  setShowUserManagementModal,
  setShowFileManagementModal,
  setShowWhatsAppMenuModal,
  setShowProfileManagementModal,
  setShowAssociationModal,
  handleLogout,
  textConfig,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && event.target instanceof Node) {
        if (!dropdownRef.current.contains(event.target)) {
          setShowDropdown(false);
        }
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown, setShowDropdown]);

  return (
    <div className="relative left-0" ref={dropdownRef}>
      <button
        className={`flex items-center justify-center w-10 h-10 rounded-full font-bold text-lg cursor-pointer transition-all duration-300 ${
          isDarkMode
            ? "bg-gray-300 text-gray-800 hover:bg-gray-600"
            : "bg-slate-700 text-white hover:bg-gray-200"
        }`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {username && username.length > 0
          ? username.charAt(0).toUpperCase()
          : textConfig?.LEFTNAV_TEXT_USER?.[0] || "A"}
      </button>
      {showDropdown && (
        <div
          className={`absolute right-0 mt-2 w-56 rounded-lg shadow-lg z-50 border transition-colors duration-300 ${
            isDarkMode
              ? "bg-neutral-800 text-gray-200 border-gray-700"
              : "bg-white text-gray-700 border-gray-200"
          }`}
        >
          {username && (
            <div className={`px-4 py-3 font-medium border-b ${
              isDarkMode ? "border-gray-700 text-gray-100" : "border-gray-200 text-gray-800"
            }`}>
              {username}
            </div>
          )}
          
          <ul
            className={`flex flex-col divide-y ${
              isDarkMode ? "divide-gray-700" : "divide-gray-200"
            }`}
          >
            {userRole === "admin" && (
              <>
                <li
                  className={`px-4 py-2 cursor-pointer transition-colors duration-200 ${
                    isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    setShowUserManagementModal(true);
                    setShowDropdown(false);
                  }}
                >
                  {textConfig?.LEFTNAV_TEXT_ADMIN_USER ||
                    "Administración de Usuario"}
                </li>
                <li
                  className={`px-4 py-2 cursor-pointer transition-colors duration-200 ${
                    isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    setShowFileManagementModal(true);
                    setShowDropdown(false);
                  }}
                >
                  {textConfig?.LEFTNAV_TEXT_FILE_MANAGEMENT ||
                    "Administración de Archivos"}
                </li>
                <li
                  className={`px-4 py-2 cursor-pointer transition-colors duration-200 ${
                    isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    setShowWhatsAppMenuModal(true);
                    setShowDropdown(false);
                  }}
                >
                  {textConfig?.LEFTNAV_TEXT_WHATSAPP_SERVICE ||
                    "Servicio de WhatsApp"}
                </li>
                <li
                  className={`px-4 py-2 cursor-pointer transition-colors duration-200 ${
                    isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    setShowProfileManagementModal(true);
                    setShowDropdown(false);
                  }}
                >
                  {textConfig?.LEFTNAV_TEXT_PROFILES || "Perfiles"}
                </li>
                <li
                  className={`px-4 py-2 cursor-pointer transition-colors duration-200 ${
                    isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    setShowAssociationModal(true);
                    setShowDropdown(false);
                  }}
                >
                  {textConfig?.LEFTNAV_TEXT_ASSOCIATION ||
                    "Asociación y Grupos"}
                </li>
              </>
            )}
            <li
              className={`px-4 py-2 cursor-pointer transition-colors duration-200 ${
                isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
              }`}
              onClick={handleLogout}
            >
              {textConfig?.LEFTNAV_TEXT_LOGOUT || "Cerrar Sesión"}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;