import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { List, Grid } from "lucide-react";
import { useTheme } from "../ThemeContext";

interface CustomToggleTabsProps<T extends string> {
  value: T;
  onChange: (value: T) => void;
  options: {
    value: T;
    label: string;
    icon?: React.ReactNode;
  }[];
}

const ToggleContainer = styled(Box)({
  width: "100%",
  padding: "4px",
  borderRadius: "8px",
  display: "flex",
  gap: "4px",
  marginBottom: "16px",
  position: "relative",
  "&.light": {
    backgroundColor: "#f1f5f9",
  },
  "&.dark": {
    backgroundColor: "#18181B",
  },
});

interface SliderProps {
  $position: number;
  $totalOptions: number;
}

const Slider = styled(Box)<SliderProps>(({ $position, $totalOptions }) => ({
  position: "absolute",
  width: `calc(${100 / $totalOptions}% - 6px)`,
  height: "calc(100% - 8px)",
  backgroundColor: "#22C55E",
  borderRadius: "6px",
  transition: "transform 0.3s ease-in-out",
  transform: `translateX(${$position * 100}%)`,
  left: "4px",
  top: "4px",
}));

interface ToggleButtonProps {
  selected?: boolean;
  isDark?: boolean;
}

const ToggleButton = styled(Box)<ToggleButtonProps>(({ selected, isDark }) => ({
  flex: 1,
  padding: "8px",
  textAlign: "center",
  color: selected ? "#18181B" : isDark ? "#FFFFFF" : "#64748b",
  cursor: "pointer",
  borderRadius: "6px",
  position: "relative",
  zIndex: 1,
  transition: "color 0.2s ease-in-out",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  fontWeight: selected ? 600 : 400,
  "&:hover": {
    backgroundColor: !selected
      ? isDark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.05)"
      : "transparent",
  },
}));

export function CustomToggleTabs<T extends string>({ 
  value, 
  onChange, 
  options 
}: CustomToggleTabsProps<T>) {
  const { isDarkMode } = useTheme();
  const currentIndex = options.findIndex(option => option.value === value);

  return (
    <ToggleContainer className={isDarkMode ? "dark" : "light"}>
      <Slider 
        $position={currentIndex} 
        $totalOptions={options.length} 
      />
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          selected={value === option.value}
          isDark={isDarkMode}
          onClick={() => onChange(option.value)}
        >
          {option.icon}
          {option.label}
        </ToggleButton>
      ))}
    </ToggleContainer>
  );
}

export default CustomToggleTabs;