import React from 'react';
import { AlertTriangle } from 'lucide-react';

const AccessErrorAlert = ({ message, isDarkMode }) => {
  if (!message) return null;
  
  return (
    <div 
      className={`p-4 rounded border flex items-start gap-3 ${
        isDarkMode 
          ? "bg-red-900/20 border-red-500 text-red-300" 
          : "bg-red-50 border-red-500 text-red-800"
      }`} 
      style={{
        position: 'sticky',
        top: '0',
        zIndex: 100,
        boxShadow: isDarkMode 
          ? '0 4px 6px -1px rgba(0, 0, 0, 0.3)' 
          : '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
      }}
    >
      <AlertTriangle size={24} className={isDarkMode ? "text-red-400 mt-0.5 flex-shrink-0" : "text-red-600 mt-0.5 flex-shrink-0"} />
      <div className="flex-1">
        <h3 className={`font-medium text-base mb-1 ${isDarkMode ? "text-red-300" : "text-red-800"}`}>
          Acceso restringido
        </h3>
        <p className="text-sm">
          {message}
        </p>
      </div>
    </div>
  );
};

export default AccessErrorAlert;