import React, { useState, useEffect } from "react";
import { BaseModal } from "../ui/BaseModal.tsx";
import { ActionButton } from "../ui/ActionButton.tsx";
import { CustomToggleTabs } from "../ui/CustomToggleTabs.tsx";
import { useTheme } from "../ThemeContext.jsx";
import { CustomAlert } from "../ui/CustomAlert.tsx";
import { CustomTextField } from "../ui/CustomTextField.tsx";
import { Plus, Trash, Users, Boxes, Edit, Info  } from "lucide-react";
import Cookies from "js-cookie";
import { getUsers, createAssociation, deleteAssociation, getProfiles, getGroups, createGroup, deleteGroup, updateGroup, getFetchBaseUrl, } from "../../services/associationApi";
import EditGroupModal from "./EditGroupModal.tsx";

interface User {
  username: string;
  attributes: {
    email: string;
    email_verified?: boolean;
    sub: string;
  };
}

interface Association {
  user_id: string;
  profile_id: string;
}

interface Profile {
  profile_id: string;
  backend_config?: {
    collections?: string[];
    prompts?: any[];
  };
}

interface Group {
  groupname: string;
  description: string;
  users: { username: string }[];
}

interface AssociationModalProps {
  onClose: () => void;
  isOpen: boolean;
}

interface ItemDisplayProps {
  items: any[];
  viewMode: "list" | "grid";
  onSelect: (item: any) => void;
  selectedItems: string[];
  itemKey: string;
  onRemove: (item: string) => void;
  showDescription?: boolean;
  fetchGroups?: () => Promise<void>;
  onUpdate?: (data: any) => Promise<void>;
  showRemoveButton?: boolean;
}

interface GroupData {
  groupname: string;
  description: string;
  users?: { username: string }[];
}

interface EditingGroup {
  groupname: string;
  newName: string;
  newDescription: string;
  users: string[];
}

const ItemDisplay = ({
  items,
  viewMode,
  onSelect,
  selectedItems,
  itemKey,
  onRemove,
  showDescription = false,
  fetchGroups,
  onUpdate,
  showRemoveButton = true,
}: ItemDisplayProps) => {
  const { isDarkMode } = useTheme();
  const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    open: false,
    type: "success" as "success" | "error" | "loading" | "confirm",
    message: "",
    title: "",
    onConfirm: undefined as (() => Promise<void>) | undefined,
  });

  const handleStartEdit = (group: GroupData) => {
    setSelectedGroup(group);
    setIsEditModalOpen(true);
  };

  const handleSaveGroupEdit = async (data: { groupname: string; description: string }) => {
    if (!onUpdate || !fetchGroups) return;
    
    setAlertConfig({
      open: true,
      type: "loading",
      message: "Actualizando grupo...",
      title: "",
      onConfirm: undefined,
    });

    try {
      await onUpdate({
        groupname: data.groupname,
        description: data.description,
      });
      
      await fetchGroups();
      
      setAlertConfig({
        open: true,
        type: "success",
        message: "La descripción del grupo ha sido actualizada con éxito",
        title: "Grupo Actualizado",
        onConfirm: undefined,
      });
    } catch (error) {
      console.error("Error updating group:", error);
      
      setAlertConfig({
        open: true,
        type: "error",
        message: "Ha ocurrido un error al actualizar el grupo. Por favor, inténtelo de nuevo.",
        title: "Error",
        onConfirm: undefined,
      });
    }
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedGroup(null);
  };

  const getItemClassName = (isSelected: boolean) => `
    flex justify-between items-center p-2 cursor-pointer rounded mb-0.5
    ${
      isSelected
        ? "bg-green-500 text-white"
        : isDarkMode
        ? "hover:bg-zinc-800"
        : "hover:bg-gray-100"
    }
  `;

  const getTextClassName = (isSelected: boolean) =>
    isSelected ? "text-white" : isDarkMode ? "text-gray-100" : "text-gray-900";

  const getBorderClassName = () =>
    isDarkMode ? "border-zinc-700" : "border-gray-200";

  const renderAssociationList = () => (
    <div className="flex flex-col space-y-4">
      <div
        className={`max-h-[300px] overflow-y-auto border ${getBorderClassName()} rounded p-1`}
      >
        {items.map((item) => {
          const isSelected = selectedItems.includes(item[itemKey]);
          return (
            <div
              key={item[itemKey]}
              onClick={() => onSelect(item)}
              className={getItemClassName(isSelected)}
            >
              <div className="flex-1">
                <span className={getTextClassName(isSelected)}>
                  {item[itemKey]}
                </span>
              </div>
              {onRemove && showRemoveButton && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(item[itemKey]);
                  }}
                  className="p-1 hover:bg-red-100 dark:hover:bg-red-900 rounded"
                >
                  <Trash
                    size={16}
                    className={isSelected ? "text-white" : "text-red-500"}
                  />
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
  
  const renderGroupList = () => (
    <div className="flex flex-col space-y-4 max-h-[350px] overflow-y-auto pr-2">
      {items.map((item) => (
        <div
          key={item[itemKey]}
          className={`border rounded-lg overflow-visible mb-2 shadow-sm ${
            isDarkMode ? "border-zinc-600" : "border-gray-200"
          }`}
        >
          <div className={`flex justify-between items-center px-4 py-3 z-10 ${
            isDarkMode ? "bg-zinc-700" : "bg-gray-100"
          }`}>
            <span className={`font-medium truncate max-w-[70%] ${
              isDarkMode ? "text-gray-100" : "text-gray-800"
            }`}>
              {item[itemKey]}
            </span>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => handleStartEdit(item as GroupData)}
                className={`p-1 rounded transition-colors ${
                  isDarkMode 
                    ? "hover:bg-zinc-600 text-gray-200" 
                    : "hover:bg-gray-200 text-gray-600"
                }`}
              >
                <Edit size={16} />
              </button>
              {onRemove && showRemoveButton && (
                <button
                  onClick={() => onRemove(item.groupname || item[itemKey])}
                  className={`p-1 rounded transition-colors ${
                    isDarkMode 
                      ? "hover:bg-red-800/50 text-red-300" 
                      : "hover:bg-red-100 text-red-500"
                  }`}
                >
                  <Trash size={16} />
                </button>
              )}
            </div>
          </div>
          {showDescription && renderGroupContent(item)}
        </div>
      ))}
      <EditGroupModal
        isOpen={isEditModalOpen}
        onClose={handleEditModalClose}
        group={selectedGroup}
        onSave={handleSaveGroupEdit}
      />
      
      <CustomAlert
        open={alertConfig.open}
        type={alertConfig.type}
        message={alertConfig.message}
        onClose={() => setAlertConfig((prev) => ({ ...prev, open: false }))}
        textConfig={{
          CUSTOM_ALERT_BUTTON_CONFIRM: "Confirmar",
          CUSTOM_ALERT_BUTTON_CANCEL: "Cancelar",
          CUSTOM_ALERT_BUTTON_CLOSE: "Cerrar",
        }}
        title={alertConfig.title}
        onConfirm={alertConfig.onConfirm}
        $isDark={isDarkMode}
      />
    </div>
  );
  
  const renderGroupContent = (item: GroupData) => {
    return (
      <div className={`pl-4 pr-2 py-2 ${
        isDarkMode ? "bg-zinc-800" : "bg-gray-50"
      }`}>
        <p className={`text-sm mb-2 break-words ${
          isDarkMode ? "text-gray-300" : "text-gray-700"
        }`}>
          {item.description || "Sin descripción"}
        </p>
        {item.users && item.users.length > 0 && (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <Users size={14} className={
                isDarkMode ? "text-gray-400" : "text-gray-500"
              } />
              <h4 className={`text-xs font-medium ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}>
                Usuarios del grupo:
              </h4>
            </div>
            <div className="flex flex-wrap gap-2 pl-6 pb-2 max-h-[100px] overflow-y-auto">
              {item.users.map((user) => (
                <span
                  key={user.username}
                  className="inline-flex items-center px-2 py-1 rounded text-xs gap-1 mb-1 text-white"
                  style={{ backgroundColor: "#22C55E" }}
                >
                  <Users size={12} className="text-white" />
                  <span>
                    {user.username}
                  </span>
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };
  return viewMode === "list"
    ? showDescription
      ? renderGroupList()
      : renderAssociationList()
    : null;
};

const AssociationModal = ({ onClose, isOpen }: AssociationModalProps) => {
  const { isDarkMode } = useTheme();
  const [associations, setAssociations] = useState<Association[]>([]);
  const [selectedTab, setSelectedTab] = useState("associations");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [newGroup, setNewGroup] = useState({ name: "", description: "" });
  const [alertConfig, setAlertConfig] = useState({
    open: false,
    type: "success" as "success" | "error" | "loading" | "confirm",
    message: "",
    title: "",
    onConfirm: undefined as (() => Promise<void>) | undefined,
  });

  useEffect(() => {
    if (isOpen) {
      fetchInitialData();
      fetchGroups();
      fetchAssociations();
    }
  }, [isOpen]);

  const fetchInitialData = async () => {
    try {
      const [usersData, profilesData] = await Promise.all([
        getUsers(),
        getProfiles(),
      ]);

      setUsers(Array.isArray(usersData) ? usersData : []);
      setProfiles(
        Array.isArray(profilesData) ? profilesData : profilesData.profiles || []
      );
    } catch (error) {
      console.error("Error al cargar datos iniciales:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al cargar los datos iniciales",
        title: "Error",
        onConfirm: undefined,
      });
    }
  };

  const handleDeleteAssociation = async (userId) => {
    try {
      setAlertConfig({
        open: true,
        type: "confirm",
        message: "¿Deseas eliminar esta asociación y forzar la desconexión del usuario?",
        title: "Confirmar acción",
        onConfirm: async () => {
          try {
            setAlertConfig({
              open: true,
              type: "loading",
              message: "Eliminando asociación...",
              title: "",
              onConfirm: undefined,
            });
  
            await deleteAssociation(userId);
            await fetchAssociations();
  
            setAlertConfig({
              open: true,
              type: "success",
              message: "Asociación eliminada con éxito. El usuario perderá acceso en su próxima solicitud o cuando su sesión expire.",
              title: "Éxito",
              onConfirm: undefined,
            });
          } catch (error) {
            console.error("Error al eliminar asociación:", error);
            setAlertConfig({
              open: true,
              type: "error",
              message: "Error al eliminar la asociación",
              title: "Error",
              onConfirm: undefined,
            });
          }
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const hasExistingAssociation = () => {
    return associations.some(
      (assoc) =>
        assoc.user_id === selectedUsers[0] &&
        assoc.profile_id === selectedProfiles[0]
    );
  };

  const fetchAssociations = async () => {
    try {
      const apiConfig = await getFetchBaseUrl();
      if (!apiConfig.API_ASSOCIATION)
        throw new Error("URL de API para asociaciones no disponible");
  
      const token = Cookies.get("accessToken");
  
      const response = await fetch(apiConfig.API_ASSOCIATION, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) throw new Error("Error al obtener asociaciones");
      const data = await response.json();
      setAssociations(data.associations || []);
    } catch (error) {
      console.error("Error detallado en fetchAssociations:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al cargar las asociaciones",
        title: "Error",
        onConfirm: undefined,
      });
    }
  };
  const verifyAssociation = async (userId) => {
    try {
      const apiConfig = await getFetchBaseUrl();
      const url = `${apiConfig.API_ASSOCIATION}?user_id=${userId}`;
      
      const token = Cookies.get("accessToken");
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (!response.ok) throw new Error("Error al verificar asociación");
      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error("Error al verificar asociación:", error);
      return null;
    }
  };
  const fetchGroups = async () => {
    try {
      const data = await getGroups();
      const processedGroups = data.map((group) => ({
        ...group,
        users: Array.isArray(group.users) ? group.users : [],
      }));

      setGroups(processedGroups);
    } catch (error) {
      console.error("Error al cargar grupos:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: "Error al cargar los grupos",
        title: "Error",
        onConfirm: undefined,
      });
    }
  };

  const handleUserSelect = (user: User) => {
    setSelectedUsers([user.username]);
  };

  const handleProfileSelect = (profile: Profile) => {
    setSelectedProfiles([profile.profile_id]);
  };

  const handleCreateAssociation = async () => {
    if (selectedUsers.length === 0 || selectedProfiles.length === 0) return;
  
    setAlertConfig({
      open: true,
      type: "loading",
      message: "Creando asociación...",
      title: "",
      onConfirm: undefined,
    });
  
    try {

      const result = await createAssociation({
        user_id: selectedUsers[0],
        profile_id: selectedProfiles[0],
      });
        
      try {
        const verificationResult = await verifyAssociation(selectedUsers[0]);
        
        if (verificationResult && 
            verificationResult.profile_id === selectedProfiles[0]) {
        } else {
        }
      } catch (verifyError) {
        console.error("Error al verificar la asociación:", verifyError);
      }
  
      await fetchAssociations();
      setSelectedUsers([]);
      setSelectedProfiles([]);
      setAlertConfig({
        open: true,
        type: "success",
        message: "Asociación creada con éxito",
        title: "Éxito",
        onConfirm: undefined,
      });
    } catch (error) {
      console.error("Error detallado al crear asociación:", error);
  
      let errorMessage = "Error al crear la asociación";
      if (error.message && error.message.includes("AlreadyAssociatedError")) {
        errorMessage = "El usuario ya está asociado a un perfil";
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error_message
      ) {
        errorMessage = error.response.data.error_message;
      }
  
      setAlertConfig({
        open: true,
        type: "error",
        message: errorMessage,
        title: "Error",
        onConfirm: undefined,
      });
    }
  };

  const handleCreateGroup = async () => {
    if (!newGroup.name) return;
  
    const groupNameExists = groups.some(
      (group) => group.groupname.toLowerCase() === newGroup.name.toLowerCase()
    );
  
    if (groupNameExists) {
      setAlertConfig({
        open: true,
        type: "error",
        message: `Ya existe un grupo con el nombre "${newGroup.name}". Por favor, utilice un nombre diferente.`,
        title: "Nombre duplicado",
        onConfirm: undefined,
      });
      return;
    }
  
    setAlertConfig({
      open: true,
      type: "loading",
      message: "Creando grupo...",
      title: "",
      onConfirm: undefined,
    });
  
    try {
      await createGroup({
        groupname: newGroup.name,
        description: newGroup.description,
      });
  
      await fetchGroups();
      setNewGroup({ name: "", description: "" });
      
      setAlertConfig({
        open: true,
        type: "success",
        message: "Grupo creado con éxito",
        title: "Éxito",
        onConfirm: undefined,
      });
    } catch (error: any) {
      console.error("Error al crear grupo:", error);
      
      const errorMessage = error?.message || "";
      if (errorMessage.includes("duplicate") || 
          errorMessage.includes("already exists") || 
          errorMessage.includes("ya existe")) {
        setAlertConfig({
          open: true,
          type: "error",
          message: `Ya existe un grupo con el nombre "${newGroup.name}". Por favor, utilice un nombre diferente.`,
          title: "Nombre duplicado",
          onConfirm: undefined,
        });
      } else {
        setAlertConfig({
          open: true,
          type: "error",
          message: "Error al crear el grupo. Por favor, inténtelo de nuevo.",
          title: "Error",
          onConfirm: undefined,
        });
      }
    }
  };
  const handleDeleteGroup = async (groupname: string) => {
    setAlertConfig({
      open: true,
      type: "confirm",
      message: `¿Estás seguro que deseas eliminar el grupo ${groupname}?`,
      title: "Confirmar eliminación",
      onConfirm: async () => {
        try {
          setAlertConfig({
            open: true,
            type: "loading",
            message: "Eliminando grupo...",
            title: "",
            onConfirm: undefined,
          });

          await deleteGroup(groupname);
          await fetchGroups();

          setAlertConfig({
            open: true,
            type: "success",
            message: "Grupo eliminado exitosamente",
            title: "Éxito",
            onConfirm: undefined,
          });
        } catch (error) {
          setAlertConfig({
            open: true,
            type: "error",
            message: "Error al eliminar el grupo",
            title: "Error",
            onConfirm: undefined,
          });
        }
      },
    });
  };

  const AssociationsList = ({
    associations,
    onDeleteAssociation,
  }: {
    associations: Association[];
    onDeleteAssociation: (userId: string) => Promise<void>;
  }) => {
    const { isDarkMode } = useTheme();
  
    return (
      <div className="mt-6">
        <h3 className="text-sm font-medium mb-2">Asociaciones Existentes</h3>
        <div className="border border-gray-200 dark:border-zinc-700 rounded-lg overflow-hidden max-h-[250px]">
          {associations.length === 0 ? (
            <div className="p-3 text-center text-gray-500">
              No hay asociaciones existentes
            </div>
          ) : (
            <div className="overflow-y-auto max-h-[250px] custom-scrollbar">
              {associations.map((association, index) => (
                <div
                  key={`${association.user_id}-${association.profile_id}`}
                  className={`flex justify-between items-center p-3 ${
                    index !== associations.length - 1
                      ? "border-b border-gray-200 dark:border-zinc-700"
                      : ""
                  } ${isDarkMode ? "hover:bg-zinc-800" : "hover:bg-gray-50"}`}
                >
                  <div className="flex items-center space-x-4">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium">
                        Usuario: {association.user_id}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        Perfil: {association.profile_id}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() => onDeleteAssociation(association.user_id)}
                    className="p-2 hover:bg-red-100 dark:hover:bg-red-900 rounded transition-colors"
                  >
                    <Trash size={16} className="text-red-500" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  const getTextColorClass = () =>
    isDarkMode ? "text-gray-300" : "text-gray-600";

  const getBorderColorClass = () =>
    isDarkMode ? "border-zinc-700" : "border-gray-200";

  return (
    <>
      <BaseModal title="Asociación" onClose={onClose} isOpen={isOpen}>
        <div className="w-full max-w-2xl">
          <p className={`mb-4 ${getTextColorClass()}`}>
            Gestionar asociaciones de usuarios y perfiles
          </p>

          <div className="mb-4">
            <CustomToggleTabs
              value={selectedTab}
              onChange={setSelectedTab}
              options={[
                {
                  value: "associations",
                  label: "Asociaciones",
                  icon: <Users size={18} />,
                },
                {
                  value: "groups",
                  label: "Grupos",
                  icon: <Boxes size={18} />,
                },
              ]}
            />
          </div>

          {selectedTab === "associations" ? (
  <div className="mt-4">
    <div
      className={`grid grid-cols-2 gap-4 mb-4 ${getBorderColorClass()}`}
    >
      <div className="flex flex-col h-[350px]">
        <h3 className="text-sm font-medium mb-2">Perfiles</h3>
        <div className="flex-1">
          <ItemDisplay
            items={profiles}
            viewMode="list"
            onSelect={handleProfileSelect}
            selectedItems={selectedProfiles}
            itemKey="profile_id"
            onRemove={() => setSelectedProfiles([])}
            showRemoveButton={false}
          />
        </div>
      </div>

      <div className="flex flex-col h-[350px]">
        <h3 className="text-sm font-medium mb-2">Usuarios</h3>
        <div className="flex-1">
          <ItemDisplay
            items={users}
            viewMode="list"
            onSelect={handleUserSelect}
            selectedItems={selectedUsers}
            itemKey="username"
            onRemove={() => setSelectedUsers([])}
            showRemoveButton={false}
          />
        </div>
      </div>
    </div>

    <div className="flex justify-end gap-2 mb-4">
      <ActionButton
        label="Limpiar"
        onClick={() => {
          setSelectedUsers([]);
          setSelectedProfiles([]);
        }}
        Icon={<Trash size={16} />}
        variant="clean"
      />
      {selectedUsers.length > 0 &&
        selectedProfiles.length > 0 &&
        (hasExistingAssociation() ? (
          <ActionButton
            label="Eliminar Asociación"
            onClick={() => handleDeleteAssociation(selectedUsers[0])}
            Icon={<Trash size={16} />}
            variant="delete"
          />
        ) : (
          <ActionButton
            label="Crear Asociación"
            onClick={handleCreateAssociation}
            Icon={<Plus size={16} />}
          />
        ))}
    </div>

    <AssociationsList
      associations={associations}
      onDeleteAssociation={handleDeleteAssociation}
    />
  </div>
) : (
  <div className="mt-4">
    <div 
      className={`mb-4 p-2 rounded border flex items-center gap-2 ${
        isDarkMode 
          ? "bg-green-900/10 border-green-500 text-green-300" 
          : "bg-green-50 border-green-500 text-green-800"
      }`}
    >
      <Info size={18} className={isDarkMode ? "text-green-400" : "text-green-600"} />
      <p className="text-sm">
        El nombre del grupo debe ser único. No se permite crear grupos con nombres duplicados.
      </p>
    </div>

    <div className="flex flex-col gap-3 mb-4">
      <CustomTextField
        label="Nombre del Grupo"
        value={newGroup.name}
        onChange={(e) =>
          setNewGroup({ ...newGroup, name: e.target.value })
        }
        placeholder="Ingrese nombre del grupo"
        isFullWidth
      />
      <CustomTextField
        label="Descripción"
        value={newGroup.description}
        onChange={(e) =>
          setNewGroup({ ...newGroup, description: e.target.value })
        }
        placeholder="Ingrese descripción del grupo"
        isFullWidth
        multiline
        rows={3}
      />
    </div>
    <ActionButton
      label="Crear Grupo"
      onClick={handleCreateGroup}
      Icon={<Plus size={16} />}
      disabled={!newGroup.name}
    />
    <div className="mt-4 overflow-hidden">
      <h3 className="text-sm font-medium mb-2 z-10">
        Grupos Existentes
      </h3>
      <ItemDisplay
        items={groups}
        viewMode="list"
        onSelect={() => {}}
        selectedItems={[]}
        itemKey="groupname"
        onRemove={handleDeleteGroup}
        fetchGroups={fetchGroups}
        onUpdate={updateGroup}
        showDescription
      />
    </div>
  </div>
)}
        </div>
        
      </BaseModal>
      <CustomAlert
        open={alertConfig.open}
        type={alertConfig.type}
        message={alertConfig.message}
        onClose={() => setAlertConfig((prev) => ({ ...prev, open: false }))}
        textConfig={{
          CUSTOM_ALERT_BUTTON_CONFIRM: "Confirmar",
          CUSTOM_ALERT_BUTTON_CANCEL: "Cancelar",
          CUSTOM_ALERT_BUTTON_CLOSE: "Cerrar",
        }}
        title={alertConfig.title}
        onConfirm={alertConfig.onConfirm}
      />
    </>
  );
};

export default AssociationModal;
