import { getEndpoints } from "../utils/ApiContext";
import fetchWithAuth from "../utils/fetchWithAuth";


export const fetchServiceStatus = async (serviceName) => {
  try {
    const endpoints = await getEndpoints();
    const API_SERVICE_STATUS_BASE = endpoints.API_SERVICE_STATUS;
    const API_URL = `${API_SERVICE_STATUS_BASE}?service_name=${encodeURIComponent(serviceName)}`;
    const response = await fetchWithAuth(API_URL);

    if (!response.ok) {
      throw new Error("Error al obtener el estado del servicio");
    }

    const serviceStatus = await response.json();
    return serviceStatus;
  } catch (error) {
    console.error("Error al intentar obtener el estado del servicio:", error);
    throw error;
  }
};