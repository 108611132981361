import React, { useContext, useRef, useState, useEffect } from "react";
import { ContextApp } from "../utils/Context";
import { ThemeContext } from "../components/ThemeContext";
import Footer from "./Footer";
import Chat from "./Chat";
import { RiSendPlane2Fill } from "react-icons/ri";

function ChatContainer() {
  const {
    chatValue,
    setChatValue,
    handleSend,
    isWaitingForResponse,
    isChatOpened,
    message,
  } = useContext(ContextApp);

  const { isDarkMode } = useContext(ThemeContext);
  const textareaRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [shouldShowInCenter, setShouldShowInCenter] = useState(true);

  useEffect(() => {
    if (message && message.length > 0) {
      setShouldShowInCenter(false);
    }
  }, [message]);

  useEffect(() => {
    if (isMessageSent && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      setIsMessageSent(false);
    }
  }, [isMessageSent]);

  useEffect(() => {
    if (!isWaitingForResponse && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isWaitingForResponse]);

  const handleSendMessage = async () => {
    if (chatValue.trim() !== "") {
      setShouldShowInCenter(false);
      await handleSend();
      setIsMessageSent(true);
      requestAnimationFrame(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      });
    }
  };

  return (
    <div
      className={`relative flex flex-col h-screen w-full ${
        isDarkMode ? "bg-transparent" : "bg-white"
      }`}
    >
      <div ref={chatContainerRef} className="flex-1 overflow-y-hidden">
        <Chat />
      </div>

      <div
        className={`${
          !shouldShowInCenter
            ? "fixed bottom-10 w-full pl-0 pr-12 sm:pr-24 md:pr-28"
            : "absolute top-1/3 w-full pl-0 pr-12 sm:pr-24 md:pr-16"
        } py-4 flex justify-center transition-all duration-500`}
      >
        <div
          className={`flex items-center w-full max-w-[300px] sm:max-w-[500px] md:max-w-2xl border-2 border-green-500 rounded-full shadow-md ml-4 sm:ml-8 md:ml-12 ${
            isDarkMode ? "bg-neutral-800" : "bg-white"
          }`}
        >
          <textarea
            ref={textareaRef}
            autoFocus
            placeholder="Escribe un mensaje..."
            className={`flex-1 resize-none rounded-full p-3 focus:outline-none ml-4 content-center ${
              isDarkMode ? "bg-transparent text-white" : "bg-white text-black"
            }`}
            value={chatValue}
            onChange={(e) => setChatValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={isWaitingForResponse}
            style={{ lineHeight: "1.5" }}
          />
          <button
            className={`flex items-center justify-center w-12 h-12 rounded-full mr-2 ${
              chatValue.trim()
                ? "bg-green-500 text-white hover:bg-green-600"
                : "bg-gray-400 text-white cursor-not-allowed"
            }`}
            onClick={handleSendMessage}
            disabled={chatValue.trim() === "" || isWaitingForResponse}
          >
            <RiSendPlane2Fill size={24} />
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ChatContainer;