import React from "react";
import Button from "@mui/material/Button";

          interface ActionButtonProps {
            label: string;
            onClick: () => void;
            Icon: React.ReactNode;
            variant?: "default" | "delete" | "clean";
            disabled?: boolean;
            size?: "small" | "medium" | "large";
          }

          export function ActionButton({
            label,
            onClick,
            Icon,
            variant = "default",
            disabled = false,
            size = "medium",
          }: ActionButtonProps) {
            const getButtonStyles = () => {
              const baseStyles = {
                textTransform: "none",
                color: "#FFFFFF",
                whiteSpace: "nowrap",
                minWidth: "fit-content",
                padding:
                  size === "small"
                    ? "4px 10px"
                    : size === "large"
                    ? "8px 22px"
                    : "6px 16px",
                fontSize:
                  size === "small" ? "0.875rem" : size === "large" ? "1rem" : "0.875rem",
              };

              let colorStyles = {};
              switch (variant) {
                case "delete":
                  colorStyles = {
                    backgroundColor: "#ef4444",
                    "&:hover": {
                      backgroundColor: "#dc2626",
                    },
                    "&:focus": {
                      backgroundColor: "#ef4444",
                    },
                    "&:active": {
                      backgroundColor: "#b91c1c",
                    },
                  };
                  break;
                case "clean":
                  colorStyles = {
                    backgroundColor: "#18181b",
                    "&:hover": {
                      backgroundColor: "#27272a",
                    },
                    "&:focus": {
                      backgroundColor: "#18181b",
                    },
                    "&:active": {
                      backgroundColor: "#3f3f46",
                    },
                  };
                  break;
                default:
                  colorStyles = {
                    backgroundColor: "#1BCF66",
                    "&:hover": {
                      backgroundColor: "#18b85b",
                    },
                    "&:focus": {
                      backgroundColor: "#1BCF66",
                    },
                    "&:active": {
                      backgroundColor: "#15a852",
                    },
                  };
              }

              const disabledStyles = {
                "&.Mui-disabled": {
                  backgroundColor:
                    variant === "delete"
                      ? "#fca5a5"
                      : variant === "clean"
                      ? "#3f3f46"
                      : "#86efac",
                  color: "#ABABAB",
                  opacity: 0.7,

                  "& .MuiSvgIcon-root, & .MuiButton-startIcon svg": {
                    color: "#ABABAB",
                  },
                },
              };

              return {
                ...baseStyles,
                ...colorStyles,
                ...disabledStyles,
              };
            };

            return (
              <Button
                onClick={onClick}
                variant="contained"
                startIcon={Icon}
                disabled={disabled}
                sx={getButtonStyles()}
              >
                {label}
              </Button>
            );
          }
