import React, { useContext } from "react";
import RightSidebar from "../components/RightSidebar";
import ChatContainer from "../components/ChatContainer";
import Header from "../components/Header";
import { ContextApp } from "../utils/Context";
import { CircularProgress } from "@mui/material";

function HomeContent() {
  const { isLoadingChat } = useContext(ContextApp);

  return (
    <div className="flex flex-col h-screen relative">
      {isLoadingChat && (
        <div className="absolute inset-0 bg-zinc-900 bg-opacity-30 flex items-center justify-center z-20">
          <CircularProgress color="inherit" />
        </div>
      )}
      
      <Header className="flex-none relative z-10" />
      <div className="flex flex-1 overflow-hidden relative">
        <ChatContainer className="relative z-10" />
        <RightSidebar className="relative z-30" />
      </div>
    </div>
  );
}

export default HomeContent;