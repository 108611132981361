import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { fetchTopics, deleteTopic } from "../services/topicApi";
import { fetchChatHistory, getUserDocuments } from "../services/uploadFilesApi";
import { refreshToken } from "../services/loginApi";
import { jwtDecode } from "jwt-decode";
import fetchWithAuth from "./fetchWithAuth";
import { ConfigContext } from "../utils/Config.jsx";
import { ApiContext, getEndpoints } from "../utils/ApiContext";

export const ContextApp = createContext();

const AppContext = ({ children }) => {
  const configContext = useContext(ConfigContext);
  const config = configContext?.config || {};
  const isLoadingConfig = configContext?.isLoading || false;
  
  const apiContext = useContext(ApiContext);
  const apiEndpoints = apiContext?.endpoints || {};
  const isLoadingApi = apiContext?.isLoading || false;

  const [showSlide, setShowSlide] = useState(false);
  const [chatValue, setChatValue] = useState("");
  const [message, setMessage] = useState(() => {
    const storedMessages = localStorage.getItem("chatHistory");
    return storedMessages ? JSON.parse(storedMessages) : [];
  });

  const [hasAccessError, setHasAccessError] = useState(false);
  
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isAwaitingNewResponse, setIsAwaitingNewResponse] = useState(false);
  const ws = useRef(null);
  const reconnectTimeout = useRef(null);
  const responseTimeout = useRef(null);
  const reconnectAttempts = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 3;

  const [currentTopic, setCurrentTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [reloadTopicsTrigger, setReloadTopicsTrigger] = useState(0);
  const [currentCollectionDocuments, setCurrentCollectionDocuments] = useState([]);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [isCollectionSelectorOpen, setIsCollectionSelectorOpen] = useState(false);

  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isChatOpened, setIsChatOpened] = useState(false);
  const [lastError, setLastError] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(() => !!Cookies.get("accessToken"));
  const [isGuest, setIsGuest] = useState(() => !Cookies.get("accessToken"));
  const [expirationDate, setExpirationDate] = useState(() => {
    const expiration = Cookies.get("expirationDate");
    return expiration ? new Date(expiration) : null;
  });
  const [userRole, setUserRole] = useState(null);
  const [username, setUsername] = useState(() => {
    const fromCookies = Cookies.get("username");
    if (fromCookies) return fromCookies;
    const fromLocalStorage = localStorage.getItem("username");
    if (fromLocalStorage) return fromLocalStorage;
    
    try {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken && decodedToken.username) {
          return decodedToken.username;
        }
      }
    } catch (error) {
      console.error("Error al decodificar token para username:", error);
    }
    
    return null;
  });

  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);

  const awaitingResponseRef = useRef(isAwaitingNewResponse);

  useEffect(() => {
    if (config && config.DEFAULT_COLLECTION) {
      if (isGuest || username === "admin") {
        const defaultCols = [config.DEFAULT_COLLECTION];
        setCollections(defaultCols);
        setSelectedCollection(config.DEFAULT_COLLECTION);
      } else {
        if (!hasAccessError) {
          loadCollections();
        }
      }
    }
  }, [config, isGuest, username, hasAccessError]);

  const decodeToken = () => {
    try {
      const accessToken = Cookies.get("accessToken");
      if (!accessToken) {
        setUserRole(null);
        setUsername(null);
        return;
      }

      const decodedToken = jwtDecode(accessToken);

      if (
        decodedToken &&
        decodedToken["cognito:groups"] &&
        Array.isArray(decodedToken["cognito:groups"])
      ) {
        setUserRole(
          decodedToken["cognito:groups"].includes("admin") ? "admin" : "user"
        );
      } else {
        setUserRole("user");
      }

      if (decodedToken && decodedToken.username) {
        setUsername(decodedToken.username);
      } else {
        setUsername(null);
      }
    } catch (error) {
      console.error("Error al decodificar el token:", error);
      setUserRole(null);
      setUsername(null);
    }
  };

  useEffect(() => {
    if (username) {
      Cookies.set("username", username);
      localStorage.setItem("username", username);
    } else {
      Cookies.remove("username");
      localStorage.removeItem("username");
    }
  }, [username]);


  useEffect(() => {
    decodeToken();
    if (!hasAccessError) {
      loadCollections();
    }
  }, [isLoggedIn, hasAccessError]);

  useEffect(() => {
    if (isCollectionSelectorOpen && !hasAccessError) {
      loadCollections();
    }
  }, [isCollectionSelectorOpen, hasAccessError]);

  useEffect(() => {
    awaitingResponseRef.current = isAwaitingNewResponse;
  }, [isAwaitingNewResponse]);

  useEffect(() => {
    if (!hasAccessError) {
      loadTopics();
      resetChat();
    }
  }, [reloadTopicsTrigger, isLoggedIn, isGuest, hasAccessError]);

  const triggerLoadHistory = (load) => {
    setShouldLoadHistory(load);
  };
  
  const loadTopics = async () => {
    if (isGuest || hasAccessError) {
      setTopics([]);
      return;
    }
    try {
      const fetchedTopics = await fetchTopics();
      setTopics(fetchedTopics);
    } catch (error) {
      console.error("Error al cargar los tópicos:", error);
    }
  };

  const loadCollections = async () => {
    if (hasAccessError) {
      return [];
    }
    
    if (isGuest) {
      if (config && config.DEFAULT_COLLECTION) {
        const guestCollection = [config.DEFAULT_COLLECTION];
        setCollections(guestCollection);
        setSelectedCollection(config.DEFAULT_COLLECTION);
        return guestCollection;
      } else {
        return [];
      }
    }

    try {
      const accessToken = Cookies.get("accessToken");
      const decodedToken = jwtDecode(accessToken);
      const currentUsername = decodedToken.username;

      if (currentUsername === "admin") {
        if (config && config.DEFAULT_COLLECTION) {
          const adminCollection = [config.DEFAULT_COLLECTION];
          setCollections(adminCollection);
          setSelectedCollection(config.DEFAULT_COLLECTION);
          localStorage.setItem(
            "selectedCollection",
            config.DEFAULT_COLLECTION
          );
          return adminCollection;
        } else {
          const defaultCol = ["coleccion1"];
          setCollections(defaultCol);
          setSelectedCollection("coleccion1");
          return defaultCol;
        }
      }

      try {
        const endpoints = await getEndpoints();
        const API_ASSOCIATION = apiEndpoints.API_ASSOCIATION || endpoints.API_ASSOCIATION;
                               
        const associationUrl = `${API_ASSOCIATION}?user_id=${currentUsername}`;

        const associationResponse = await fetchWithAuth(associationUrl);

        if (!associationResponse.ok) {
          console.error(
            "Error al verificar asociación:",
            await associationResponse.text()
          );
          setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
          setHasAccessError(true);
          setCollections([]);
          setSelectedCollection("");
          localStorage.removeItem("selectedCollection");
          return [];
        }

        const associationData = await associationResponse.json();

        if (!associationData || !associationData.profile_id) {
          setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
          setHasAccessError(true);
          setCollections([]);
          setSelectedCollection("");
          localStorage.removeItem("selectedCollection");
          return [];
        }

        const API_PROFILES = apiEndpoints.API_PROFILES || endpoints.API_PROFILES;
                            
        const profileUrl = `${API_PROFILES}?profile_id=${associationData.profile_id}`;
        
        const profileResponse = await fetchWithAuth(profileUrl);

        if (!profileResponse.ok) {
          console.error(
            "Error al obtener perfil:",
            await profileResponse.text()
          );
          setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
          setHasAccessError(true);
          setCollections([]);
          setSelectedCollection("");
          return [];
        }

        const profileData = await profileResponse.json();
        if (
          profileData.backend_config &&
          profileData.backend_config.collections
        ) {
          const profileCollections = profileData.backend_config.collections;

          if (profileCollections.length > 0) {
            setCollections(profileCollections);

            const newSelectedCollection =
              selectedCollection &&
              profileCollections.includes(selectedCollection)
                ? selectedCollection
                : profileCollections[0];

            setSelectedCollection(newSelectedCollection);
            localStorage.setItem("selectedCollection", newSelectedCollection);
            setLastError(null);
            setHasAccessError(false);

            return profileCollections;
          } else {
            setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
            setHasAccessError(true);
            setCollections([]);
            setSelectedCollection("");
            return [];
          }
        } else {
          setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
          setHasAccessError(true);
          setCollections([]);
          setSelectedCollection("");
          return [];
        }
      } catch (error) {
        console.error("Error al obtener documentos:", error);
        setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
        setHasAccessError(true);
        setCollections([]);
        setSelectedCollection("");
        return [];
      }
    } catch (error) {
      console.error("Error al cargar las colecciones:", error);
      setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
      setHasAccessError(true);
      setCollections([]);
      setSelectedCollection("");
      return [];
    }
  };

  const loadDocumentsForCollection = async (collectionName) => {
    if (hasAccessError) {
      return false;
    }
    
    if (isGuest) {
      return true;
    }

    if (!collectionName) {
      console.error("Nombre de colección vacío");
      setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
      setHasAccessError(true);
      return false;
    }

    try {
      const accessToken = Cookies.get("accessToken");
      const decodedToken = jwtDecode(accessToken);
      const currentUsername = decodedToken.username;

      if (currentUsername === "admin") {
        if (config) {
          if (config.DEFAULT_COLLECTION) {
            if (collectionName !== config.DEFAULT_COLLECTION) {
              setLastError(`No tienes acceso a la colección: ${collectionName}`);
              setHasAccessError(true);
              return false;
            }

            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
              const messageData = {
                collection: config.DEFAULT_COLLECTION,
                Authorization: `Bearer ${accessToken}`,
                topic_id: currentTopic ? currentTopic.id : "",
                context_update: true,
              };

              ws.current.send(JSON.stringify(messageData));
            }

            setLastError(null);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      try {
        const endpoints = await getEndpoints();
        const API_ASSOCIATION = apiEndpoints.API_ASSOCIATION || endpoints.API_ASSOCIATION;
                               
        const associationUrl = `${API_ASSOCIATION}?user_id=${currentUsername}`;

        const associationResponse = await fetchWithAuth(associationUrl);

        if (associationResponse.ok) {
          const associationData = await associationResponse.json();

          if (associationData && associationData.profile_id) {
            const API_PROFILES = apiEndpoints.API_PROFILES || endpoints.API_PROFILES;
                                
            const profileUrl = `${API_PROFILES}?profile_id=${associationData.profile_id}`;
            
            const profileResponse = await fetchWithAuth(profileUrl);

            if (profileResponse.ok) {
              const profileData = await profileResponse.json();

              if (
                profileData.backend_config &&
                profileData.backend_config.collections &&
                profileData.backend_config.collections.includes(collectionName)
              ) {
                if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                  const messageData = {
                    collection: collectionName,
                    Authorization: `Bearer ${accessToken}`,
                    topic_id: currentTopic ? currentTopic.id : "",
                    context_update: true,
                  };

                  ws.current.send(JSON.stringify(messageData));
                }

                setLastError(null);
                return true;
              }
            }
          }
        }

        const documents = await getUserDocuments();
        const collectionDocs = documents.filter(
          (doc) => doc.COLLECTION_NAME === collectionName
        );

        if (collectionDocs.length === 0) {
          setLastError(`No tienes acceso a la colección: ${collectionName}`);
          setHasAccessError(true);
          return false;
        }

        setCurrentCollectionDocuments(collectionDocs);

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          const messageData = {
            collection: collectionName,
            Authorization: `Bearer ${accessToken}`,
            topic_id: currentTopic ? currentTopic.id : "",
            context_update: true,
          };

          ws.current.send(JSON.stringify(messageData));
        }

        setLastError(null);
        return true;
      } catch (error) {
        console.error("Error al verificar acceso a la colección:", error);
        setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
        setHasAccessError(true);
        return false;
      }
    } catch (error) {
      console.error("Error al cargar los documentos de la colección:", error);
      setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
      setHasAccessError(true);
      return false;
    }
  };
  
  const resetWebSocketConnection = () => {
    reconnectAttempts.current = 0;

    if (responseTimeout.current) {
      clearTimeout(responseTimeout.current);
      responseTimeout.current = null;
    }

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
    }

    setIsWaitingForResponse(false);
    setIsAwaitingNewResponse(false);

    if (!hasAccessError) {
      setupWebSocket();
    }
  };

  async function getWebSocketUrlFromJson() {
    try {
      const endpoints = await getEndpoints();
      const API_WEBSOCKET = endpoints.API_WEBSOCKET;
      return API_WEBSOCKET;
    } catch (error) {
      console.error("Error al obtener la URL del WebSocket:", error);
      throw error;
    }
  }

  const getWebSocketURL = async () => {
    try {
      const API_WEBSOCKET = await getWebSocketUrlFromJson();
      return API_WEBSOCKET;
    } catch (error) {
      console.error("Error al obtener la URL del WebSocket:", error);
      throw error;
    }
  };

  const setupWebSocket = async () => {
    if (hasAccessError) {
      return;
    }
    
    try {
      if (!isGuest && username && username !== "admin") {
        try {
          const endpoints = await getEndpoints();
          const API_ASSOCIATION = apiEndpoints.API_ASSOCIATION || endpoints.API_ASSOCIATION;
                                 
          const associationUrl = `${API_ASSOCIATION}?user_id=${username}`;

          const token = Cookies.get("accessToken");
          
          const associationResponse = await fetchWithAuth(associationUrl);

          if (!associationResponse.ok) {
            setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
            setHasAccessError(true);
            return;
          }

          const associationData = await associationResponse.json();
          if (!associationData || !associationData.profile_id) {
            setLastError("Tu perfil ha sido desasociado. Por favor, contacta al administrador.");
            setHasAccessError(true);
            return;
          }
        } catch (error) {
          console.error("Error al verificar acceso para websocket:", error);
        }
      }

      reconnectAttempts.current += 1;

      const wsPath = await getWebSocketURL();
      if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
        ws.current = new WebSocket(wsPath);

        ws.current.onopen = () => {
          setIsWaitingForResponse(false);
          setIsAwaitingNewResponse(false);
          reconnectAttempts.current = 0;
        };
        ws.current.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);

            if (data.error) {
              console.error("Error del WebSocket:", data.error);
              setIsWaitingForResponse(false);
              setIsAwaitingNewResponse(false);

              const errorMessage = isGuest
                ? "No se pudo procesar tu consulta. Por favor, intenta con otra pregunta."
                : "Error al procesar tu consulta. Por favor, intenta nuevamente.";

              setMessage((prevMessages) => [
                ...prevMessages,
                {
                  text: errorMessage,
                  isBot: true,
                  isError: true,
                },
              ]);

              if (responseTimeout.current) {
                clearTimeout(responseTimeout.current);
                responseTimeout.current = null;
              }

              return;
            }

            if (data.TOPIC_ID) {
              setCurrentTopic({
                id: data.TOPIC_ID,
                name: data.TOPIC_NAME,
              });
            } else {
              if (awaitingResponseRef.current) {
                setIsAwaitingNewResponse(false);
              }
              setMessage((prevMessages) => {
                const lastMessage =
                  prevMessages.length > 0
                    ? prevMessages[prevMessages.length - 1]
                    : null;
                if (lastMessage && lastMessage.isBot) {
                  const combinedText = data.map((item) => item.text).join("");
                  return [
                    ...prevMessages.slice(0, prevMessages.length - 1),
                    { ...lastMessage, text: lastMessage.text + combinedText },
                  ];
                } else {
                  const combinedText = data.map((item) => item.text).join("");
                  return [...prevMessages, { text: combinedText, isBot: true }];
                }
              });
            }

            reconnectAttempts.current = 0;
          } catch (error) {
            console.error("Error al parsear el mensaje del WebSocket:", error);

            setTimeout(() => {
              setMessage((prevMessages) => [
                ...prevMessages,
                {
                  text: "Error en la respuesta del servidor",
                  isBot: true,
                  isError: true,
                },
              ]);
            }, 10000);

            setIsWaitingForResponse(false);
            setIsAwaitingNewResponse(false);
          }

          if (ws.current.timeout) {
            clearTimeout(ws.current.timeout);
          }

          ws.current.timeout = setTimeout(() => {
            setIsWaitingForResponse(false);
            if (!hasAccessError) {
              loadTopics();
            }
          }, 1000);

          if (responseTimeout.current) {
            clearTimeout(responseTimeout.current);
            responseTimeout.current = null;
          }
        };

        ws.current.onerror = (error) => {
          console.error("Error en la conexión WebSocket:", error);
          setIsWaitingForResponse(false);
          setIsAwaitingNewResponse(false);

          if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
            reconnectAttempts.current = 0;
            setTimeout(() => {
              setMessage((prevMessages) => [
                ...prevMessages,
                {
                  text: "Error de conexión. Por favor, intenta nuevamente más tarde o recarga la página.",
                  isBot: true,
                  isError: true,
                },
              ]);
            }, 60000);
          }

          if (responseTimeout.current) {
            clearTimeout(responseTimeout.current);
            responseTimeout.current = null;
          }
        };

        ws.current.onclose = (event) => {
          if (event.code !== 1000) {
            setIsWaitingForResponse(false);
            setIsAwaitingNewResponse(false);

            if (
              awaitingResponseRef.current &&
              reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS
            ) {
              setTimeout(() => {
                setMessage((prevMessages) => [
                  ...prevMessages,
                  {
                    text: "La conexión se ha cerrado. Por favor, intenta nuevamente.",
                    isBot: true,
                    isError: true,
                  },
                ]);
              }, 60000);
            }
          }

          if (responseTimeout.current) {
            clearTimeout(responseTimeout.current);
            responseTimeout.current = null;
          }
        };
      }
    } catch (error) {
      console.error("Error al configurar el WebSocket:", error);
      setIsWaitingForResponse(false);
      setIsAwaitingNewResponse(false);

      if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
        setTimeout(() => {
          setMessage((prevMessages) => [
            ...prevMessages,
            {
              text: "Error al establecer la conexión. Por favor, recarga la página.",
              isBot: true,
              isError: true,
            },
          ]);
        }, 60000);
      }
    }
  };

  const closeWebSocket = () => {
    if (responseTimeout.current) {
      clearTimeout(responseTimeout.current);
      responseTimeout.current = null;
    }

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
    }
  };
  useEffect(() => {
    if (!hasAccessError) {
      setupWebSocket();
    }
    return () => {
      closeWebSocket();
      clearTimeout(ws.current?.timeout);
    };
  }, [hasAccessError]);

  const handleSend = async (inputText = chatValue.trim()) => {
    if (hasAccessError) {
      setLastError("No tienes acceso a ninguna colección. Contacta con el administrador.");
      return;
    }
    
    if (!inputText) return;

    if (!isGuest && username !== "admin") {
      try {
        const endpoints = await getEndpoints();
        const API_ASSOCIATION = apiEndpoints.API_ASSOCIATION || endpoints.API_ASSOCIATION;
                               
        const associationUrl = `${API_ASSOCIATION}?user_id=${username}`;

        const token = Cookies.get("accessToken");
        
        const associationResponse = await fetchWithAuth(associationUrl);

        if (!associationResponse.ok) {
          setMessage((prevMessages) => [
            ...prevMessages,
            { text: inputText, isBot: false },
            {
              text: "No tienes acceso para realizar consultas. Contacta con el administrador.",
              isBot: true,
              isError: true,
            },
          ]);
          setHasAccessError(true);
          return;
        }

        const associationData = await associationResponse.json();
        if (!associationData || !associationData.profile_id) {
          setMessage((prevMessages) => [
            ...prevMessages,
            { text: inputText, isBot: false },
            {
              text: "No tienes un perfil asociado. Contacta con el administrador para obtener acceso.",
              isBot: true,
              isError: true,
            },
          ]);
          setHasAccessError(true);
          return;
        }
      } catch (error) {
        console.error(
          "Error al verificar asociación antes de consulta:",
          error
        );
        setMessage((prevMessages) => [
          ...prevMessages,
          { text: inputText, isBot: false },
          {
            text: "Error al verificar tu acceso. Por favor, intenta nuevamente.",
            isBot: true,
            isError: true,
          },
        ]);
        return;
      }
    }

    setMessage((prevMessages) => [
      ...prevMessages,
      { text: inputText, isBot: false },
    ]);
    setChatValue("");

    setIsWaitingForResponse(true);
    setIsAwaitingNewResponse(true);

    let errorMessageSent = false;

    const errorTimeout = setTimeout(() => {
      if (
        !errorMessageSent &&
        (!ws.current || ws.current.readyState !== WebSocket.OPEN)
      ) {
        errorMessageSent = true;
        setMessage((prevMessages) => [
          ...prevMessages,
          {
            text: "Error de conexión. Por favor, intenta nuevamente.",
            isBot: true,
            isError: true,
          },
        ]);
        setIsWaitingForResponse(false);
        setIsAwaitingNewResponse(false);
      }
    }, 60000);

    if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
      try {
        await setupWebSocket();

        let attempts = 0;
        const maxAttempts = 50;

        while (
          (!ws.current || ws.current.readyState !== WebSocket.OPEN) &&
          attempts < maxAttempts
        ) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          attempts++;
        }

        if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
          return;
        }
      } catch (error) {
        console.error("Error al configurar WebSocket:", error);
        return;
      }
    }

    try {
      clearTimeout(errorTimeout);

      let messageData;

      if (isGuest) {
        const guestToken =
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJHVUVTVF9VU0VSIiwiY29nbml0bzpncm91cHMiOlsiR1VFU1RfR1JPVVAiXSwiaXNzIjoiZnJvbnQiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJ1c2VybmFtZSI6IkdVRVNUX1VTRVIifQ.PcqpR_wHkLVs1_hnnThLqPfyq9huDLAEPgOojKSVXJo";

        messageData = {
          query: inputText,
          collection: config?.DEFAULT_COLLECTION || "",
          Authorization: `Bearer ${guestToken}`,
          topic_id: currentTopic ? currentTopic.id || "" : "",
        };
      } else {
        const accessToken = Cookies.get("accessToken");

        messageData = {
          query: inputText,
          collection:
            selectedCollection || config?.DEFAULT_COLLECTION || "",
          Authorization: `Bearer ${accessToken}`,
          topic_id: currentTopic ? currentTopic.id : "",
        };
      }

      ws.current.send(JSON.stringify(messageData));
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);

      if (!errorMessageSent) {
        setTimeout(() => {
          if (!errorMessageSent) {
            errorMessageSent = true;
            setMessage((prevMessages) => [
              ...prevMessages,
              {
                text: "Error al enviar el mensaje. Por favor, intenta nuevamente.",
                isBot: true,
                isError: true,
              },
            ]);
            setIsWaitingForResponse(false);
            setIsAwaitingNewResponse(false);
          }
        }, 60000 - 5000);
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        e.preventDefault();
      } else {
        e.preventDefault();
        handleSend();
      }
    }
  };

  const loadChatHistoryForTopic = async (topicId) => {
    if (hasAccessError) {
      return;
    }
    
    setIsLoadingChat(true);
    try {
      closeWebSocket();
      await setupWebSocket();
      const history = await fetchChatHistory(topicId);
      setMessage(
        history.map((msg) => ({
          text: msg.content,
          isBot: msg.type !== "human",
        }))
      );
      setCurrentTopic({ id: topicId });
      setLastError(null);
    } catch (error) {
      console.error("Error al cargar el historial del chat:", error);
      setMessage([
        {
          text: "Error al cargar el historial del chat. Por favor, intenta nuevamente.",
          isBot: true,
          isError: true,
        },
      ]);
    } finally {
      setIsLoadingChat(false);
    }
  };

  const resetChat = () => {
    reconnectAttempts.current = 0;
  
    if (responseTimeout.current) {
      clearTimeout(responseTimeout.current);
      responseTimeout.current = null;
    }
  
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
    }
  
    setCurrentTopic(null);
    setMessage([]);
    setIsWaitingForResponse(false);
    setIsAwaitingNewResponse(false);
    setLastError(null);
    setHasAccessError(false);
  
    setTimeout(() => {
      setupWebSocket();
    }, 300);
  };
  
  useEffect(() => {
    const checkAndRefreshToken = async () => {
      if (!expirationDate) return;
      const now = new Date();
      const timeLeft = expirationDate.getTime() - now.getTime();

      if (timeLeft <= 0) {
        try {
          const data = await refreshToken();
          setIsLoggedIn(true);
          setIsGuest(false);
          setExpirationDate(new Date(Cookies.get("expirationDate")));
        } catch (error) {
          console.error("Error al refrescar el token:", error);
          Cookies.remove("accessToken");
          Cookies.remove("expirationDate");
          setIsLoggedIn(false);
          setIsGuest(true);
        }
      } else {
        const timer = setTimeout(checkAndRefreshToken, timeLeft - 60000);
        return () => clearTimeout(timer);
      }
    };

    checkAndRefreshToken();
  }, [expirationDate]);

  useEffect(() => {
    setIsLoggedIn(!!Cookies.get("accessToken"));
    setIsGuest(!Cookies.get("accessToken"));
  }, [Cookies.get("accessToken")]);

  useEffect(() => {
    if (isLoggedIn && !isGuest && username && username !== "admin" && !hasAccessError) {
      const checkAssociationStatus = async () => {
        try {
          const endpoints = await getEndpoints();
          const API_ASSOCIATION = apiEndpoints.API_ASSOCIATION || endpoints.API_ASSOCIATION;
                                 
          const associationUrl = `${API_ASSOCIATION}?user_id=${username}`;
  
          const token = Cookies.get("accessToken");
          if (!token) {
            return;
          }
          
          const associationResponse = await fetchWithAuth(associationUrl);
  
          if (!associationResponse.ok) {
            setLastError(
              "No tienes acceso a ninguna colección. Contacta con el administrador."
            );
            setCollections([]);
            setSelectedCollection("");
            localStorage.removeItem("selectedCollection");
            setHasAccessError(true);
            return [];
          }
  
          const associationData = await associationResponse.json();
          if (!associationData || !associationData.profile_id) {
            setLastError(
              "Tu perfil ha sido desasociado. Por favor, contacta al administrador."
            );
            setCollections([]);
            setSelectedCollection("");
            setHasAccessError(true);
  
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
              ws.current.close();
            }
          } else {
            if (lastError) {
              setLastError(null);
            }
          }
        } catch (error) {
          console.error("Error al verificar estado de asociación:", error);
        }
      };
  
      checkAssociationStatus();
  
      if (!hasAccessError) {
        const interval = setInterval(checkAssociationStatus, 60000);
        return () => clearInterval(interval);
      }
    }
  }, [isLoggedIn, isGuest, username, hasAccessError]);
  const deleteTopicFromList = async (topic) => {
    if (hasAccessError) {
      return;
    }
    
    setIsLoadingChat(true);
    try {
      await deleteTopic(topic);
      await loadTopics();

      if (currentTopic && currentTopic.id === topic) {
        resetChat();
      }
    } catch (error) {
      console.error("Error al eliminar el tópico:", error);
    } finally {
      setIsLoadingChat(false);
    }
  };

  return (
    <ContextApp.Provider
      value={{
        showSlide,
        setShowSlide,
        chatValue,
        setChatValue,
        handleSend,
        message,
        handleKeyPress,
        isWaitingForResponse,
        loadChatHistoryForTopic,
        currentTopic,
        topics,
        deleteTopicFromList,
        loadTopics,
        reloadTopicsTrigger,
        setReloadTopicsTrigger,
        resetChat,
        isLoggedIn,
        setIsLoggedIn,
        isGuest,
        setIsGuest,
        isAwaitingNewResponse,
        setIsAwaitingNewResponse,
        username,
        userRole,
        setUsername,
        collections,
        selectedCollection,
        setSelectedCollection,
        isCollectionSelectorOpen,
        setIsCollectionSelectorOpen,
        loadCollections,
        loadDocumentsForCollection,
        resetWebSocketConnection,
        isLoadingChat,
        setIsLoadingChat,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isChatOpened,
        setIsChatOpened,
        currentCollectionDocuments,
        lastError,
        setLastError,
        hasAccessError,       
        setHasAccessError       
      }}
    >
      {children}
    </ContextApp.Provider>
  );
};

export default AppContext;