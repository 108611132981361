import { createContext, useEffect, useState } from 'react';

let configurationPromise = null;

const getConfiguration = () => {
  if (!configurationPromise) {
    configurationPromise = fetch("/configuration.json")
      .then(response => response.json())
      .catch(error => {
        console.error("Error al cargar la configuración:", error);
        configurationPromise = null;
        return {};
      });
  }
  return configurationPromise;
};

export const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({
    LOGGING_LEVEL: "WARNING",
    TITLE: "CHAT",
    WHATSAPP_SHOW: true,
    VERSION: "last",
    DEFAULT_COLLECTION: "PUBLIC_COLLECTION",
    FOOTER: "Footer.",
    MENU_SERVICIO_FOOTER: "Service Footer.",
    MESSAGE_WAIT_TIME: 6000,
    BUTTON_WAIT_TIME: 30000
  });
  
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const configData = await getConfiguration();
        setConfig(prevConfig => ({...prevConfig, ...configData}));
        
        if (configData.TITLE) {
          document.title = configData.TITLE;
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config, isLoading }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;