import { createContext, useEffect, useState, useContext } from 'react';

export const ApiContext = createContext();

let globalApiContext = null;
let apiLoadPromise = null;

export const getEndpoints = async () => {
  if (globalApiContext && !globalApiContext.isLoading) {
    return globalApiContext.endpoints;
  }
  
  if (apiLoadPromise) {
    return apiLoadPromise;
  }
  
  apiLoadPromise = fetch("/api.json")
    .then(response => response.json())
    .catch(error => {
      console.error("Error al cargar los endpoints de la API:", error);
      return {}; 
    });
  
  return apiLoadPromise;
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi debe ser usado dentro de un ApiProvider');
  }
  return context;
};

const ApiProvider = ({ children }) => {
  const [endpoints, setEndpoints] = useState({
    API_FETCH_USER_DOCUMENTS: "",
    API_DELETE_FILES: "",
    API_UPLOAD_FILES: "",
    API_FETCH_CHAT_HISTORY: "",
    API_FETCH_TOPIC: "",
    API_DELETE_TOPIC: "",
    API_SERVICE_STATUS: "",
    API_RESTART_SERVICE: "",
    API_AUTH_CHALLENGE: "",
    API_LOGIN: "",
    API_REGISTER: "",
    API_USERGET: "",
    API_USERMOD: "",
    API_PROFILES: "",
    API_ASSOCIATION: "",
    API_GROUPS: "",
    API_QR_PNG: "",
    API_WHATSAPP_CONNECTED_NUMBER: "",
    API_WHATSAPP_LAST_CONNECTED_TIME: "",
    API_WHATSAPP_COOLDOWN: "",
    API_WHASTAPP_LAST_SPOKEN_NUMBER: "",
    API_WHATSAPP_MODE: "",
    API_WHATSAPP_STATUS: "",
    API_WHATSAPP_QR: "",
    API_WHATSAPP_LOGOUT: "",
    API_WHATSAPP_PAIRING_CODE_MODE: "",
    API_WHATSAPP_QR_CODE_MODE: "",
    API_WEBSOCKET: ""
  });
  
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (globalApiContext && !globalApiContext.isLoading) {
      setEndpoints(globalApiContext.endpoints);
      setIsLoading(false);
      return;
    }
    
    if (apiLoadPromise) {
      apiLoadPromise.then(data => {
        setEndpoints(prevEndpoints => ({...prevEndpoints, ...data}));
        setIsLoading(false);
      });
      return;
    }
    
    const loadEndpoints = async () => {
      try {
        const data = await getEndpoints();
        setEndpoints(prevEndpoints => ({...prevEndpoints, ...data}));
      } catch (error) {
        console.error("Error al cargar los endpoints:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadEndpoints();
  }, []);

  const contextValue = { endpoints, isLoading };
  globalApiContext = contextValue;

  return (
    <ApiContext.Provider value={contextValue}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;