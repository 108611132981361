import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";
import { Moon, Sun } from "lucide-react";

const ToggleButton = () => {
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);

  const handleToggle = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    document.body.style.background = newTheme
      ? "linear-gradient(to right, #171715, #2D2D29)"
      : "white";

    localStorage.setItem("theme", newTheme ? "dark" : "light");
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    const initialTheme = savedTheme === "dark";
    setIsDarkMode(initialTheme);
    document.body.style.background = initialTheme
      ? "linear-gradient(to right, #171715, #2D2D29)"
      : "white";
  }, [setIsDarkMode]);

  return (
    <button
      className={`flex items-center w-20 h-10 rounded-full transition-colors border-2 ${
        isDarkMode
          ? "bg-black border-gray-500"
          : "bg-green-500 border-green-500"
      }`}
      onClick={handleToggle}
    >
      <div
        className={`w-8 h-8 bg-white rounded-full shadow-md transition-transform transform ${
          isDarkMode ? "translate-x-10" : "translate-x-0"
        } flex items-center justify-center`}
      >
        {isDarkMode ? (
          <Moon className="w-6 h-6 text-gray-800" />
        ) : (
          <Sun className="w-6 h-6 text-green-500" />
        )}
      </div>
    </button>
  );
};

export default ToggleButton;