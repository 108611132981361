import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { useTheme } from "../ThemeContext";

interface CustomTextFieldProps extends Omit<TextFieldProps, "variant"> {
  isFullWidth?: boolean;
  label?: string;
  multiline?: boolean;
  rows?: number;
}

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  isFullWidth = false,
  label,
  multiline = false,
  rows = 3,
  sx,
  ...props
}) => {
  const { isDarkMode } = useTheme();

  const baseStyles = {
    "& .MuiInputBase-root": {
      height: multiline ? "auto" : "40px",
      backgroundColor: isDarkMode ? "transparent" : "#FFFFFF",
    },
    "& .MuiInputBase-root .MuiInputBase-input": {
      height: multiline ? "auto" : "40px",
      padding: multiline ? "8px 14px" : "0 14px",
      minHeight: multiline ? "auto" : "auto",
      lineHeight: "1.5",
    },
    "& .MuiInputBase-multiline": {
      padding: "0",
    },
    "& .MuiOutlinedInput-root": {
      padding: multiline ? "0" : undefined,
      "& fieldset": {
        borderColor: isDarkMode ? "#22C55E" : "#16A34A",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: isDarkMode ? "#4ADE80" : "#22C55E",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: isDarkMode ? "#4ADE80" : "#22C55E",
        borderWidth: "2px",
      },
      "&:hover": {
        backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.05)" : "#F9FAFB",
      },
      "&.Mui-focused": {
        backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.05)" : "#FFFFFF",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: isDarkMode ? "#9CA3AF" : "#6B7280",
      opacity: 1,
    },
  };

  const widthStyles = isFullWidth
    ? {
        width: "100%",
        "& .MuiInputBase-root": {
          width: "100%",
        },
      }
    : {
        flex: 1,
        maxWidth: "70%",
      };

  return (
    <div className="flex flex-col gap-2 w-full">
      {label && (
        <label className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
          {label}
        </label>
      )}
      <TextField
        {...props}
        variant="outlined"
        multiline={multiline}
        rows={multiline ? rows : undefined}
        sx={{
          ...baseStyles,
          ...widthStyles,
          ...sx,
        }}
        InputProps={{
          ...props.InputProps,
          style: {
            color: isDarkMode ? "#FFFFFF" : "#000000",
          },
        }}
      />
    </div>
  );
};