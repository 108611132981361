import Cookies from "js-cookie";
import { getEndpoints } from "../utils/ApiContext";

const fetchWithTimeout = async (url, options, timeoutMs = 5000) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeoutMs);
  
  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(timeoutId);
    return response;
  } catch (error) {
    clearTimeout(timeoutId);
    if (error.name === "AbortError") {
      throw new Error("La solicitud ha excedido el tiempo de espera");
    }
    throw error;
  }
};

const fetchWithRetry = async (apiCall, maxRetries = 3) => {
  let retries = 0;
  
  while (retries < maxRetries) {
    try {
      return await apiCall();
    } catch (error) {
      retries++;
      if (retries >= maxRetries) throw error;
      
      const delay = Math.pow(2, retries) * 1000;
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
};

async function getConfigUrl(endpoint) {
  try {
    const cache = sessionStorage.getItem('api_config');
    if (cache) {
      const data = JSON.parse(cache);
      return data[endpoint];
    }
    
    const endpoints = await getEndpoints();
    sessionStorage.setItem('api_config', JSON.stringify(endpoints));
    return endpoints[endpoint];
  } catch (error) {
    console.error(`Error al obtener URL para ${endpoint}:`, error);
    throw error;
  }
}

async function makeApiRequest(urlGetter, method = "GET", body = null) {
  const token = Cookies.get("accessToken");
  if (!token) {
    throw new Error("No se encontró el token de acceso");
  }
  
  const API_URL = await urlGetter();
  
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body ? JSON.stringify(body) : undefined
  };
  
  return fetchWithRetry(async () => {
    const response = await fetchWithTimeout(API_URL, options);
    
    if (!response.ok) {
      throw new Error(`Error en solicitud ${method} a ${API_URL}: ${response.status}`);
    }
    
    if (method === "DELETE" && response.status === 204) {
      return { success: true };
    }
    
    if (response.headers.get("content-type")?.includes("application/json")) {
      try {
        return await response.json();
      } catch (error) {
        throw new Error(`Error al parsear JSON: ${error.message}`);
      }
    } else if (response.headers.get("content-type")?.includes("image")) {
      return await response.blob();
    } else {
      return await response.text();
    }
  });
}

export const fetchConnectedNumber = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_CONNECTED_NUMBER"));
  } catch (error) {
    console.error("Error al obtener el número conectado:", error);
    throw error;
  }
};

export const fetchLastConnectedTime = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_LAST_CONNECTED_TIME"));
  } catch (error) {
    console.error("Error al obtener el último tiempo de conexión:", error);
    throw error;
  }
};

export const fetchCooldown = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_COOLDOWN"));
  } catch (error) {
    console.error("Error al obtener el mensaje de cooldown:", error);
    throw error;
  }
};

export const fetchLastSpokenNumber = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_LAST_SPOKEN_NUMBER"));
  } catch (error) {
    console.error("Error al obtener el último número hablado:", error);
    throw error;
  }
};

export const fetchMode = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_MODE"));
  } catch (error) {
    console.error("Error al obtener el modo:", error);
    throw error;
  }
};

export const fetchStatus = async () => {
  try {
    const result = await makeApiRequest(() => getConfigUrl("API_WHATSAPP_STATUS"));
    return result;
  } catch (error) {
    console.error("Error al obtener el estado de conexión:", error);
    throw error;
  }
};

export const fetchQr = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_QR"));
  } catch (error) {
    console.error("Error al obtener el código QR:", error);
    throw error;
  }
};

export const postLogout = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_LOGOUT"), "DELETE");
  } catch (error) {
    console.error("Error al hacer logout:", error);
    throw error;
  }
};

export const postPairingCodeMode = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_PAIRING_CODE_MODE"), "POST");
  } catch (error) {
    console.error("Error al cambiar al modo de código de emparejamiento:", error);
    throw error;
  }
};

export const postQrCodeMode = async () => {
  try {
    return await makeApiRequest(() => getConfigUrl("API_WHATSAPP_QR_CODE_MODE"), "POST");
  } catch (error) {
    console.error("Error al cambiar al modo de código QR:", error);
    throw error;
  }
};

export const postRestartService = async (serviceName) => {
  try {
    return await makeApiRequest(
      () => getConfigUrl("API_RESTART_SERVICE"), 
      "POST", 
      { service_name: serviceName }
    );
  } catch (error) {
    console.error("Error al reiniciar el servicio:", error);
    throw error;
  }
};