import fetchWithAuth from "../utils/fetchWithAuth";
import { getEndpoints } from "../utils/ApiContext"

export const fetchTopics = async () => {
  try {
    const endpoints = await getEndpoints();
    const API_URL = endpoints.API_FETCH_TOPIC;
    
    const response = await fetchWithAuth(API_URL);

    if (!response.ok) {
      throw new Error("Error al cargar los tópicos de chat");
    }

    const topics = await response.json();
    return topics.map(topic => ({
      id: topic.TOPIC_ID,
      name: topic.TOPIC_NAME,
    }));
  } catch (error) {
    console.error("Error al intentar cargar los tópicos de chat:", error);
    throw error;
  }
};

export const deleteTopic = async (topic) => {
  try {
    const endpoints = await getEndpoints();
    const API_BASE_URL = endpoints.API_DELETE_TOPIC;
    const API_URL = `${API_BASE_URL}?topic=${encodeURIComponent(topic)}`;
    
    const response = await fetchWithAuth(API_URL, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Error al eliminar el tópico');
    }

    return response;
  } catch (error) {
    console.error('Error al intentar eliminar el tópico:', error);
    throw error;
  }
};