import fetchWithAuth from "../utils/fetchWithAuth";
import { getEndpoints } from "../utils/ApiContext";

export async function getFetchBaseUrl() {
  try {
    return await getEndpoints();
  } catch (error) {
    console.error("Error al obtener la configuración de la API:", error);
    throw error;
  }
}

export const getUsers = async () => {
  try {
    const endpoints = await getEndpoints();
    const API_USERGET = endpoints.API_USERGET;
    if (!API_USERGET) throw new Error("URL de API para usuarios no disponible");
    
    const response = await fetchWithAuth(API_USERGET);

    if (!response.ok) throw new Error("Error al obtener usuarios");
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getProfiles = async () => {
  try {
    const endpoints = await getEndpoints();
    const API_PROFILES = endpoints.API_PROFILES;
    if (!API_PROFILES) throw new Error("URL de API para perfiles no disponible");
    
    const response = await fetchWithAuth(API_PROFILES);

    if (!response.ok) throw new Error("Error al obtener perfiles");
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const endpoints = await getEndpoints();
    const API_REGISTER = endpoints.API_REGISTER;
    if (!API_REGISTER) throw new Error("URL de API para registro de usuarios no disponible");
    
    const response = await fetchWithAuth(API_REGISTER, {
      method: "POST",
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', errorText);
      throw new Error(`Error al crear usuario: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error detallado:", error);
    throw error;
  }
};

export const updateUserApi = async (userData) => {
  try {
    const endpoints = await getEndpoints();
    const API_USERGET = endpoints.API_USERGET;
    if (!API_USERGET) throw new Error("URL de API para usuarios no disponible");
    
    const updateUrl = `${API_USERGET}?username=${userData.username}`;
    
    const requestBody = {
      attributes: {
        email: userData.email
      }
    };
    
    if (userData.groups) {
      requestBody.groups = userData.groups;
    }
    
    const response = await fetchWithAuth(updateUrl, {
      method: 'PUT',
      body: JSON.stringify(requestBody)
    });
    
    if (!response.ok) {
      try {
        const errorJson = await response.json();
        console.error('Error del servidor (JSON):', errorJson);
        throw new Error(`Error ${response.status}: ${errorJson.message || errorJson.error || 'Error del servidor'}`);
      } catch (jsonError) {
        const errorText = await response.text();
        console.error('Error del servidor (texto):', response.status, errorText);
        throw new Error(`Error ${response.status}: ${response.statusText || 'Error del servidor'}`);
      }
    }

    try {
      return await response.json();
    } catch (e) {
      return { success: true };
    }
  } catch (error) {
    console.error('Error en updateUserApi:', error);
    throw error;
  }
};

export const deleteUserApi = async (username) => {
  try {
    const endpoints = await getEndpoints();
    const API_USERGET = endpoints.API_USERGET;
    if (!API_USERGET) throw new Error("URL de API para usuarios no disponible");

    const response = await fetchWithAuth(`${API_USERGET}?username=${username}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', errorText);
      throw new Error(`Error al eliminar usuario: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error detallado:', error);
    throw error;
  }
};

export const getGroups = async () => {
  try {
    const endpoints = await getEndpoints();
    const API_GROUPS = endpoints.API_GROUPS;
    if (!API_GROUPS) throw new Error("URL de API para grupos no disponible");
    
    const response = await fetchWithAuth(API_GROUPS);

    if (!response.ok) throw new Error("Error al obtener grupos");
    const groups = await response.json();
    const groupsWithUsers = await Promise.all(
      groups.map(async (group) => {
        try {
          const usersResponse = await fetchWithAuth(`${API_GROUPS}?groupname=${group.groupname}`);
          
          if (usersResponse.ok) {
            const groupData = await usersResponse.json();
            return {
              ...group,
              users: groupData.users || []
            };
          }
          return { ...group, users: [] };
        } catch (error) {
          console.error(`Error al obtener usuarios del grupo ${group.groupname}:`, error);
          return { ...group, users: [] };
        }
      })
    );

    return groupsWithUsers;
  } catch (error) {
    console.error("Error detallado en getGroups:", error);
    throw error;
  }
};

export const createGroup = async (groupData) => {
  try {
    const endpoints = await getEndpoints();
    const API_GROUPS = endpoints.API_GROUPS;
    if (!API_GROUPS) throw new Error("URL de API para grupos no disponible");
    
    const response = await fetchWithAuth(API_GROUPS, {
      method: "POST",
      body: JSON.stringify(groupData)
    });
    
    const responseText = await response.text();
    let responseData;
    
    try {
      responseData = JSON.parse(responseText);
    } catch (e) {
      responseData = { message: responseText };
    }
    
    if (!response.ok) {
      const checkGroupExists = async () => {
        try {
          const groupsResponse = await fetchWithAuth(API_GROUPS);
          
          if (groupsResponse.ok) {
            const groups = await groupsResponse.json();
            return groups.some(group => group.groupname === groupData.groupname);
          }
          return false;
        } catch (e) {
          return false;
        }
      };
      
      if (await checkGroupExists()) {
        return { success: true };
      }
      
      throw new Error(`Error al crear grupo: ${response.status} ${responseData.message || response.statusText}`);
    }
    
    return responseData;
  } catch (error) {
    console.error("Error detallado:", error);
    throw error;
  }
};

export const deleteGroup = async (groupname) => {
  try {
    const endpoints = await getEndpoints();
    const API_GROUPS = endpoints.API_GROUPS;
    if (!API_GROUPS) throw new Error("URL de API para grupos no disponible");
    
    const response = await fetchWithAuth(`${API_GROUPS}?groupname=${groupname}`, {
      method: "DELETE"
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', errorText);
      throw new Error(`Error al eliminar grupo: ${response.status} ${response.statusText}`);
    }
    return true;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateGroup = async (groupData) => {
  try {
    const endpoints = await getEndpoints();
    const API_GROUPS = endpoints.API_GROUPS;
    if (!API_GROUPS) throw new Error("URL de API para grupos no disponible");
    
    const response = await fetchWithAuth(`${API_GROUPS}?groupname=${encodeURIComponent(groupData.groupname)}`, {
      method: "PUT",
      body: JSON.stringify({
        description: groupData.description
      })
    });

    let errorText = "";
    try {
      errorText = await response.text();
    } catch (e) {
      errorText = "No se pudo obtener detalles del error";
    }

    if (!response.ok) {
      console.error('API Error Response:', errorText);
      throw new Error(`Error al actualizar grupo (${response.status}): ${errorText || response.statusText}`);
    }

    try {
      return await response.json();
    } catch (e) {
      if (response.ok) {
        return { success: true };
      }
      throw new Error(`Error al procesar respuesta: ${errorText}`);
    }
  } catch (error) {
    console.error("Error detallado:", error);
    throw error;
  }
};

export const createAssociation = async (associationData) => {
  try {
    const endpoints = await getEndpoints();
    const API_ASSOCIATION = endpoints.API_ASSOCIATION;
    if (!API_ASSOCIATION) throw new Error("URL de API para asociaciones no disponible");
        
    const response = await fetchWithAuth(API_ASSOCIATION, {
      method: "POST",
      body: JSON.stringify(associationData)
    });

    if (!response.ok) {
      let errorMessage = '';
      try {
        const errorData = await response.json();
        errorMessage = errorData.error_message || errorData.message || '';
      } catch (e) {
        errorMessage = await response.text();
      }
      
      if (response.status === 409) {
        throw new Error(`AlreadyAssociatedError: ${errorMessage || 'El usuario ya está asociado a un perfil'}`);
      } else {
        throw new Error(`Error al crear asociación (${response.status}): ${errorMessage || response.statusText}`);
      }
    }
    
    return await response.json();
  } catch (error) {
    console.error("Error detallado:", error);
    throw error;
  }
};

export const verifyAssociation = async (userId) => {
  try {
    const endpoints = await getEndpoints();
    const API_ASSOCIATION = endpoints.API_ASSOCIATION;
    if (!API_ASSOCIATION) throw new Error("URL de API para asociaciones no disponible");
    
    const url = `${API_ASSOCIATION}?user_id=${userId}`;
    
    const response = await fetchWithAuth(url);
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error en respuesta de verificación:", errorText);
      throw new Error(`Error al verificar asociación: ${response.status} ${response.statusText}`);
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error detallado en verifyAssociation:", error);
    throw error;
  }
};

export const deleteAssociation = async (userId) => {
  try {
    const endpoints = await getEndpoints();
    const API_ASSOCIATION = endpoints.API_ASSOCIATION;
    if (!API_ASSOCIATION) throw new Error("URL de API para asociaciones no disponible");
        
    const response = await fetchWithAuth(`${API_ASSOCIATION}?user_id=${userId}`, {
      method: "DELETE"
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error al eliminar asociación:", errorText);
      throw new Error(`Error al eliminar asociación: ${response.status} ${errorText || response.statusText}`);
    }
    return true;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};