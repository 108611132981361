import React, { forwardRef, ReactNode, useContext } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, IconButton } from "@mui/material";
import { X } from "lucide-react";
import { ThemeContext } from "../ThemeContext";

interface BaseModalProps {
  title: string;
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullWidth?: boolean;
  dialogProps?: {
    PaperProps?: {
      sx?: Record<string, any>;
    };
    [key: string]: any;
  };
  customStyles?: Record<string, any>;
}

interface StyledProps {
  $isDark?: boolean;
}

const StyledDialog = styled(Dialog)<StyledProps>(({ $isDark }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: $isDark ? "#2D2D2D" : "#FFFFFF",
    color: $isDark ? "#FFFFFF" : "#1F2937",
    border: `2px solid ${$isDark ? "#1BCF66" : "#16A34A"}`,
    borderRadius: 16,
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)<StyledProps>(
  ({ theme, $isDark }) => ({
    backgroundColor: $isDark ? "#2D2D2D" : "#FFFFFF",
    color: $isDark ? "#1BCF66" : "#16A34A",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  })
);

const StyledDialogContent = styled(DialogContent)<StyledProps>(
  ({ theme, $isDark }) => ({
    backgroundColor: $isDark ? "#2D2D2D" : "#FFFFFF",
    color: $isDark ? "#FFFFFF" : "#1F2937",
    padding: theme.spacing(2),

    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& > *": {
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: $isDark ? "rgb(70, 70, 70)" : "rgb(209, 213, 219)",
        borderRadius: "8px",
      },
      scrollbarWidth: "thin",
      scrollbarColor: $isDark
        ? "rgb(70, 70, 70) transparent"
        : "rgb(209, 213, 219) transparent",
    },
  })
);

const StyledDialogActions = styled(DialogActions)<StyledProps>(
  ({ theme, $isDark }) => ({
    backgroundColor: $isDark ? "#2D2D2D" : "#FFFFFF",
    padding: theme.spacing(2),
  })
);

const CloseButton = styled(IconButton)<StyledProps>(({ $isDark }) => ({
  color: $isDark ? "#999999" : "#6B7280",
  padding: 8,
  "&:hover": {
    color: $isDark ? "#FFFFFF" : "#1F2937",
    backgroundColor: $isDark
      ? "rgba(153, 153, 153, 0.1)"
      : "rgba(107, 114, 128, 0.1)",
  },
}));

export const BaseModal = forwardRef<HTMLDivElement, BaseModalProps>(
  (
    {
      title,
      children,
      onClose,
      isOpen,
      maxWidth = "sm",
      fullWidth = true,
      dialogProps = {},
      customStyles = {},
    },
    ref
  ) => {
    const { isDarkMode } = useContext(ThemeContext);

    return (
      <StyledDialog
        ref={ref}
        open={Boolean(isOpen)}
        onClose={onClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        $isDark={isDarkMode}
        {...dialogProps}
        PaperProps={{
          ...dialogProps.PaperProps,
          sx: {
            ...(dialogProps.PaperProps?.sx ?? {}),
            ...customStyles,
          },
        }}
      >
        <StyledDialogTitle $isDark={isDarkMode}>
          <Box component="span">{title}</Box>
          <CloseButton onClick={onClose} size="small" $isDark={isDarkMode}>
            <X size={20} />
          </CloseButton>
        </StyledDialogTitle>
        <StyledDialogContent $isDark={isDarkMode}>
          {children}
        </StyledDialogContent>
        <StyledDialogActions $isDark={isDarkMode}></StyledDialogActions>
      </StyledDialog>
    );
  }
);

BaseModal.displayName = "BaseModal";
