import React, { useState, useEffect, useCallback, useContext } from "react";
import { BaseModal } from "../ui/BaseModal.tsx";
import { FileList } from "../ui/FileList.tsx";
import { ActionButton } from "../ui/ActionButton.tsx";
import { Box, Button, Typography, Divider } from "@mui/material";
import { Plus, Trash, ArrowLeft, Upload, FolderPlus, RefreshCw, } from "lucide-react";
import { useDropzone } from "react-dropzone";
import { CustomToggleTabs } from "../ui/CustomToggleTabs.tsx";
import { useTheme } from "../ThemeContext";
import { getUserDocuments, uploadMultipleFiles, deleteFile } from "../../services/uploadFilesApi.jsx";
import { CustomAlert } from "../ui/CustomAlert.tsx";
import { CustomTextField } from "../ui/CustomTextField.tsx";
import { List, Grid } from "lucide-react";
import { ConfigContext } from "../../utils/Config.jsx";

interface Collection {
  files?: any[];
  [key: string]: any;
}

interface FileManagementModalProps {
  onClose: () => void;
  isOpen: boolean;
}


export default function FileManagementModal({
  onClose,
  isOpen,
}: FileManagementModalProps) {
  const { isDarkMode } = useTheme();
  const [collections, setCollections] = useState<{ [key: string]: Collection }>(
    {}
  );
  const [newCollectionName, setNewCollectionName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [newCollectionFiles, setNewCollectionFiles] = useState<File[]>([]);
  const [viewMode, setViewMode] = useState<"list" | "grid">("list");
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [defaultCollection, setDefaultCollection] = useState("");
  const [textConfig, setTextConfig] = useState<any>({});
  const [isCreatingCollection, setIsCreatingCollection] = useState(false);
  const [showInlineSuccessMessage, setShowInlineSuccessMessage] =
    useState(false);
  const [showFileUploadSuccessMessage, setShowFileUploadSuccessMessage] =
    useState(false);
    
  const [alertConfig, setAlertConfig] = useState<{
    open: boolean;
    type: "loading" | "success" | "error" | "confirm";
    message: string;
    title?: string;
    onConfirm?: () => Promise<void>;
  }>({
    open: false,
    type: "loading",
    message: "",
    title: "",
    onConfirm: undefined,
  });
  const handleCloseAlert = useCallback(() => {
    setAlertConfig((prev) => ({ ...prev, open: false }));
  }, []);

  const getCurrentDirectory = useCallback(() => {
    let current = collections;
    for (const folder of currentPath) {
      if (current[folder]) {
        current = current[folder];
      } else {
        return { files: [] };
      }
    }
    return current;
  }, [collections, currentPath]);

  const { config } = useContext(ConfigContext);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const handleManualRefresh = async () => {
    setIsRefreshing(true);
    try {
      await fetchUserDocuments();
    } catch (error) {
      console.error("Error al refrescar documentos:", error);
    } finally {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 500);
    }
  };
  const fetchUserDocuments = useCallback(async () => {
    if (!isOpen) return;

    setIsLoading(true);
    setAlertConfig({
      open: true,
      type: "loading",
      message: "Cargando documentos...",
    });

    try {
      const documents = await getUserDocuments();

      if (!Array.isArray(documents)) {
        setAlertConfig({
          open: true,
          type: "error",
          message: "Formato de respuesta inválido",
        });
        setIsLoading(false);
        return;
      }

      const groupedDocuments: { [key: string]: Collection } = {};

      const ensureFolderExists = (folderPath: string[], docObj: any = null) => {
        let currentLevel = groupedDocuments;

        for (let i = 0; i < folderPath.length; i++) {
          const folderName = folderPath[i].trim();
          if (!folderName) continue;

          if (!currentLevel[folderName]) {
            currentLevel[folderName] = { files: [] };
          }
          currentLevel = currentLevel[folderName];
        }

        if (docObj && currentLevel.files) {
          currentLevel.files.push(docObj);
        }

        return currentLevel;
      };

      documents.forEach((doc: any) => {
        const filePath = doc.FILE_NAME || "";
        const decodedPath = decodeURIComponent(filePath);

        const paths = decodedPath.split("/").filter((p) => p.trim() !== "");

        if (paths.length === 0) return;

        const isFolder = paths[paths.length - 1] === ".folder";

        if (isFolder) {
          ensureFolderExists(paths.slice(0, -1));
        } else {
          const fileName = paths[paths.length - 1];
          const folderPaths = paths.slice(0, -1);

          ensureFolderExists(folderPaths, doc);
        }
      });

      if (!groupedDocuments[defaultCollection]) {
        groupedDocuments[defaultCollection] = { files: [] };
      }

      setCollections(groupedDocuments);
      handleCloseAlert();
    } catch (error) {
      console.error("Error al cargar documentos:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message:
          textConfig.FILE_MANAGEMENT_ERROR_UPLOAD_FILE ||
          "Error al cargar los documentos",
      });
    } finally {
      setIsLoading(false);
    }
  }, [defaultCollection, handleCloseAlert, textConfig, isOpen]);
  const fetchInitialData = async () => {
    if (!isOpen) return;
  
    setDefaultCollection(config.DEFAULT_COLLECTION);
      try {
        const textResponse = await fetch("/text.json");
        if (textResponse.ok) {
          const textData = await textResponse.json();
          setTextConfig(textData);
        } else {
          setTextConfig({});
        }
      } catch (textError) {
        console.error("Error al cargar textos:", textError);
        setTextConfig({});
      }
  };
  useEffect(() => {
    setShowFileUploadSuccessMessage(false);
  }, [currentPath]);

  const handleClose = () => {
    setShowFileUploadSuccessMessage(false);
    onClose();
  };
  useEffect(() => {
    if (isOpen) {
      fetchInitialData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultCollection && isOpen) {
      fetchUserDocuments();
    }
  }, [defaultCollection, fetchUserDocuments, isOpen]);

  useEffect(() => {
    setSelectedFiles([]);
  }, [currentPath]);

  useEffect(() => {
    if (isOpen && defaultCollection && currentPath.length > 0) {
      fetchUserDocuments();
    }
  }, [fetchUserDocuments, isOpen, defaultCollection, currentPath]);
  const validateFile = (file: File) => {
    const validTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];

    const maxSize = 10 * 1024 * 1024;

    if (!validTypes.includes(file.type)) {
      return {
        valid: false,
        reason: "type",
      };
    }

    if (file.size > maxSize) {
      return {
        valid: false,
        reason: "size",
      };
    }

    return {
      valid: true,
    };
  };

  const handleFileSelection = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidTypes: File[] = [];
    const invalidSizes: File[] = [];

    files.forEach((file) => {
      const validation = validateFile(file);
      if (validation.valid) {
        validFiles.push(file);
      } else if (validation.reason === "type") {
        invalidTypes.push(file);
      } else if (validation.reason === "size") {
        invalidSizes.push(file);
      }
    });

    if (invalidTypes.length > 0 || invalidSizes.length > 0) {
      let message = "";

      if (invalidTypes.length > 0) {
        const fileNames = invalidTypes.map((f) => f.name).join(", ");
        message += `Los siguientes archivos no tienen un formato permitido: ${fileNames}. Solo se permiten PDF, Word y TXT.\n`;
      }

      if (invalidSizes.length > 0) {
        const fileNames = invalidSizes.map((f) => f.name).join(", ");
        message += `Los siguientes archivos exceden el tamaño máximo de 10MB: ${fileNames}`;
      }

      setAlertConfig({
        open: true,
        type: "error",
        message: message.trim(),
      });
    }

    setSelectedFiles((prev) => [...prev, ...validFiles]);
  };

  const handleNewCollectionFileSelection = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidTypes: File[] = [];
    const invalidSizes: File[] = [];

    files.forEach((file) => {
      const validation = validateFile(file);
      if (validation.valid) {
        validFiles.push(file);
      } else if (validation.reason === "type") {
        invalidTypes.push(file);
      } else if (validation.reason === "size") {
        invalidSizes.push(file);
      }
    });

    if (invalidTypes.length > 0 || invalidSizes.length > 0) {
      let message = "";

      if (invalidTypes.length > 0) {
        const fileNames = invalidTypes.map((f) => f.name).join(", ");
        message += `Los siguientes archivos no tienen un formato permitido: ${fileNames}. Solo se permiten PDF, Word y TXT.\n`;
      }

      if (invalidSizes.length > 0) {
        const fileNames = invalidSizes.map((f) => f.name).join(", ");
        message += `Los siguientes archivos exceden el tamaño máximo de 10MB: ${fileNames}`;
      }

      setAlertConfig({
        open: true,
        type: "error",
        message: message.trim(),
      });
    }

    setNewCollectionFiles((prev) => [...prev, ...validFiles]);
  };
  const handleCreateCollection = async () => {
    setShowInlineSuccessMessage(false);

    if (newCollectionName.trim() === "") {
      setAlertConfig({
        open: true,
        type: "error",
        message:
          textConfig.FILE_MANAGEMENT_COLLECTION_ERROR_EMPTY ||
          "El nombre de la colección no puede estar vacío",
      });
      return;
    }

    if (newCollectionName in collections) {
      setAlertConfig({
        open: true,
        type: "error",
        message: `Ya existe una colección con el nombre "${newCollectionName}"`,
      });
      return;
    }

    const validName = /^[a-zA-Z0-9_\-\s]+$/;
    if (!validName.test(newCollectionName)) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "El nombre no puede contener caracteres especiales ni barras",
      });
      return;
    }

    if (newCollectionFiles.length === 0) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "Debe cargar al menos un archivo para crear una colección",
      });
      return;
    }

    setIsCreatingCollection(true);
    setAlertConfig({
      open: true,
      type: "loading",
      message: "Creando colección...",
    });

    try {
      const filesWithPath = newCollectionFiles.map((file) => {
        return new File([file], file.name, { type: file.type });
      });

      const folderMarker = new File([""], ".folder", { type: "text/plain" });
      filesWithPath.push(folderMarker);

      const result = await uploadMultipleFiles(
        filesWithPath,
        newCollectionName
      );

      const updatedCollections = { ...collections };
      if (currentPath.length === 0) {
        updatedCollections[newCollectionName] = {
          files: newCollectionFiles.map((file) => ({
            FILE_NAME: `${newCollectionName}/${file.name}`,
          })),
        };
      } else {
        let current = updatedCollections;

        for (let i = 0; i < currentPath.length; i++) {
          const folder = currentPath[i];
          if (!current[folder]) {
            current[folder] = { files: [] };
          }
          current = current[folder];
        }

        current[newCollectionName] = { files: [] };
      }

      setCollections(updatedCollections);
      setNewCollectionName("");
      setNewCollectionFiles([]);

      await fetchUserDocuments();

      setAlertConfig({
        open: true,
        type: "success",
        message: "¡Colección creada con éxito!",
        onConfirm: async () => {
          setAlertConfig((prev) => ({ ...prev, open: false }));
          await fetchUserDocuments();
        },
      });

      setShowInlineSuccessMessage(true);
    } catch (error: any) {
      console.error("Error al crear colección:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: `Error al crear colección: ${error.message}`,
        onConfirm: async () => {
          setAlertConfig((prev) => ({ ...prev, open: false }));
        },
      });
    } finally {
      setIsCreatingCollection(false);
    }
  };

  const handleCreateFolder = async () => {
    if (newCollectionName.trim() === "") {
      setAlertConfig({
        open: true,
        type: "error",
        message: "El nombre de la carpeta no puede estar vacío",
      });
      return;
    }

    const validName = /^[a-zA-Z0-9_\-\s]+$/;
    if (!validName.test(newCollectionName)) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "El nombre no puede contener caracteres especiales ni barras",
      });
      return;
    }

    let current = collections;
    let isDuplicate = false;

    if (currentPath.length === 0) {
      isDuplicate = newCollectionName in collections;
    } else {
      for (const folder of currentPath) {
        if (current[folder]) {
          current = current[folder];
        } else {
          break;
        }
      }

      isDuplicate = newCollectionName in current;
    }

    if (isDuplicate) {
      setAlertConfig({
        open: true,
        type: "error",
        message: `Ya existe una carpeta con el nombre "${newCollectionName}" en esta ubicación`,
      });
      return;
    }

    setAlertConfig({
      open: true,
      type: "loading",
      message: "Creando carpeta...",
    });

    try {
      const emptyFile = new File([""], ".folder", { type: "text/plain" });

      let folderFullPath = "";

      if (currentPath.length === 0) {
        folderFullPath = newCollectionName;
      } else {
        folderFullPath = [...currentPath, newCollectionName].join("/");
      }

      const result = await uploadMultipleFiles([emptyFile], folderFullPath);

      const updatedCollections = { ...collections };
      if (currentPath.length === 0) {
        updatedCollections[newCollectionName] = { files: [] };
      } else {
        let current = updatedCollections;

        for (let i = 0; i < currentPath.length; i++) {
          const folder = currentPath[i];
          if (!current[folder]) {
            current[folder] = { files: [] };
          }
          current = current[folder];
        }

        current[newCollectionName] = { files: [] };
      }

      setCollections(updatedCollections);
      setNewCollectionName("");

      await fetchUserDocuments();

      setAlertConfig({
        open: true,
        type: "success",
        message: "Carpeta creada exitosamente",
        onConfirm: async () => {
          setAlertConfig((prev) => ({ ...prev, open: false }));
          await fetchUserDocuments();
        },
      });
    } catch (error: any) {
      console.error("Error al crear carpeta:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message: `Error al crear carpeta: ${error.message}`,
        onConfirm: async () => {
          setAlertConfig((prev) => ({ ...prev, open: false }));
        },
      });
    }
  };
  const handleUploadFiles = async () => {
    setShowFileUploadSuccessMessage(false);

    if (selectedFiles.length === 0) {
      setAlertConfig({
        open: true,
        type: "error",
        message: "No hay archivos seleccionados para subir",
      });
      return;
    }

    setIsUploading(true);
    setAlertConfig({
      open: true,
      type: "loading",
      message: "Subiendo archivos...",
    });

    try {
      let folderPath = "";

      if (currentPath.length > 0) {
        folderPath = currentPath.join("/");
      } else if (defaultCollection) {
        folderPath = defaultCollection;
      }

      const filesWithPath = selectedFiles.map((file) => {
        return new File([file], file.name, { type: file.type });
      });

      const result = await uploadMultipleFiles(
        filesWithPath,
        folderPath || defaultCollection
      );

      const fileNames = selectedFiles.map((file) => file.name).join(", ");

      setSelectedFiles([]);

      const currentFolderPath = [...currentPath];

      await fetchUserDocuments();

      setCurrentPath(currentFolderPath);

      let successMessage;
      if (selectedFiles.length === 1) {
        successMessage = `Archivo "${fileNames}" subido exitosamente`;
      } else {
        successMessage = `${selectedFiles.length} archivos subidos exitosamente`;
      }

      setAlertConfig({
        open: true,
        type: "success",
        message: successMessage,
        onConfirm: async () => {
          setAlertConfig((prev) => ({ ...prev, open: false }));
        },
      });

      setShowFileUploadSuccessMessage(true);
    } catch (error: any) {
      console.error("Error en handleUploadFiles:", error);
      setAlertConfig({
        open: true,
        type: "error",
        message:
          error.message ||
          textConfig.FILE_MANAGEMENT_ERROR_UPLOAD_FILE ||
          "Error al subir los archivos",
      });
    } finally {
      setIsUploading(false);
    }
  };
  const handleDeleteFile = async (fileHash: string, collectionName: string) => {
    setAlertConfig({
      open: true,
      type: "confirm",
      title: "¿Eliminar archivo?",
      message: "¿Está seguro que desea eliminar este archivo?",
      onConfirm: async () => {
        setAlertConfig({
          open: true,
          type: "loading",
          message:
            textConfig.FILE_MANAGEMENT_DELETING_FILE || "Eliminando archivo...",
        });

        try {
          await deleteFile(fileHash, collectionName);

          await fetchUserDocuments();

          setAlertConfig({
            open: true,
            type: "success",
            message:
              textConfig.FILE_MANAGEMENT_DELETE_FILE ||
              "Archivo eliminado exitosamente",
            onConfirm: async () => {
              setAlertConfig((prev) => ({ ...prev, open: false }));
            },
          });
        } catch (error: any) {
          console.error("Error en handleDeleteFile:", error);
          setAlertConfig({
            open: true,
            type: "error",
            message:
              textConfig.FILE_MANAGEMENT_ERROR_DELETE_FILE ||
              "Error al eliminar el archivo",
            onConfirm: async () => {
              setAlertConfig((prev) => ({ ...prev, open: false }));
            },
          });
        }
      },
    });
  };
  const handleFolderClick = (folderName: string) => {
    setCurrentPath((prev) => [...prev, folderName]);
    setSelectedFiles([]);
  };

  const handleNavigateBack = () => {
    setCurrentPath((prev) => prev.slice(0, -1));
    setSelectedFiles([]);
  };

  const handleNavigateToRoot = () => {
    setCurrentPath([]);
    setSelectedFiles([]);
  };

  const getFilesInFolder = (
    currentCollection: any,
    path: string[]
  ): { hash: string; collection: string }[] => {
    const result: { hash: string; collection: string }[] = [];

    if (path.length === 0) {
      if (currentCollection.files && Array.isArray(currentCollection.files)) {
        currentCollection.files.forEach((file: any) => {
          if (file.FILE_HASH && file.COLLECTION_NAME) {
            result.push({
              hash: file.FILE_HASH,
              collection: file.COLLECTION_NAME,
            });
          }
        });
      }

      Object.keys(currentCollection).forEach((key) => {
        if (key !== "files" && typeof currentCollection[key] === "object") {
          result.push(...getFilesInFolder(currentCollection[key], []));
        }
      });

      return result;
    }

    const nextFolder = path[0];
    if (currentCollection[nextFolder]) {
      return getFilesInFolder(currentCollection[nextFolder], path.slice(1));
    }

    return [];
  };

  const handleDeleteFolder = (folderName: string) => {
    let lastFolderName = folderName;
    let targetCollection: Collection = collections[folderName];
    let parentCollection: { [key: string]: Collection } = collections;

    if (currentPath.length > 0) {
      let current = collections;
      for (let i = 0; i < currentPath.length - 1; i++) {
        if (current[currentPath[i]]) {
          current = current[currentPath[i]];
        }
      }

      parentCollection = current;
      lastFolderName = currentPath[currentPath.length - 1];
      targetCollection = current[lastFolderName][folderName];
    }

    const filesToDelete = getFilesInFolder(targetCollection, []);

    const confirmMessage =
      filesToDelete.length > 0
        ? `¿Está seguro que desea eliminar esta ${
            currentPath.length === 0 ? "colección" : "carpeta"
          } y todos sus archivos (${filesToDelete.length} archivo${
            filesToDelete.length !== 1 ? "s" : ""
          } encontrado${filesToDelete.length !== 1 ? "s" : ""})?`
        : `¿Está seguro que desea eliminar esta ${
            currentPath.length === 0 ? "colección" : "carpeta"
          }?`;

    setAlertConfig({
      open: true,
      type: "confirm",
      title: `¿Eliminar ${currentPath.length === 0 ? "colección" : "carpeta"}?`,
      message: confirmMessage,
      onConfirm: async () => {
        try {
          setAlertConfig({
            open: true,
            type: "loading",
            message: "Eliminando...",
            title: "Procesando",
          });

          let errors = 0;
          for (const file of filesToDelete) {
            try {
              await deleteFile(file.hash, file.collection);
            } catch (error) {
              console.error(`Error al eliminar archivo ${file.hash}:`, error);
              errors++;
            }
          }

          const updatedCollections = { ...collections };

          if (currentPath.length === 0) {
            delete updatedCollections[folderName];
          } else {
            let current = updatedCollections;
            for (let i = 0; i < currentPath.length - 1; i++) {
              current = current[currentPath[i]];
            }

            delete current[currentPath[currentPath.length - 1]][folderName];
          }

          setCollections(updatedCollections);

          let successMessage = "";
          if (filesToDelete.length > 0) {
            if (errors > 0) {
              successMessage = `${
                currentPath.length === 0 ? "Colección" : "Carpeta"
              } eliminada. Se eliminaron ${filesToDelete.length - errors} de ${
                filesToDelete.length
              } archivos. ${errors} archivo${errors !== 1 ? "s" : ""} no se ${
                errors !== 1 ? "pudieron" : "pudo"
              } eliminar.`;
            } else {
              successMessage = `${
                currentPath.length === 0 ? "Colección" : "Carpeta"
              } y ${filesToDelete.length} archivo${
                filesToDelete.length !== 1 ? "s" : ""
              } eliminado${
                filesToDelete.length !== 1 ? "s" : ""
              } exitosamente.`;
            }
          } else {
            successMessage = `${
              currentPath.length === 0 ? "Colección" : "Carpeta"
            } eliminada exitosamente.`;
          }

          setAlertConfig({
            open: true,
            type: "success",
            message: successMessage,
            onConfirm: async () => {
              setAlertConfig((prev) => ({ ...prev, open: false }));
            },
          });
        } catch (error: any) {
          console.error("Error en handleDeleteFolder:", error);
          setAlertConfig({
            open: true,
            type: "error",
            message: `Error al eliminar la ${
              currentPath.length === 0 ? "colección" : "carpeta"
            }.`,
            onConfirm: async () => {
              setAlertConfig((prev) => ({ ...prev, open: false }));
            },
          });
        }
      },
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileSelection,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
    disabled: currentPath.length === 0,
    maxSize: 10 * 1024 * 1024,
    onDropRejected: (rejectedFiles) => {
      const sizeErrors = rejectedFiles.filter((file) =>
        file.errors.some((e) => e.code === "file-too-large")
      );
      const typeErrors = rejectedFiles.filter((file) =>
        file.errors.some((e) => e.code === "file-invalid-type")
      );

      if (sizeErrors.length > 0) {
        const fileNames = sizeErrors.map((f) => f.file.name).join(", ");
        const message =
          sizeErrors.length === 1
            ? `El archivo "${fileNames}" excede el tamaño máximo de 10MB`
            : `Los siguientes archivos exceden el tamaño máximo de 10MB: ${fileNames}`;

        setAlertConfig({
          open: true,
          type: "error",
          title: "Archivos demasiado grandes",
          message: message,
        });
      }

      if (typeErrors.length > 0 && sizeErrors.length === 0) {
        const fileNames = typeErrors.map((f) => f.file.name).join(", ");
        const message =
          typeErrors.length === 1
            ? `El archivo "${fileNames}" no tiene un formato permitido. Solo se permiten PDF, Word y TXT.`
            : `Los siguientes archivos no tienen un formato permitido: ${fileNames}. Solo se permiten PDF, Word y TXT.`;

        setAlertConfig({
          open: true,
          type: "error",
          title: "Formato no permitido",
          message: message,
        });
      }
    },
  });

  const {
    getRootProps: getNewCollectionRootProps,
    getInputProps: getNewCollectionInputProps,
    isDragActive: isNewCollectionDragActive,
  } = useDropzone({
    onDrop: handleNewCollectionFileSelection,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
    maxSize: 10 * 1024 * 1024,
    onDropRejected: (rejectedFiles) => {
      const sizeErrors = rejectedFiles.filter((file) =>
        file.errors.some((e) => e.code === "file-too-large")
      );
      const typeErrors = rejectedFiles.filter((file) =>
        file.errors.some((e) => e.code === "file-invalid-type")
      );

      if (sizeErrors.length > 0) {
        const fileNames = sizeErrors.map((f) => f.file.name).join(", ");
        const message =
          sizeErrors.length === 1
            ? `El archivo "${fileNames}" excede el tamaño máximo de 10MB`
            : `Los siguientes archivos exceden el tamaño máximo de 10MB: ${fileNames}`;

        setAlertConfig({
          open: true,
          type: "error",
          title: "Archivos demasiado grandes",
          message: message,
        });
      }

      if (typeErrors.length > 0 && sizeErrors.length === 0) {
        const fileNames = typeErrors.map((f) => f.file.name).join(", ");
        const message =
          typeErrors.length === 1
            ? `El archivo "${fileNames}" no tiene un formato permitido. Solo se permiten PDF, Word y TXT.`
            : `Los siguientes archivos no tienen un formato permitido: ${fileNames}. Solo se permiten PDF, Word y TXT.`;

        setAlertConfig({
          open: true,
          type: "error",
          title: "Formato no permitido",
          message: message,
        });
      }
    },
  });
  const currentItems = [
    ...Object.entries(getCurrentDirectory())
      .filter(([key]) => key !== "files")
      .map(([name]) => ({
        name,
        type: "folder" as const,
        onClick: () => handleFolderClick(name),
        onDelete: () => handleDeleteFolder(name),
      })),
    ...(getCurrentDirectory().files?.map((file: any) => {
      const isOnlyFileInFolder =
        getCurrentDirectory().files && getCurrentDirectory().files.length === 1;

      return {
        name:
          decodeURIComponent(file.FILE_NAME || "")
            .split("/")
            .pop() || "",
        type: "file" as const,
        hash: file.FILE_HASH,
        collection: file.COLLECTION_NAME,
        onDelete: isOnlyFileInFolder
          ? undefined
          : () => handleDeleteFile(file.FILE_HASH, file.COLLECTION_NAME),
      };
    }) || []),
  ];
  return (
    <>
      <BaseModal
        title="Administración de Archivos"
        onClose={onClose}
        isOpen={isOpen}
      >
        <CustomToggleTabs
          value={viewMode}
          onChange={(newValue) => setViewMode(newValue as "list" | "grid")}
          options={[
            {
              value: "list",
              label: "Lista",
              icon: <List size={18} />,
            },
            {
              value: "grid",
              label: "Cuadrícula",
              icon: <Grid size={18} />,
            },
          ]}
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Button
              variant="text"
              onClick={handleNavigateToRoot}
              sx={{ color: isDarkMode ? "#fff" : "#000" }}
            >
              Raíz
            </Button>
            {currentPath.map((folder, index) => (
              <React.Fragment key={folder}>
                <Typography color={isDarkMode ? "white" : "black"}>
                  /
                </Typography>
                <Button
                  variant="text"
                  onClick={() =>
                    setCurrentPath(currentPath.slice(0, index + 1))
                  }
                  sx={{ color: isDarkMode ? "#fff" : "#000" }}
                >
                  {folder}
                </Button>
              </React.Fragment>
            ))}
          </Box>
          <Button
            onClick={handleManualRefresh}
            disabled={isRefreshing}
            sx={{
              minWidth: "40px",
              p: 1,
              color: isDarkMode ? "#fff" : "#000",
              "&:hover": {
                backgroundColor: isDarkMode
                  ? "rgba(255, 255, 255, 0.1)"
                  : "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <RefreshCw
              size={20}
              className={isRefreshing ? "animate-spin" : ""}
            />
          </Button>
        </Box>
        {currentPath.length > 0 && (
          <Button
            onClick={handleNavigateBack}
            startIcon={<ArrowLeft />}
            sx={{ mb: 2 }}
            variant="outlined"
          >
            Volver
          </Button>
        )}
        <Box
          sx={{
            height: "200px",
            border: isDarkMode ? "1px solid #2D2D2D" : "1px solid #e5e7eb",
            borderRadius: 2,
            p: 2,
            mb: 2,
            backgroundColor: isDarkMode ? "#18181b" : "#ffffff",
            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <Typography color={isDarkMode ? "white" : "black"}>
              Cargando...
            </Typography>
          ) : (
            <FileList items={currentItems} viewMode={viewMode} />
          )}
        </Box>
        {currentPath.length === 0 && (
          <Box
            sx={{
              p: 2,
              mb: 2,
              border: isDarkMode ? "1px solid #2D2D2D" : "1px solid #e5e7eb",
              borderRadius: 2,
              backgroundColor: isDarkMode ? "#27272a" : "#f9fafb",
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={isDarkMode ? "#fff" : "#374151"}
              mb={1}
            >
              {currentPath.length === 0
                ? "Crear nueva colección"
                : "Crear nueva carpeta"}
            </Typography>

            {currentPath.length === 0 && (
              <>
                <Typography
                  color={isDarkMode ? "white" : "black"}
                  fontSize="14px"
                  mb={2}
                >
                  Para crear una colección, primero debe agregar al menos un
                  archivo:
                </Typography>

                <div
                  {...getNewCollectionRootProps()}
                  className={`border-dashed border-2 p-4 rounded-lg cursor-pointer ${
                    isDarkMode ? "border-gray-600" : "border-gray-300"
                  }`}
                  style={{
                    backgroundColor: isDarkMode ? "#1f1f1f" : "#f9fafb",
                    marginBottom: "1rem",
                  }}
                >
                  <input {...getNewCollectionInputProps()} />
                  {isNewCollectionDragActive ? (
                    <p className="text-green-400 text-center">
                      Suelta los archivos aquí...
                    </p>
                  ) : (
                    <p
                      className={`text-center ${
                        isDarkMode ? "text-gray-400" : "text-gray-600"
                      }`}
                    >
                      Arrastra y suelta archivos aquí, o haz clic para
                      seleccionarlos
                    </p>
                  )}
                </div>

                {newCollectionFiles.length > 0 && (
                  <Box
                    mt={2}
                    p={2}
                    bgcolor={isDarkMode ? "#1f1f1f" : "#f3f4f6"}
                    borderRadius={2}
                    mb={2}
                  >
                    <Typography
                      color={isDarkMode ? "#fff" : "#000"}
                      variant="body2"
                      mb={2}
                    >
                      Archivos Seleccionados para la Nueva Colección:
                    </Typography>
                    <ul>
                      {newCollectionFiles.map((file, index) => (
                        <li
                          key={index}
                          className={`flex justify-between items-center mb-1 ${
                            isDarkMode ? "text-white" : "text-gray-800"
                          }`}
                        >
                          <span>
                            {file.name} - {(file.size / 1024).toFixed(2)} KB
                          </span>
                          <Trash
                            size={16}
                            className="text-red-500 cursor-pointer hover:text-red-400"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setNewCollectionFiles((prev) =>
                                prev.filter((_, i) => i !== index)
                              );
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </>
            )}

            <Box display="flex" alignItems="center" gap={2} mt={1}>
              <CustomTextField
                placeholder={
                  currentPath.length === 0
                    ? "Nombre de la colección"
                    : "Nombre de la carpeta"
                }
                value={newCollectionName}
                onChange={(e) => setNewCollectionName(e.target.value)}
                sx={{ flex: 1 }}
              />
              <ActionButton
                label={
                  currentPath.length === 0 ? "Crear Colección" : "Crear Carpeta"
                }
                onClick={
                  currentPath.length === 0
                    ? handleCreateCollection
                    : handleCreateFolder
                }
                Icon={<FolderPlus />}
                size="medium"
                disabled={
                  newCollectionName.trim() === "" ||
                  (currentPath.length === 0 &&
                    newCollectionFiles.length === 0) ||
                  isCreatingCollection
                }
              />
            </Box>
            {showInlineSuccessMessage && (
              <Box
                mt={2}
                p={2}
                borderRadius={1}
                bgcolor={isDarkMode ? "#27272A" : "#f0fdf4"}
                border="1px solid #22C55E"
                display="flex"
                alignItems="center"
                gap={2}
              >
                <Box
                  sx={{
                    color: "#22C55E",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                      stroke="#22C55E"
                      strokeWidth="2"
                    />
                    <path
                      d="M12 8V12"
                      stroke="#22C55E"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <circle cx="12" cy="16" r="1" fill="#22C55E" />
                  </svg>
                </Box>
                <Typography color="#22C55E">
                  ¡Colección creada con éxito! Si no puedes ver tu colección,
                  haz clic en el botón de refrescar en la parte superior
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {currentPath.length > 0 && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={isDarkMode ? "#fff" : "#374151"}
              mb={1}
            >
              Subir archivos
            </Typography>

            <div
              {...getRootProps()}
              className={`border-dashed border-2 p-4 rounded-lg cursor-pointer ${
                isDarkMode ? "border-gray-600" : "border-gray-300"
              }`}
              style={{
                backgroundColor: isDarkMode ? "#27272a" : "#f9fafb",
                marginBottom: "1rem",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="text-green-400 text-center">
                  Suelta los archivos aquí...
                </p>
              ) : (
                <p
                  className={`text-center ${
                    isDarkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Arrastra y suelta archivos aquí, o haz clic para
                  seleccionarlos
                </p>
              )}
            </div>

            {selectedFiles.length > 0 && (
              <Box
                mt={2}
                p={2}
                bgcolor={isDarkMode ? "#27272a" : "#f3f4f6"}
                borderRadius={2}
              >
                <Typography
                  color={isDarkMode ? "#fff" : "#000"}
                  variant="body2"
                  mb={2}
                >
                  Archivos Seleccionados:
                </Typography>
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li
                      key={index}
                      className={`flex justify-between ${
                        isDarkMode ? "text-white" : "text-gray-800"
                      }`}
                    >
                      {file.name} - {(file.size / 1024).toFixed(2)} KB
                      <Trash
                        size={16}
                        className="text-red-500 cursor-pointer hover:text-red-400"
                        onClick={() =>
                          setSelectedFiles((prev) =>
                            prev.filter((_, i) => i !== index)
                          )
                        }
                      />
                    </li>
                  ))}
                </ul>
              </Box>
            )}
            <Box display="flex" mt={2}>
              <Button
                variant="outlined"
                startIcon={<Upload size={16} />}
                onClick={handleUploadFiles}
                disabled={isUploading || selectedFiles.length === 0}
                sx={{
                  backgroundColor: "#27272A",
                  borderColor: "#22C55E",
                  borderWidth: "2px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  boxShadow: "0 4px 6px -1px rgba(34, 197, 94, 0.2)",
                  "&:hover": {
                    backgroundColor: "#363639",
                    borderColor: "#16A34A",
                    transform: "translateY(-2px)",
                    boxShadow: "0 6px 10px -1px rgba(34, 197, 94, 0.3)",
                  },
                  "&:disabled": {
                    backgroundColor: "#1F1F1F",
                    borderColor: "#383838",
                    color: "#666666",
                  },
                  animation:
                    selectedFiles.length > 0 ? "pulse 2s infinite" : "none",
                  "@keyframes pulse": {
                    "0%": {
                      boxShadow: "0 0 0 0 rgba(34, 197, 94, 0.4)",
                    },
                    "70%": {
                      boxShadow: "0 0 0 10px rgba(34, 197, 94, 0)",
                    },
                    "100%": {
                      boxShadow: "0 0 0 0 rgba(34, 197, 94, 0)",
                    },
                  },
                  transition: "all 0.3s ease",
                }}
              >
                Subir Archivos
              </Button>
            </Box>
            {currentPath.length > 0 && (
              <Box
                mt={2}
                p={2}
                borderRadius={1}
                bgcolor={isDarkMode ? "#27272A" : "#f0fdf4"}
                border="1px solid #22C55E"
                display="flex"
                alignItems="center"
                gap={2}
              >
                <Box
                  sx={{
                    color: "#22C55E",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                      stroke="#22C55E"
                      strokeWidth="2"
                    />
                    <path
                      d="M12 8V12"
                      stroke="#22C55E"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <circle cx="12" cy="16" r="1" fill="#22C55E" />
                  </svg>
                </Box>
                <Typography color="#22C55E">
                  Si no puedes ver tus documentos después de cargarlos, haz clic
                  en el botón de refrescar en la parte superior
                </Typography>
              </Box>
            )}
          </>
        )}

        <Box display="flex" mt={3} justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              backgroundColor: "#FF3D3D",
              "&:hover": {
                backgroundColor: "#FF5252",
              },
            }}
          >
            Cerrar
          </Button>
        </Box>
      </BaseModal>
      <CustomAlert
        open={alertConfig.open}
        type={alertConfig.type}
        message={alertConfig.message}
        title={alertConfig.title}
        onClose={handleCloseAlert}
        onConfirm={async () => {
          if (alertConfig.onConfirm) {
            await alertConfig.onConfirm();
          }
        }}
        textConfig={textConfig}
      />
    </>
  );
}
