import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { getEndpoints } from "../utils/ApiContext";
import fetchWithAuth from "../utils/fetchWithAuth";

function verifyAdminPermissions() {
  const token = Cookies.get("accessToken");
  if (!token) {
    throw new Error("No se encontró el token de acceso. Por favor, inicie sesión nuevamente.");
  }
  
  try {
    const decodedToken = jwtDecode(token);
    const userGroups = decodedToken["cognito:groups"] || [];
    
    if (!userGroups.includes("admin")) {
      throw new Error("Se requieren privilegios de administrador para esta operación.");
    }
  } catch (error) {
    throw new Error("Error en la verificación de permisos. Por favor, inicie sesión nuevamente.");
  }
  
  return token;
}

function getAccessToken() {
  const token = Cookies.get("accessToken");
  if (!token) {
    throw new Error("No se encontró el token de acceso. Por favor, inicie sesión nuevamente.");
  }
  return token;
}

async function getUploadFilesUrl(collection, fileName, fileType) {
  try {
    const endpoints = await getEndpoints();
    const API_UPLOAD_FILES = endpoints.API_UPLOAD_FILES;
    if (!API_UPLOAD_FILES) {
      throw new Error('No se encontró la configuración para API_UPLOAD_FILES');
    }
    
    if (!API_UPLOAD_FILES.startsWith('http') && !API_UPLOAD_FILES.startsWith('/')) {
      throw new Error(`URL de API inválida: ${API_UPLOAD_FILES}`);
    }
    
    const collectionStr = String(collection || '');
    
    const fullUrl = `${API_UPLOAD_FILES}?collection=${encodeURIComponent(collectionStr)}&filename=${encodeURIComponent(fileName)}&content_type=${encodeURIComponent(fileType)}`;
    
    return fullUrl;
  } catch (error) {
    console.error('Error al obtener la URL para subida de archivos:', error);
    throw error;
  }
}

export const uploadMultipleFiles = async (files, collection) => {
  const token = verifyAdminPermissions();

  try {
    const successFiles = [];
    const errorFiles = [];

    for (const file of files) {
      try {
        const fileName = file.name;
        const isFolderMarker = fileName === ".folder";
        const fileType = file.type;
        
        const getUploadUrl = await getUploadFilesUrl(collection, fileName, fileType);

        const responseUrl = await fetch(getUploadUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
          },
        });

        if (!responseUrl.ok) {
          const errorText = await responseUrl.text();
          console.error(`Error ${responseUrl.status} al obtener URL de subida:`, errorText);
          throw new Error(`Error al obtener la URL de subida: ${responseUrl.status} - ${errorText}`);
        }
        
        const uploadUrl = await responseUrl.text();
        
        if (!uploadUrl || !uploadUrl.startsWith('http')) {
          throw new Error(`URL de carga inválida: ${uploadUrl}`);
        }

        const responseUpload = await fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            'Content-Type': fileType,
            'Content-Length': `${file.size}`,
          },
        });

        if (!responseUpload.ok) {
          const uploadErrorText = await responseUpload.text();
          console.error(`Error ${responseUpload.status} al subir archivo:`, uploadErrorText);
          throw new Error(`Error al subir el archivo: ${responseUpload.status} - ${uploadErrorText}`);
        }
        
        successFiles.push(fileName);
      } catch (error) {
        console.error(`Error al subir ${file.name}:`, error);
        errorFiles.push({
          fileName: file.name,
          error: error.message || "Error desconocido al subir el archivo"
        });
      }
    }

    if (errorFiles.length > 0) {
      if (successFiles.length === 0) {
        console.error("No se pudo subir ningún archivo:", errorFiles);
        throw new Error(`No se pudieron subir los archivos. ${errorFiles.length} errores encontrados.`);
      } else {
        return {
          message: `Se subieron ${successFiles.length} archivos con éxito, pero hubo ${errorFiles.length} errores.`,
          successFiles,
          errorFiles
        };
      }
    }

    return {
      message: "Todos los archivos fueron subidos exitosamente.",
      successFiles
    };
  } catch (error) {
    console.error("Error general en el proceso de subida de archivos:", error);
    throw error;
  }
};

export const getUserDocuments = async () => {
  try {
    const endpoints = await getEndpoints();
    const API_URL = endpoints.API_FETCH_USER_DOCUMENTS;
    const response = await fetchWithAuth(API_URL);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al obtener los documentos: ${response.status} - ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchChatHistory = async (topic) => {
  try {
    const endpoints = await getEndpoints();
    const API_BASE = endpoints.API_FETCH_CHAT_HISTORY;
    const API_URL = `${API_BASE}?topic_id=${encodeURIComponent(topic)}`;
    
    const response = await fetchWithAuth(API_URL);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al cargar el historial del chat: ${response.status} - ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (fileHash, collectionName) => {
  try {
    verifyAdminPermissions();
    
    const endpoints = await getEndpoints();
    const API_BASE = endpoints.API_DELETE_FILES;
    const API_URL = `${API_BASE}?collection=${encodeURIComponent(collectionName)}&file_hash=${encodeURIComponent(fileHash)}`;
    
    const response = await fetchWithAuth(API_URL, {
      method: "DELETE"
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al eliminar el archivo: ${response.status} - ${errorText}`);
    }
    
    return true;
  } catch (error) {
    throw error;
  }
};

export const deleteTopic = async (topicId) => {
  try {
    const endpoints = await getEndpoints();
    const API_BASE = endpoints.API_DELETE_TOPIC;
    const API_URL = `${API_BASE}?topic=${encodeURIComponent(topicId)}`;
    
    const response = await fetchWithAuth(API_URL, {
      method: "DELETE"
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al eliminar el tópico: ${response.status} - ${errorText}`);
    }

    return true;
  } catch (error) {
    throw error;
  }
};

export const fetchTopics = async () => {
  try {
    const endpoints = await getEndpoints();
    const API_URL = endpoints.API_FETCH_TOPIC;
    
    const response = await fetchWithAuth(API_URL);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al obtener los tópicos: ${response.status} - ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const deleteFolder = async (folderPath) => {
  verifyAdminPermissions();
  try {
    return true;
  } catch (error) {
    throw error;
  }
};

export const createFolder = async (folderPath) => {
  verifyAdminPermissions();

  try {
    const emptyFile = new File([""], ".folder", { type: "text/plain" });
    
    const result = await uploadMultipleFiles([emptyFile], folderPath);
    
    return true;
  } catch (error) {
    console.error("Error al crear carpeta:", error);
    throw error;
  }
};