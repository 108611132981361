import React, { useEffect, useState, useContext, useRef } from "react";
import { BaseModal } from "../ui/BaseModal.tsx";
import { toast } from "react-toastify";
import { Box, Typography, CircularProgress } from "@mui/material";
import { Phone, Clock, PhoneCall, Hash, Signal } from "lucide-react";
import { styled } from "@mui/material/styles";
import { ThemeContext } from "../ThemeContext.jsx";
import {
  fetchConnectedNumber,
  fetchLastConnectedTime,
  fetchLastSpokenNumber,
  fetchStatus,
  fetchQr,
  postLogout,
  postRestartService
} from "../../services/whatsAppStatusApi";

interface WhatsAppMenuModalProps {
  onClose: () => void;
  isOpen: boolean;
}

interface WhatsAppData {
  connectedNumber?: string;
  lastConnectedTime?: string;
  lastSpokenNumber?: string;
  phoneNumber?: string;
  status?: string;
}

interface StyledProps {
  $isDark?: boolean;
  $color?: string;
  $variant?: 'primary' | 'danger';
}

const InfoRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isDark'
})<StyledProps>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => (props.$isDark ? "#404040" : "#e5e7eb")};
  gap: 12px;
  &:last-child {
    border-bottom: none;
  }
`;

const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isDark'
})<StyledProps>`
  color: ${(props) => (props.$isDark ? "#22C55E" : "#16A34A")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const InfoText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== '$isDark'
})<StyledProps>`
  color: ${(props) => (props.$isDark ? "#22C55E" : "#16A34A")};
  font-weight: 500;
  flex: 1;
`;

const InfoValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== '$isDark'
})<StyledProps>`
  color: ${(props) => (props.$isDark ? "#FFFFFF" : "#1F2937")};
  text-align: right;
`;

const ActionButton = styled("button", {
  shouldForwardProp: (prop) => !['$isDark', '$variant'].includes(prop as string)
})<StyledProps>`
  width: 100%;
  padding: 12px;
  background-color: ${(props) => 
    props.$variant === 'danger' ? "#DC2626" : "#22C55E"};
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${(props) =>
      props.$variant === 'danger' ? "#B91C1C" : "#16A34A"};
  }

  &:disabled {
    background-color: ${(props) => (props.$isDark ? "#4B5563" : "#9CA3AF")};
    cursor: not-allowed;
  }
`;

const ErrorBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isDark'
})<StyledProps>`
  padding: 12px 16px;
  background-color: ${(props) => (props.$isDark ? "#451A1A" : "#FFEBEE")};
  color: ${(props) => (props.$isDark ? "#F87171" : "#B71C1C")};
  border-radius: 8px;
  margin-top: 16px;
  font-size: 14px;
`;

function WhatsAppMenuModal({ onClose, isOpen }: WhatsAppMenuModalProps) {
  const { isDarkMode } = useContext(ThemeContext);
  const [data, setData] = useState<WhatsAppData>({});
  const [cachedData, setCachedData] = useState<WhatsAppData>(() => {
    try {
      const saved = localStorage.getItem("whatsappMenuData");
      return saved ? JSON.parse(saved) : {};
    } catch (e) {
      return {};
    }
  });
  const [qrImageUrl, setQrImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [isQrLoading, setIsQrLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const qrIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const dataFetchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [requestInFlight, setRequestInFlight] = useState(false);
  const [textConfig, setTextConfig] = useState({
    WHATSAPP_MENU_TEXT_TITLE: "Servicio de WhatsApp",
    WHATSAPP_MENU_TEXT_CONNECTED_NUMBER: "Número Conectado",
    WHATSAPP_MENU_TEXT_LAST_CONNECTED_TIME: "Última Conexión",
    WHATSAPP_MENU_TEXT_LAST_SPOKEN_NUMBER: "Último Número Hablado",
    WHATSAPP_MENU_TEXT_PHONE_NUMBER: "Número del Teléfono",
    WHATSAPP_MENU_TEXT_CONNECTION_STATUS: "Estado de Conexión",
    WHATSAPP_MENU_TEXT_QR_CODE: "Escanea este código para conectar WhatsApp",
    WHATSAPP_MENU_TEXT_LOGOUT: "Cerrar Sesión",
    WHATSAPP_MENU_TEXT_RESTART: "Reiniciar Servicio",
    WHATSAPP_MENU_TEXT_CLOSE: "Cerrar",
    WHATSAPP_MENU_TEXT_NO_DATA: "No hay datos",
    WHATSAPP_MENU_TEXT_PROCESSING: "Procesando...",
    WHATSAPP_MENU_TEXT_MAKING_REQUEST: "Procesando solicitud...",
    WHATSAPP_MENU_TOAST_TEXT_LOGOUT: "Sesión cerrada correctamente",
    WHATSAPP_MENU_TOAST_TEXT_ERROR_LOGOUT: "Error al cerrar sesión",
    WHATSAPP_MENU_TEXT_LOADING_QR: "Cargando código QR...",
    WHATSAPP_MENU_TOAST_TEXT_RESTART: "Servicio reiniciado correctamente",
    WHATSAPP_MENU_TOAST_TEXT_ERROR_RESTART: "Error al reiniciar el servicio",
    WHATSAPP_MENU_TEXT_RETRY: "Reintentar"
  });
  const [hasErrors, setHasErrors] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  
  const loadTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      if (response.ok) {
        const textConfig = await response.json();
        if (textConfig) {
          setTextConfig(prev => ({ ...prev, ...textConfig }));
        }
      }
    } catch (error) {
      console.error("Error al cargar la configuración de texto:", error);
    }
  };

  const safeApiCall = async <T,>(
    apiFunction: () => Promise<T>,
    fallbackValue: T,
    errorLogMessage: string
  ): Promise<T> => {
    try {
      const result = await apiFunction();
      return result;
    } catch (error) {
      console.error(errorLogMessage, error);
      return fallbackValue;
    }
  };

  const fetchData = async (forceRefresh = false) => {
    if (!forceRefresh && !isLoading && hasLoadedOnce && !hasErrors) {
      return;
    }
    
    if (requestInFlight) {
      return;
    }
    
    setRequestInFlight(true);
    setIsLoading(true);
    setErrorMessage("");
    
    if (dataFetchTimeoutRef.current) {
      clearTimeout(dataFetchTimeoutRef.current);
    }
    
    dataFetchTimeoutRef.current = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
        setErrorMessage("La operación ha excedido el tiempo de espera");
        setHasErrors(true);
        setRequestInFlight(false);
      }
    }, 15000);
    
    let errorCount = 0;
    const noDataText = textConfig.WHATSAPP_MENU_TEXT_NO_DATA || "No hay datos";
    
    try {
      const newData: WhatsAppData = {};
      
      const [
        connectedNumberData,
        lastConnectedData,
        lastSpokenData,
        statusData
      ] = await Promise.all([
        safeApiCall(
          fetchConnectedNumber,
          { connectedNumber: noDataText },
          "Error al obtener número conectado:"
        ),
        safeApiCall(
          fetchLastConnectedTime,
          { lastConnectedTime: noDataText },
          "Error al obtener última conexión:"
        ),
        safeApiCall(
          fetchLastSpokenNumber,
          { lastSpokenNumber: noDataText },
          "Error al obtener último número hablado:"
        ),
        safeApiCall(
          fetchStatus,
          { connectionStatus: "Desconectado" },
          "Error al obtener estado:"
        )
      ]);
      
      if (connectedNumberData.connectedNumber === noDataText) errorCount++;
      if (lastConnectedData.lastConnectedTime === noDataText) errorCount++;
      if (lastSpokenData.lastSpokenNumber === noDataText) errorCount++;
      if (statusData.connectionStatus === "Desconectado") errorCount++;
      
      newData.connectedNumber = connectedNumberData.connectedNumber || noDataText;
      newData.lastConnectedTime = lastConnectedData.lastConnectedTime || noDataText;
      newData.lastSpokenNumber = lastSpokenData.lastSpokenNumber || noDataText;
      newData.status = statusData.connectionStatus || "Desconectado";
      newData.phoneNumber = newData.connectedNumber;
      
      setData(newData);
      setHasLoadedOnce(true);
      
      if (errorCount === 0) {
        localStorage.setItem("whatsappMenuData", JSON.stringify(newData));
        setCachedData(newData);
      }
      
      if (errorCount > 2) {
        setHasErrors(true);
        setErrorMessage("Hay problemas para conectar con el servicio de WhatsApp");
      } else {
        setHasErrors(false);
        setRetryCount(0);
      }
      
      const isConnected = 
        newData.connectedNumber && 
        newData.connectedNumber !== noDataText && 
        (newData.status?.toLowerCase() === "conectado" || newData.status?.toLowerCase() === "connected");
      
      if (!isConnected) {
        fetchQrCode();
        
        if (!qrIntervalRef.current) {
          qrIntervalRef.current = setInterval(() => {
            fetchQrCode();
          }, 30000);
        }
      } else {
        setQrImageUrl("");
        if (qrIntervalRef.current) {
          clearInterval(qrIntervalRef.current);
          qrIntervalRef.current = null;
        }
      }
    } catch (error) {
      console.error("Error al obtener datos de WhatsApp:", error);
      setErrorMessage("Error al cargar los datos de WhatsApp");
      setHasErrors(true);
      
      if (Object.keys(cachedData).length > 0) {
        setData(cachedData);
      }
    } finally {
      setIsLoading(false);
      setRequestInFlight(false);
      
      if (dataFetchTimeoutRef.current) {
        clearTimeout(dataFetchTimeoutRef.current);
        dataFetchTimeoutRef.current = null;
      }
    }
  };
  const fetchQrCode = async () => {
    if (isQrLoading) return;
    
    setIsQrLoading(true);
    try {
      const qrBlob = await fetchQr();      
      if (qrBlob) {
        if (qrImageUrl) {
          URL.revokeObjectURL(qrImageUrl);
        }
        const newQrImageUrl = URL.createObjectURL(qrBlob);
        setQrImageUrl(newQrImageUrl);
      }
    } catch (error) {
      console.error("Error al obtener código QR:", error);
    } finally {
      setIsQrLoading(false);
    }
  };

  const handleLogout = async () => {
    if (isActionLoading) return;
    
    setIsActionLoading(true);
    toast.info(textConfig.WHATSAPP_MENU_TEXT_MAKING_REQUEST);
    
    try {
      await postLogout();
      toast.success(textConfig.WHATSAPP_MENU_TOAST_TEXT_LOGOUT);
      
      setQrImageUrl("");
      setHasLoadedOnce(false);
      await fetchData(true);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      toast.error(textConfig.WHATSAPP_MENU_TOAST_TEXT_ERROR_LOGOUT);
      setErrorMessage("Error al cerrar sesión de WhatsApp");
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleRestartService = async () => {
    if (isActionLoading) return;
    
    setIsActionLoading(true);
    toast.info(textConfig.WHATSAPP_MENU_TEXT_MAKING_REQUEST);
    
    try {
      await postRestartService("whatsapp");
      toast.success(textConfig.WHATSAPP_MENU_TOAST_TEXT_RESTART);
      
      setQrImageUrl("");
      setData({});
      
      setTimeout(async () => {
        setHasLoadedOnce(false);
        await fetchData(true);
        setIsActionLoading(false);
      }, 5000);
    } catch (error) {
      console.error("Error al reiniciar el servicio:", error);
      toast.error(textConfig.WHATSAPP_MENU_TOAST_TEXT_ERROR_RESTART);
      setErrorMessage("Error al reiniciar el servicio de WhatsApp");
      setIsActionLoading(false);
    }
  };

  const handleRetry = async () => {
    setRetryCount(prev => prev + 1);
    setHasLoadedOnce(false);
    setHasErrors(false);
    setErrorMessage("");
    await fetchData(true);
  };

  useEffect(() => {
    if (isOpen) {
      const initialize = async () => {
        await loadTextConfig();
        
        setHasLoadedOnce(false);
        setHasErrors(false);
        
        if (qrImageUrl) {
          URL.revokeObjectURL(qrImageUrl);
          setQrImageUrl("");
        }
        
        await fetchData(true);
      };
      
      initialize();
    }
    
    return () => {
      if (qrIntervalRef.current) {
        clearInterval(qrIntervalRef.current);
        qrIntervalRef.current = null;
      }
      
      if (dataFetchTimeoutRef.current) {
        clearTimeout(dataFetchTimeoutRef.current);
        dataFetchTimeoutRef.current = null;
      }
      
      if (qrImageUrl) {
        URL.revokeObjectURL(qrImageUrl);
      }
    };
  }, [isOpen]);
  const displayData = {
    ...cachedData,
    ...data
  };
  
  const isDisconnected = !displayData.connectedNumber || 
    displayData.connectedNumber === textConfig.WHATSAPP_MENU_TEXT_NO_DATA || 
    ["desconectado", "disconnected", "qr available"].includes(displayData.status?.toLowerCase() || "");
    
  const shouldShowQr = isDisconnected && !hasErrors;

  return (
    <BaseModal
      title={textConfig.WHATSAPP_MENU_TEXT_TITLE || "Servicio de WhatsApp"}
      onClose={onClose}
      isOpen={isOpen}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0,
          backgroundColor: isDarkMode ? "transparent" : "#FFFFFF",
        }}
      >
        {isLoading && !hasLoadedOnce ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress color="success" />
          </Box>
        ) : (
          <>
            <InfoRow $isDark={isDarkMode}>
              <IconWrapper $isDark={isDarkMode}>
                <Phone size={20} />
              </IconWrapper>
              <InfoText $isDark={isDarkMode}>
                {textConfig.WHATSAPP_MENU_TEXT_CONNECTED_NUMBER}
              </InfoText>
              <InfoValue $isDark={isDarkMode}>{displayData.connectedNumber || textConfig.WHATSAPP_MENU_TEXT_NO_DATA}</InfoValue>
            </InfoRow>

            <InfoRow $isDark={isDarkMode}>
              <IconWrapper $isDark={isDarkMode}>
                <Clock size={20} />
              </IconWrapper>
              <InfoText $isDark={isDarkMode}>
                {textConfig.WHATSAPP_MENU_TEXT_LAST_CONNECTED_TIME}
              </InfoText>
              <InfoValue $isDark={isDarkMode}>{displayData.lastConnectedTime || textConfig.WHATSAPP_MENU_TEXT_NO_DATA}</InfoValue>
            </InfoRow>

            <InfoRow $isDark={isDarkMode}>
              <IconWrapper $isDark={isDarkMode}>
                <PhoneCall size={20} />
              </IconWrapper>
              <InfoText $isDark={isDarkMode}>
                {textConfig.WHATSAPP_MENU_TEXT_LAST_SPOKEN_NUMBER}
              </InfoText>
              <InfoValue $isDark={isDarkMode}>{displayData.lastSpokenNumber || textConfig.WHATSAPP_MENU_TEXT_NO_DATA}</InfoValue>
            </InfoRow>

            <InfoRow $isDark={isDarkMode}>
              <IconWrapper $isDark={isDarkMode}>
                <Hash size={20} />
              </IconWrapper>
              <InfoText $isDark={isDarkMode}>
                {textConfig.WHATSAPP_MENU_TEXT_PHONE_NUMBER}
              </InfoText>
              <InfoValue $isDark={isDarkMode}>{displayData.phoneNumber || textConfig.WHATSAPP_MENU_TEXT_NO_DATA}</InfoValue>
            </InfoRow>

            <InfoRow $isDark={isDarkMode}>
              <IconWrapper $isDark={isDarkMode}>
                <Signal size={20} />
              </IconWrapper>
              <InfoText $isDark={isDarkMode}>
                {textConfig.WHATSAPP_MENU_TEXT_CONNECTION_STATUS}
              </InfoText>
              <InfoValue $isDark={isDarkMode}>{displayData.status || "Desconectado"}</InfoValue>
            </InfoRow>
            {errorMessage && (
              <ErrorBox $isDark={isDarkMode}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="body2">{errorMessage}</Typography>
                  <ActionButton 
                    onClick={handleRetry}
                    $isDark={isDarkMode}
                    $variant="primary"
                    style={{ width: 'auto', padding: '4px 8px', fontSize: '12px' }}
                  >
                    {textConfig.WHATSAPP_MENU_TEXT_RETRY}
                  </ActionButton>
                </Box>
              </ErrorBox>
            )}

            {shouldShowQr && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 2,
                  mb: 2,
                }}
              >
                {qrImageUrl ? (
                  <img
                    src={qrImageUrl}
                    alt={textConfig.WHATSAPP_MENU_TEXT_QR_CODE}
                    style={{
                      maxWidth: "200px",
                      maxHeight: "200px",
                      border: "1px solid #e5e7eb",
                      borderRadius: "8px",
                      padding: "8px",
                      backgroundColor: "#ffffff"
                    }}
                  />
                ) : (
                  isQrLoading && (
                    <Box sx={{ p: 4, textAlign: "center" }}>
                      <CircularProgress color="success" size={40} />
                      <Typography sx={{ mt: 2, color: isDarkMode ? "#8B8B84" : "#6B7280" }}>
                        {textConfig.WHATSAPP_MENU_TEXT_LOADING_QR}
                      </Typography>
                    </Box>
                  )
                )}
                {qrImageUrl && (
                  <Typography
                    sx={{
                      color: isDarkMode ? "#8B8B84" : "#6B7280",
                      mt: 1,
                      fontSize: "14px",
                      textAlign: "center",
                      px: 2
                    }}
                  >
                    {textConfig.WHATSAPP_MENU_TEXT_QR_CODE}
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}

        <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <ActionButton
            onClick={handleRestartService}
            disabled={isActionLoading}
            $isDark={isDarkMode}
            $variant="primary"
          >
            {isActionLoading ? (
              <>
                <CircularProgress color="inherit" size={20} />
                <span>{textConfig.WHATSAPP_MENU_TEXT_PROCESSING}</span>
              </>
            ) : (
              textConfig.WHATSAPP_MENU_TEXT_RESTART
            )}
          </ActionButton>
          
          <ActionButton
            onClick={handleLogout}
            disabled={isActionLoading || isDisconnected}
            $isDark={isDarkMode}
            $variant="danger"
          >
            {isActionLoading ? (
              <>
                <CircularProgress color="inherit" size={20} />
                <span>{textConfig.WHATSAPP_MENU_TEXT_PROCESSING}</span>
              </>
            ) : (
              textConfig.WHATSAPP_MENU_TEXT_LOGOUT
            )}
          </ActionButton>
        </Box>
      </Box>
    </BaseModal>
  );
}

export default WhatsAppMenuModal;
